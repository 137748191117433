import http from "../http-common-RestAPI";

class DeliveryAgentsDataService {
  GetAllDeliveryAgents(params, headers) {
    return http.get("/DeliveryAgent", { params }, { headers });
  };
  GetAllDeliveryAgentsOnline(params, headers) {
    return http.get("/DeliveryAgent/online", { params }, { headers });
  };
  GetAllDeliveryAgentsOnlineFromZone(params, headers) {
    return http.get("/DeliveryAgent/onlineFromZone", { params }, { headers });
  };
  GetAllDeliveryAgentsOnlineFromSupplier(params, headers) {
    return http.get("/DeliveryAgent/onlineFromSupplier", { params }, { headers });
  };
  GetAllDeliveryAgentsOnlineFromZoneWithState(params, headers) {
    return http.get("/DeliveryAgent/onlineFromZoneWithState", { params }, { headers });
  };
  BloquearDeliveryAgent(params, headers) {
    return http.post("/DeliveryAgent/bloquear", {},  {params}, { headers });
  };
  DesbloquearDeliveryAgent(params, headers) {
    return http.post("/DeliveryAgent/desbloquear", {},  { params }, { headers });
  };
  CheckoutDeliveryAgent(params, headers) {
    return http.post("/DeliveryAgent/checkout", {},  { params }, { headers });
  };
  DefinirZonaDeliveryAgent(params, headers) {
    return http.post("/DeliveryAgent/definirZona", {},  { params }, { headers });
  };
  GetFreeDeliveryAgent(headers) {
    return http.get("/DeliveryAgent/getFreeDeliveryAgent", {}, { headers });
  };
  AddDeliveryAgent(params, headers) {
    return http.post("/DeliveryAgent/addDA", {},  { params }, { headers });
  };
}

export default new DeliveryAgentsDataService();

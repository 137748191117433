<template>
  <div v-if="poNum != ''">
    <i class="fa fa-eye-slash reload" :title="'Não Entregue'" style="color: gray" v-if="partnerState == null" @click="getPartnerState(poNum)"></i><!--<i class="nc-icon nc-notification-70 complaintIcon" style="font-size: 10px; font-weight: bold;margin-left: -5px" title="Realizar uma chamada automática para o Parceiro" @click="sendAutoCallToPartner(supplierId)" v-if="!doingCall && partnerState == null"></i>-->
    <i class="fa fa-eye-slash reload" title="Não Visto" style="color: red" v-if="partnerState == 'DeliveredToDevice'" @click="getPartnerState(poNum)"></i>
    <i class="fa fa-eye reload" :title="'Visto'+ partnerStateDate" style="color: green" v-if="partnerState == 'ReadByUser'" @click="getPartnerState(poNum)"></i>
    <i class="nc-icon nc-check-2 reload" :title="'Confirmado' + partnerStateDate" style="color: green;-webkit-text-stroke: 1px green;" v-if="partnerState == 'Confirmed'" @click="getPartnerState(poNum)"></i>
  </div>
</template>
<script>
import PartnerDataService from "src/services/PartnerDataService"
import CallDataService from "src/services/CallDataService"
export default {
  name: "PartnerStateIcon",

  props: {
    poNum: {
      type: Number,
      required: true
    },
    supplierId: {
      type: Number,
      required: true
    },
  },
  created(){
    this.$poHub.joinPOChat(this.poNum)
    this.$poHub.$on('ReceivePOStateMessage', this.signalRGetPOState)
  },
  beforeDestroy(){
    this.$poHub.leavePOChat(this.poNum)
    this.$poHub.$off('ReceivePOStateMessage', this.signalRGetPOState)
    clearInterval(this.timer)
    this.timer = null
  },
  mounted () {
    this.getPartnerState(this.poNum)
    this.timer = setInterval(() => {
      if(this.partnerState != 'Confirmed' && this.partnerState != 'ReadByUser'){
        this.getPartnerState(this.poNum)
      }
    }, 60000)
  },
  watch: {
    poNum(newVal, oldVal) {
      this.partnerState = ''
      this.partnerStateDate = null
      this.$poHub.leavePOChat(oldVal)
      this.$poHub.$off('ReceivePOStateMessage', this.signalRGetPOState)
      this.$poHub.joinPOChat(this.poNum)
      this.$poHub.$on('ReceivePOStateMessage', this.signalRGetPOState)
      this.getPartnerState(this.poNum)
    },
  },
  data() {
    return {
      timer: null,
      partnerState: '',
      partnerStateDate: null,
      signalRConnectionPO: '',
      doingCall: false
    }
  },

  methods:{
    getPartnerState(poNum){
      PartnerDataService.GetPartnerState({poNum: poNum})
        .then(response => (this.formatPartnerState(response.data)))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    formatPartnerState(stateObj){
      if(stateObj != null){
        this.partnerState = stateObj.partnerState
        if(stateObj.partnerStateDate != null){
          var date = new Date(stateObj.partnerStateDate)
          var hour = String(date.getHours()).padStart(2, '0');
          var min = String(date.getMinutes()).padStart(2, '0');
          this.partnerStateDate = hour + ":" + min
        }
      }
    },
    sendAutoCallToPartner(supplierId){
      this.doingCall = true
      CallDataService.SendAutoCallToPartner({supplierId: supplierId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (setTimeout(() => {this.doingCall = false}, 60000)))
        .catch(error => {
          console.log(error)
          this.doingCall = false
        })
        .finally()
    },

    signalRGetPOState(){
      this.getPartnerState(this.poNum);
    }
  }
};
</script>

<style scoped>
  .reload:hover {
    color: black;
    cursor: pointer;
  }
</style>

import http from "../http-common-oc";

class DAScheduleDataService {
  GetDeliveryAgentSchedule(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgentSchedule/GetDeliveryAgentSchedule", config);
  }
  UpdateDeliveryAgentSchedule(payload, headers) {
    let config = {
      headers: headers
    }
    return http.put("/services/app/DeliveryAgentSchedule/UpdateDeliveryAgentSchedule", payload, config);
  }
  GetScheduleByZone(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgentSchedule/GetScheduleByZone", config);
  }
  GetScheduleByZoneWithDates(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgentSchedule/GetScheduleByZoneBetweenDates", config);
  }
  GetScheduleByDABetweenDates(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgentSchedule/GetScheduleByDABetweenDates", config);
  }
  GetAbsencesByDAWithDates(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgentSchedule/GetAbsencesByDAWithDates", config);
  }
  GetScheduleChangesByZone(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgentScheduleChange/GetScheduleChangesByZone", config);
  }
  GetScheduleAbsencesByDA(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgentScheduleChange/GetScheduleAbsencesByDA", config);
  }
  GetAvailableDAsByZoneAndShift(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgentSchedule/GetAvailableDAsByZoneAndShift", config);
  }
  PostDeliveryAgentScheduleChange(body, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/DeliveryAgentScheduleChange/Create", body, config);
  }
  PutDeliveryAgentScheduleChange(body, headers) {
    let config = {
      headers: headers
    }
    return http.put("/services/app/DeliveryAgentScheduleChange/Update", body, config);
  }
  DeleteDeliveryAgentScheduleChange(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.delete("/services/app/DeliveryAgentScheduleChange/Delete", config);
  }
  RemoveDeliveryAgentScheduleEvent(body, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/DeliveryAgentSchedule/DeliveryAgentScheduleEventRemove", body, config);
  }

}

export default new DAScheduleDataService();

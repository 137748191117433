/*!

 =========================================================
 * Vue Light Bootstrap Dashboard - v2.0.0 (Bootstrap 4)
 =========================================================

 * Product Page: http://www.creative-tim.com/product/light-bootstrap-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import UserDataService from "./services/UserDataService"

// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main'

import VueSimpleAlert from "vue-simple-alert";

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import BeeGridTable from "beegridtable";
import BeeLocale from "beegridtable/src/locale";

import 'beegridtable/dist/styles/beegridtable.css';

import { TooltipPlugin } from 'bootstrap-vue'
import { PopoverPlugin } from 'bootstrap-vue'

import POHub from './components/Hubs/POHub'
import PartnerHub from './components/Hubs/PartnerHub'

// router setup
import routes from './routes/routes'

import {API_KEY} from './pages/Maps/API_KEY'
import * as VueGoogleMaps from 'vue2-google-maps'

import './registerServiceWorker'
// plugin setup
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(LightBootstrap)
Vue.use(TooltipPlugin)
Vue.use(PopoverPlugin)
Vue.use(VueSimpleAlert);
Vue.use(POHub);
Vue.use(PartnerHub);
Vue.use(BeeGridTable, {
  locale:BeeLocale,
  capture: true,
});
Vue.use(VueGoogleMaps, {
  load: {
    key: API_KEY,
    libraries: "places",
  }
})

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'nav-item active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  var currentUserPermissions = [];

  if (authorize) {
    UserDataService.GetCurrentUserPermissions({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
      .then(response => (currentUserPermissions = response.data.result, currentUserPermissions.includes(authorize)? next(): next({ path: '/login', query: { returnUrl: to.path } })))
      .catch(error => {
        console.log(error)
      })
      .finally()
  }
  else{
    next();
  }
})


<template>
  <div class="calendar-date-selector">
    <span @click="selectPrevious">﹤</span>
    <span @click="selectCurrent" v-if="selectedDate > currentDate">Atual</span>
    <div class="calendar-date-indicator">{{ selectedMonth }}<i class="fa fa-calendar absentIcon" title="Adicionar Falta de Estafeta" style="margin-left: 20px;" @click="openAbsenceModal" v-if="withAbsenceButton"><i class="nc-icon nc-simple-remove" style="color: red;font-size:1.3em;font-weight: bold;position:relative;left:-27px;bottom:-8px;"></i></i></div>
    <span @click="selectCurrent" v-if="selectedDate < currentDate">Atual</span>
    <span @click="selectNext">﹥</span>
  </div>
</template>
<script>
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import pt from "dayjs/locale/pt";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
// dayjs.locale(pt);

export default {
  name: "CalendarDateSelector",

  props: {
    currentDate: {
      type: Object,
      required: true
    },

    selectedDate: {
      type: Object,
      required: true
    },

    withAbsenceButton: {
      type: Boolean,
      default: true
    },
  },

  computed: {
    selectedMonth() {
      // return this.selectedDate.format("D MMMM YYYY");
      var weekday = dayjs(this.selectedDate).weekday()
      weekday = weekday == 0 ? 6 : (weekday - 1)
      var sundayDiff = 6 - weekday
      var mondayDiff = weekday
      var monday = dayjs(this.selectedDate).subtract(mondayDiff, "day")
      var sunday = dayjs(this.selectedDate).add(sundayDiff, "day")
      var selDate = dayjs(this.selectedDate).locale(pt)
      return monday.format("DD") + "-" + sunday.format("DD") + " " + selDate.format("MMMM YYYY").charAt(0).toUpperCase() + selDate.format("MMMM YYYY").substring(1);
    }
  },

  methods: {
    selectPrevious() {
      let newSelectedDate = dayjs(this.selectedDate).subtract(1, "week");
      this.$emit("dateSelected", newSelectedDate);
    },

    selectCurrent() {
      let newSelectedDate = dayjs(this.currentDate);
      this.$emit("dateSelected", newSelectedDate);
    },

    selectNext() {
      let newSelectedDate = dayjs(this.selectedDate).add(1, "week");
      this.$emit("dateSelected", newSelectedDate);
    },
    openAbsenceModal() {
      this.$emit("changeSchedule", '');
    },
  }
};
</script>
<style scoped>
.calendar-date-indicator {
  font-size: 24px;
  font-weight: 600;
  color: var(--grey-00);
  width: 100%;
  text-align: center;
}
.calendar-date-selector {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--grey-800);
  font-size: 20px;
}

.calendar-date-selector > * {
  cursor: pointer;
  user-select: none;
}

.absentIcon{
  color: black;
  display: inline !important;

}
.absentIcon:hover{
  color: #DAA520;

  cursor: pointer;
}
</style>

<template>
  <div v-if="poNum != '' && debt > 0">
    <i class="fa fa-user-secret complaintIcon" title="Saldar Divida" style="font-size: 1.2em; margin-right: 10px;" @click="closeDebtInfo(poNum, poNum, $event.target)" v-if="hasPermissions('Pages.POs.PayOffDebt')"></i>
    <b-button size="sm" variant="secondary" title="Pedir Pagamento de Divida" style="padding:0" @click="mbwayDebtInfo(poNum, poNum, $event.target)" v-if="hasPermissions('Pages.POs.PagamentoDebtMBWAY')">
      <img src="img/MBWay.png" style="width:auto;height:22px;">
      <i class="fa fa-user-secret" style="font-size: 1.0em;"></i>
    </b-button>
    <b-modal :id="mbwayDebtModal.id" :title="mbwayDebtModal.title" :ok-disabled="telMB == '' || telMB.match(/^\d{9}$/) == null" ok-variant="success" ok-title="Pedir" cancel-title="Cancelar" @hide="resetmbwayDebtModal" @ok="postMBWAYDebtRequest(mbwayDebtModal.content, telMB)">
      <b-row style="margin: auto;width: 100%;">Valor em divida: <strong>{{this.debt}}</strong> €</b-row>
      <b-row style="margin: auto;width: 100%;">Nº Telemóvel a efetuar Pagamento</b-row>
      <b-row style="margin: auto;width: 100%;">
        <b-form-input
          id="tel-input"
          v-model="telMB"
          type="text"
        ></b-form-input>
      </b-row>
      <div v-if="this.phonesPayments != null">
        <div v-for="phone in phonesPayments" :key="phone">
          <div v-if="phone != telMB" class="choosePhones" @click="chooseMBWAYPhone(phone)">
            <i class="fa fa-phone" style="color: black;  font-size: 1.1em;  font-weight: bold; margin-right: 10px;margin-left: 10px"></i><strong>{{phone}}</strong>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal :id="closeDebt.id" :title="closeDebt.title" :ok-disabled="description == ''" ok-variant="success" ok-title="Sim" cancel-title="Não" @hide="resetCloseDebtModal" @ok="postPayOffDebt(closeDebt.content, description)">
      <b-row style="margin: auto;width: 100%;">Descrição</b-row>
      <b-row style="margin: auto;width: 100%;">
        <b-form-input
          id="description-input"
          v-model="description"
          type="text"
        ></b-form-input>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import TransactionDataService from "src/services/TransactionDataService"
import LogAcaoDataService from "src/services/LogAcaoDataService"
import LogAuthorizationCodeDataService from 'src/services/LogAuthorizationCodeDataService';
import UserDataService from "src/services/UserDataService"
import SessionDataService from "src/services/SessionDataService"
export default {
  name: "DebtComponent",

  props: {
    poNum: {
      type: Number,
      required: true
    },
    phone: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.getDebt(this.poNum)
    this.getCurrentLoginInfo()
  },
  watch: {
    poNum(newVal, oldVal) {
      this.getDebt(this.poNum)
    },
  },
  data() {
    return {
      debt: 0,

      closeDebt: {
        id: 'close-debt-modal',
        title: '',
        content: ''
      },
      description: '',

      mbwayDebtModal: {
        id: 'mbwayDebt-modal',
        title: '',
        content: ''
      },
      phonesPayments: null,
      telMB: '',
      currentUserPermissions: [],
      currentUser: {}
    }
  },

  methods:{
    getDebt(poNum){
      LogAuthorizationCodeDataService.GetDebtByPoNum({poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.debt = response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    mbwayDebtInfo(item, index, button) {
      this.mbwayDebtModal.title = 'Pedir Pagamento MBWAY'
      this.mbwayDebtModal.content = item
      this.telMB = this.phone
      this.getPhonesByPayments(this.phone)
      this.$root.$emit('bv::show::modal', this.mbwayDebtModal.id, button)
    },
    resetmbwayDebtModal() {
      this.mbwayDebtModal.title = ''
      this.mbwayDebtModal.content = ''
      this.telMB = ''
      this.phonesPayments = null
    },
    getPhonesByPayments(customerPhone){
      TransactionDataService.GetPhonesByPayments({customerPhone: customerPhone}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.phonesPayments=response.data.result, this.phonesPayments.includes(customerPhone)? null: this.phonesPayments.push(customerPhone)))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    closeDebtInfo(item, index, button) {
      this.closeDebt.title = 'Tem a certeza que pretende saldar a divida do cliente?'
      this.closeDebt.content = item
      this.$root.$emit('bv::show::modal', this.closeDebt.id, button)
    },
    resetCloseDebtModal() {
      this.closeDebt.title = ''
      this.closeDebt.content = ''
      this.description = ''
    },

    chooseMBWAYPhone(phone){
      this.telMB = phone
    },
    postMBWAYDebtRequest(poNum, phone){
      LogAcaoDataService.MBWAYDebtRequest({poNum: poNum, phone: phone, userId: this.currentUser.id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Pedido de pagamento enviado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          })
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postPayOffDebt(poNum, description){
      LogAcaoDataService.PayOffDebt({poNum: poNum, description: description, userId: this.currentUser.id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => this.getDebt(this.poNum))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    getCurrentLoginInfo(){
      SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => {
          (this.currentUser = response.data.result.user);
          if(this.currentUser == null){
            this.$router.push({ path: '/login', query: { returnUrl: this.$route.path } })
          }else{
            // this.getCurrentUserRoles()
            this.getCurrentUserPermissions()
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getCurrentUserPermissions(){
      UserDataService.GetCurrentUserPermissions({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.currentUserPermissions = response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    hasPermissions(element){
      if(this.currentUserPermissions.includes(element)){
        return true;
      }else{
        return false;
      }
    },
  }
};
</script>

<style scoped>
  .reload:hover {
    color: black;
    cursor: pointer;
  }
</style>

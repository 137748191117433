import http from "../http-common-oc";

class ComplaintDataService {
  GetByClient(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Complaint/GetByClientPhone", config);
  }
  CreateReclamacao(reclamacao, headers) {
    return http.post("/services/app/Complaint/Create", reclamacao, { headers });
  }
  GetCreditAvailableByClient(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Complaint/GetCreditAvailableByClientPhone", config);
  }
  ActivateCredit(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Complaint/AtivateCredit", {}, config);
  }
  ExistsDevolutionByPoNum(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Complaint/ExistsDevolutionByPoNum", {},  config);
  }
  DeleteComplaint(params, headers) {
    let config = {
      params: params,
      headers: headers
    }
    return http.delete("/services/app/Complaint/Delete", config);
  };
}

export default new ComplaintDataService();

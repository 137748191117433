<template>
  <div class="content" style="padding: 3%; background-color: #f7f7f7; ">
    <div class="container-fluid">
      <div>
        <card style="margin-left: 15%; margin-right: 15%;">
          <template slot="header">
            <h4 class="card-title">Envio das Condições ao Candidato a Estafeta</h4>
          </template>
          <div class="text-center" v-if="sending">
            <b-spinner style="width: 10rem; height: 10rem; margin:20px" label="Large Spinner" variant="warning"></b-spinner>
          </div>
          <div style="padding: 3%" v-if="!sending">
            <b-row style="margin-left: 5px;color: gray">Nome do Candidato<p style="color: red;font-size: 16px">*</p></b-row>
            <b-form-input
              id="candidateName"
              v-model="candidateName"
              type="text"
            ></b-form-input>
            <b-row style="margin-left: 5px;color: gray">Email do Candidato<p style="color: red;font-size: 16px">*</p></b-row>
            <b-form-input
              id="candidateEmail"
              v-model="candidateEmail"
              type="text"
            ></b-form-input>
            <b-row style="margin-left: 5px;color: gray">Telefone<p style="color: red;font-size: 16px">*</p></b-row>
            <b-form-input
              id="candidatePhone"
              v-model="candidatePhone"
              type="number"
            ></b-form-input>
            <b-row style="margin-left: 5px;color: gray">Zona<p style="color: red;font-size: 16px">*</p></b-row>
            <b-form-select v-model="zone" class="a" style="margin-bottom:15px">
              <b-form-select-option disabled value="">Escolha a Zona</b-form-select-option>
              <b-form-select-option v-for="zona in zonas" :key="zona.name" :value="zona">{{zona.name}}</b-form-select-option>
            </b-form-select>
            <b-row style="margin-left: 5px;color: gray">Horários Disponíveis<p style="color: red;font-size: 16px">*</p></b-row>
            <div style="margin-left: 10px;" :key="turno.name" v-for="turno in turnos">
              <strong>{{ turno.name }}:</strong>
              <b-form-checkbox-group
                :id="turno.name"
                v-model="turno.shifts"
                :name="turno.name"
              >
                <b-form-checkbox value="Almoço">Almoço</b-form-checkbox>
                <b-form-checkbox value="Jantar">Jantar</b-form-checkbox>
              </b-form-checkbox-group>
            </div>
            <b-button size="sm" variant="success" style="width: 100%;margin-top: 40px; " :disabled="sending || candidateName == '' || candidateEmail == '' || candidatePhone == '' || turnos.every(t => t.shifts.length == 0) || zone == ''" @click="SendNewCandidateInfoEmail(candidateName, candidateEmail, candidatePhone, zone, turnos)"><b-spinner small variant="success" label="Spinning" v-if="sending"></b-spinner><span v-if="!sending">Enviar</span></b-button>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import SessionDataService from "src/services/SessionDataService"
import CandidatesOCDataService from "src/services/CandidatesOCDataService"
import DeliveryAgentsOCDataService from "src/services/DeliveryAgentsOCDataService"
export default {
  name: "NewCandidate",
  mounted () {
    this.getCurrentLoginInfo();
    this.getZonasByUser();
  },
  data() {
    return {
      currentUser:'',
      zonas: {},
      candidateName: '',
      candidateEmail: '',
      candidatePhone: '',
      zone: '',
      sending: false,
      turnos: [
        {
          id: 0,
          name: "Segunda-Feira",
          shifts: []
        },
        {
          id: 1,
          name: "Terça-Feira",
          shifts: []
        },
        {
          id: 2,
          name: "Quarta-Feira",
          shifts: []
        },
        {
          id: 3,
          name: "Quinta-Feira",
          shifts: []
        },
        {
          id: 4,
          name: "Sexta-Feira",
          shifts: []
        },
        {
          id: 5,
          name: "Sábado",
          shifts: []
        },
        {
          id: 6,
          name: "Domingo",
          shifts: []
        },
      ]
    }
  },
  methods: {
    getCurrentLoginInfo(){
      SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => {
          (this.currentUser = response.data.result.user);
          if(this.currentUser == null){
            this.$router.push({ path: '/login', query: { returnUrl: this.$route.path } })
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getZonasByUser(){
      DeliveryAgentsOCDataService.GetDAZonesByUser({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.zonas=response.data.result))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    SendNewCandidateInfoEmail(candidateName, candidateEmail, candidatePhone, zone, shifts){
      this.sending = true;
      var obs = '';
      shifts.forEach(day => {
        if(day.shifts.length > 0){
          day.shifts.sort()
          obs += '<li><strong>' + day.name + '</strong> - ' + day.shifts[0] + (day.shifts.length > 1 ? ' e ' +  day.shifts[1] : '') +'</li>'
        }
      });
      var schedule = this.formatSchedule(shifts)
      CandidatesOCDataService.SendNewCandidateInfoEmail(schedule, {candidateName: candidateName, candidateEmail: candidateEmail, candidatePhone: candidatePhone, zoneId: zone.id, observations: obs}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Email enviado com sucesso.</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          })),
          this.candidateName = '',
          this.candidateEmail = '',
          this.candidatePhone = '',
          this.zone =  '',
          this.turnos = [
            {
              id: 0,
              name: "Segunda-Feira",
              shifts: []
            },
            {
              id: 1,
              name: "Terça-Feira",
              shifts: []
            },
            {
              id: 2,
              name: "Quarta-Feira",
              shifts: []
            },
            {
              id: 3,
              name: "Quinta-Feira",
              shifts: []
            },
            {
              id: 4,
              name: "Sexta-Feira",
              shifts: []
            },
            {
              id: 5,
              name: "Sábado",
              shifts: []
            },
            {
              id: 6,
              name: "Domingo",
              shifts: []
            },
          ],
          this.sending = false)
        .catch(error => {
          console.log(error)
          this.errored = true
          this.sending = false
        })
        .finally(() => this.errored = false)
    },
    formatSchedule(shifts){
      var schedule = []
      shifts.forEach(day => {
        if(day.shifts.length > 0){
          day.shifts.forEach(shift => {
            var turno = 1;
            if(shift != 'Almoço'){
              turno = 2;
            }
            schedule.push({
              candidateId: 0,
              dayOfWeek: day.id,
              shift: turno,
              state: 'Summoned'
            })
          })
        }
      });
      return schedule;
    }
  }
};
</script>

<style scoped>
</style>

import http from "../http-common-oc";

class LogAcaoDataService {
  GetAllLogAcao(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAcao/GetAll", config);
  }
  GetLogAcaoByPO(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAcao/GetByPo", config);
  }
  GetLogEditsByDaId(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAcao/GetEditsByDA", config);
  }
  CreateLogAcao(logAcao, headers) {
    return http.post("/services/app/LogAcao/Create", logAcao, { headers });
  }
  GetLogAcaoByPesquisa(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAcao/GetByPesquisa", config);
  }
  GetLogBonus(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAcao/GetLogBonus", config);
  }
  GetAssignmentLogBonus(poNums, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/AssignmentLogBonus", poNums, config);
  }
  LogAcaoChangeAdress(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/ChangeAdress", logAcao, config);
  }
  GetChangeAdressInfo(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAcao/GetChangeAdressInfo", config);
  }
  GetClientDistanceInfo(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAcao/GetClientDistanceInfo", config);
  }
  LogAcaoAjustarKms(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/AjustarKms", logAcao, config);
  }
  LogAcaoAjustarDeliveryFee(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/AjustarDeliveryFee", logAcao, config);
  }
  LogAcaoAlterarSteps(logAcao, changeStepsDto, headers) {
    let config = {
      headers: headers
    }
    let payload = {
      logAcao,
      changeStepsDto
    }
    return http.post("/services/app/LogAcao/AlterarSteps", payload, config);
  }
  LogAcaoAlterarHorario(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/AlterarHorario", logAcao, config);
  }
  LogAcaoAcrescentarRetirarProduto(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/AcrescentarRetirarProduto", logAcao, config);
  }
  LogAcaoChangeObsDA(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/ChangeObsDA", logAcao, config);
  }
  LogAcaoAtivarCredito(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/AtivarCredito", logAcao, config);
  }
  LogAcaoAddAmountDue(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/AddAmountDue", logAcao, config);
  }
  LogAcaoAtribuirMaisPedidosEstafeta(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/AtribuirMaisPedidosEstafeta", logAcao, config);
  }
  LogAcaoCancelarPedido(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/CancelarPedido", logAcao, config);
  }
  LogAcaoCancelarPedidoGiftCard(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/CancelarPedidoGiftCard", logAcao, config);
  }
  LogAcaoForcarFechoPedido(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/ForcarFechoPedido", logAcao, config);
  }
  LogAcaoReAtivarPedido(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/ReAtivarPedido", logAcao, config);
  }
  LogAcaoRetirarPedido(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/RetirarPedido", logAcao, config);
  }
  LogAcaoBloquearDA(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/BloquearDA", logAcao, config);
  }
  LogAcaoDesbloquearDA(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/DesloquearDA", logAcao, config);
  }
  LogAcaoCheckoutDA(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/CheckoutDA", logAcao, config);
  }
  LogAcaoDefinirZonaDA(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/DefinirZonaDA", logAcao, config);
  }
  LogAcaoTrocarEstafeta(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/TrocarEstafeta", logAcao, config);
  }
  LogAcaoLogAuthorizationCode(logAcao, logAuthorizationCode, headers) {
    let config = {
      headers: headers
    }
    let payload = {
      logAcao,
      logAuthorizationCode
    }
    return http.post("/services/app/LogAcao/LogAuthorizationCode", payload, config);
  }
  LogAcaoReclamacao(logAcao, complaint, headers) {
    let config = {
      headers: headers
    }
    let payload = {
      logAcao,
      complaint
    }
    return http.post("/services/app/LogAcao/Reclamacao", payload, config);
  }
  LogAcaoMBWAYRequest(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/RequestMBWAYAmountDuePayment", logAcao, config);
  }
  MBWAYDebtRequest(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/RequestMBWAYDebtPayment", {}, config);
  }
  PayOffDebt(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/PayOffDebt", {}, config);
  }

  GetReceipt(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAcao/GetReceipt", config);
  }

  LogAcaoNewAlert(logAcao, alert, headers) {
    let config = {
      headers: headers
    }
    let payload = {
      logAcao,
      alert
    }
    return http.post("/services/app/LogAcao/NewAlert", payload, config);
  }

  GetDeliveryFeeFromLogs(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAcao/GetDeliveryFeeFromLogs", config);
  }

  GetPartnerState(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAcao/GetPartnerState", config);
  }

  SumAssignmentDistances(params, headers) {
    let config = {
      params: params,
      headers: headers
    }
    return http.post("/services/app/LogAcao/SumAssignmentDistances", {}, config);
  }

  SendPODescriptionBySMS(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/SendPODescriptionBySMS", logAcao, config);
  }
  SendSMSToClient(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/SendSMSToClient", logAcao, config);
  }
  CreateSFClientVoucher(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/CreateSFClientVoucher", {}, config);
  }
  AddressWrongCoords(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/AddressWrongCoords", {}, config);
  }
  LoadPOData(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/LoadPOData", {}, config);
  }
  GetSMSMessagesByCliente(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/SMSMessage/GetMessageAPIFonicaByClient", config);
  }
  ApplyBonus(das, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/ApplyBonus", das, config);
  }
}

export default new LogAcaoDataService();

import http from "../http-common-RestAPI";

class DeliveryAssignmentDataService {
  GetAllDeliveryAssignment(params, headers) {
    return http.get("/DeliveryAssignment", { params }, { headers });
  }
  GetDeliveryAssignmentById(params, headers) {
    return http.get(`/DeliveryAssignment/${params}`, { headers });
  }
  GetPOsFromDeliveryAssignmentId(params, headers) {
    return http.get(`/DeliveryAssignment/pos/${params}`, { headers });
  }
  TrocarEstafeta(params, headers) {
    return http.post("/DeliveryAssignment/trocarEstafeta", {}, { params }, { headers });
  }
  AjustarKms(params, headers) {
    return http.post("/DeliveryAssignment/ajustarKms", {}, { params }, { headers });
  }
  AlterarSteps(params, headers) {
    return http.post("/DeliveryAssignment/alterarSteps", params, { headers });
  }
}

export default new DeliveryAssignmentDataService();

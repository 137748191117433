<template>
  <ul class="nav nav-mobile-menu">
    <!-- <li class="nav-item">
      <a class="nav-link" href="#pablo">
        <span class="no-icon">Perfil</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link"  @click="loginInfo($event.target)">
        <span class="no-icon">Entrar</span>
      </a>
      <b-modal :id="loginModal.id" :title="loginModal.title"  ok-variant="success" @hide="resetLoginModal" @ok="postLogin(username, password)">
        UserName2
        <b-form-input
          id="username1-input"
          v-model="username"
          required
        ></b-form-input>
        Password
        <b-form-input
          id="password1-input"
          v-model="password"
          required
          type="password"
        ></b-form-input>
      </b-modal>
    </li> -->
  </ul>
</template>
<script>

import { BFormInput, BModal } from 'bootstrap-vue'
import axios from 'axios'

  export default {
    name: 'mobile-menu',
    components: {
      BFormInput, BModal
    },
    data () {
      return {
        loginModal: {
          id: 'login-modal',
          title: '',
          content: ''
        },
        username: '',
        password: ''
      }
    },
    methods: {
      loginInfo(button) {
        this.loginModal.title = `Login`
        this.loginModal.content = ''
        this.$root.$emit('bv::show::modal', this.loginModal.id, button)
      },
      resetLoginModal() {
        this.loginModal.title = ''
        this.loginModal.content = ''
        this.username = ''
        this.password = ''
      },
      postLogin(username, password){
        const body = {userNameOrEmailAddress: username, password: password, rememberClient: true}
        axios
          .post('http://localhost:21021/api/TokenAuth/Authenticate', body)
          .then(response => (
            console.log(response), this.$fire({
              title: "Sucesso",
              type: "success",
              timer: 2000
            }), localStorage.setItem('user-token', response.data.result.accessToken)
          ))
          .catch(error => {
            console.log(error)
            this.errored = true
            this.$fire({
              title: "Erro",
              text: "Ocorreu um erro, verifique os dados!",
              type: "error",
              timer: 3000
            })
            localStorage.removeItem('user-token')
          })
          .finally(() => this.errored = false)
      }
    }
  }
</script>
<style>
</style>

<template>
  <div class="content" style="padding-top: 0px; background-color: #f7f7f7;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <h4 class="card-title">Documentos</h4>
            </template>
            <div>
              <div class="text-center" v-if="loading">
                <b-spinner style="width: 10rem; height: 10rem; margin:20px" label="Large Spinner" variant="warning"></b-spinner>
              </div>
              <b-card-group v-if="!loading">
                <b-card v-for="doc in docs" :key="doc.docType" style="margin: 20px">
                  <b-card-header style="margin: 0; padding: 0;">
                    <h3 style="margin: 0; padding: 0;">{{ formatDocType(doc.docType) }}</h3>
                  </b-card-header>
                  <div class="docsView" @click="seeDocModalInfo(doc.doc.id)">
                    <b-card-img src="img/doc.png" alt="Doc" style="padding: 20%;padding-bottom: 0;padding-top: 0;"></b-card-img>
                    <b-card-text>
                      <h4 style="margin: 0; padding: 0;text-align: center;">{{ doc.doc.docName }}</h4>
                    </b-card-text>
                  </div>
                  <template #footer>
                    <small class="text-muted">{{getDateWithHoursFormated(doc.doc.uploadDate)}}</small>
                    <i class="fa fa-download docsView" title="Tranferir Documento" style="font-size: 20px;float: right;margin: 5px;" @click="downloadFiles(doc.doc.id, doc.doc.docName)"></i>
                    <i class="fa fa-pencil-square-o docsView" title="Alterar Documento" style="font-size: 20px;float: right;margin: 5px;" @click="submitFilesInfo(doc.docType)"></i>
                  </template>
                </b-card>
              </b-card-group>
            </div>
            <b-modal :id="submitFilesModal.id" :title="submitFilesModal.title"  :ok-disabled="files == ''"  ok-variant="success" ok-title="Guardar" cancel-title="Cancelar" @hide="resetSubmitFilesModal" @ok="submitFiles(submitFilesModal.docType)">
              <div style="border: 1px solid gray;margin:10px; padding: 5px">
                <label>
                  <input type="file" accept="application/pdf" @change="handleFileUploads($event)" style="font-size: 11px;"/>
                </label>
                <h4 style="font-size:10px; margin-top: 0">(tamanho max. 2MB)</h4>
                <div style="width:fit-content">
                    <b-row v-for="(file, key) in files" :key="key" style="margin: 10px;border: 1px solid #DAA520;padding:5px;">
                      <i class="fa fa-file" style="font-size: 15px;"></i><strong style="font-size: 12px;">{{ file.name }}</strong>
                    </b-row>
                </div>
              </div>
            </b-modal>
            <b-modal :id="seeDocModal.id" modal-class="modalClass" scrollable size="xl" ok-only ok-variant="secondary" ok-title="Fechar" button-size="sm" @hide="resetSeeDocModal">
              <div>
                <div class="text-center" v-if="!seeDocModal.docRendered">
                  <b-spinner style="width: 10rem; height: 10rem; margin:20px" label="Large Spinner" variant="warning"></b-spinner>
                </div>
                <vue-pdf-embed :source="pdf" @rendered="seeDocModal.docRendered = true"/>
              </div>
            </b-modal>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import Card from "src/components/Cards/Card.vue";
import { BTable, BCard, BCardHeader, BCardImg, BCardGroup, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BPagination, BRow, BFormSelectOption    } from 'bootstrap-vue'
import SessionDataService from "src/services/SessionDataService"
import CandidateManagementDocsDataService from "src/services/CandidateManagementDocsDataService"
export default {
  components: {
    VuePdfEmbed,
    Card,
    BTable, BCard, BCardHeader, BCardImg, BCardGroup, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BPagination, BRow, BFormSelectOption
  },
  mounted () {
    this.getCurrentLoginInfo();
    this.getCandidateManagementDocs();
  },
  data() {
    return {
      currentUser:'',
      docs: [],
      files: [],
      submitFilesModal: {
        id: 'submitFiles-modal',
        title: '',
        docType: ''
      },
      seeDocModal: {
        id: 'seeDoc-modal',
        title: '',
        docRendered: false
      },
      pdf: '',
      loading: true
    };
  },
  methods: {
    getCurrentLoginInfo(){
      SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => {
          (this.currentUser = response.data.result.user);
          if(this.currentUser == null){
            this.$router.push({ path: '/login', query: { returnUrl: this.$route.path } })
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getCandidateManagementDocs(){
      CandidateManagementDocsDataService.GetFilesGroupedByDocType({},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.docs=response.data.result, this.loading = false))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.loading = false
        })
        .finally(() => this.errored = false)
    },
    getDoc(id){
      CandidateManagementDocsDataService.DownloadFile({id: id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => {
              this.pdf = response.data;
            })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)

    },
    downloadFiles(id, filename){
      CandidateManagementDocsDataService.DownloadFile({id: id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => {
              const blob = new Blob([response.data], { type: response.data.type })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = filename
              link.click()
              URL.revokeObjectURL(link.href)
            })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)

    },
    submitFilesInfo(docType) {
      this.submitFilesModal.title = 'Alterar Documento para "'+ this.formatDocType(docType) + '"'
      this.submitFilesModal.docType = docType
      this.$root.$emit('bv::show::modal', this.submitFilesModal.id, '')
    },
    resetSubmitFilesModal() {
      this.submitFilesModal.title = ''
      this.submitFilesModal.docType = ''
      this.files = ''
    },
    handleFileUploads( event ){
      this.files = event.target.files;
    },
    submitFiles(docType){
      let formData = new FormData();

      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];

        formData.append('files[' + i + ']', file);
      }
      formData.append('docType', docType);
      this.loading = true

      CandidateManagementDocsDataService.UploadFiles(formData, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(() => setTimeout(() => {  this.$notifications.notify(
          {
            message: `<span>Documento alterado com sucesso</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }),
          this.getCandidateManagementDocs() }, 1000))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique o tamanho do documento!",
            type: "error",
            timer: 3000
          })
          this.loading = false
        })
        .finally(() => this.errored = false)

    },
    seeDocModalInfo(docId) {
      this.seeDocModal.title = ''
      this.getDoc(docId)
      this.$root.$emit('bv::show::modal', this.seeDocModal.id, "")
    },
    resetSeeDocModal() {
      this.seeDocModal.title = ''
      this.pdf = ''
      this.seeDocModal.docRendered = false
    },

    formatDocType(docType){
      var str = "";
      switch(docType){
        case "DAFORMATION":
          str = "Formação do Estafeta";
          break;
        case "DAMANUAL":
          str = "Manual do Estafeta";
          break;
        case "DARECRUIT":
          str = "Recrutamento";
          break;
        case "GENERALCONTRACT":
          str = "Contrato Genérico";
          break;
        default:
          str = "";
          break;
      }
      return str;
    },
    getDateWithHoursFormated(date){
      var dt = new Date(date)
      var str =
        dt.getDate().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + "/" +
        (dt.getMonth()+1).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + "/" + dt.getFullYear() + " " +
        dt.getHours().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + ":" +
        dt.getMinutes().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
      return str
    }
  }
};
</script>
<style>
.docsView{
  color: black;
}
.docsView:hover{
  color: #DAA520;
  cursor: pointer;
}
</style>

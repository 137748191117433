<template>
  <li class="dropdown nav-item"
      :is="tag"
      :class="{show: isOpen}"
      @click="toggleDropDown"
      >
      <!-- v-click-outside="closeDropDown"
      :aria-expanded="isOpen"-->

    <a class="nav-link"
       data-toggle="dropdown">
      <slot name="title">
        <i :class="icon"></i>
        <span class="no-icon">{{title}}</span>
        <i class="nc-icon nc-stre-up" style="font-size: 10px; font-weight: bold; margin-top: 10px; float: right;" v-if="isOpen"></i>
        <i class="nc-icon nc-stre-down" style="font-size: 10px; font-weight: bold; margin-top: 10px; float: right;" v-if="!isOpen"></i>
      </slot>
    </a>
    <!-- <div class="dropdown-menu show" v-show="isOpen">
      <slot></slot>
    </div> -->
  </li>
</template>
<script>
  export default {
    name: 'base-dropdown',
    props: {
      title: String,
      icon: String,
      tag: {
        type: String,
        default: 'li'
      }
    },
    data () {
      return {
        isOpen: false
      }
    },
    methods: {
      toggleDropDown () {
        this.isOpen = !this.isOpen
        this.$emit('change', this.isOpen)
      },
      closeDropDown () {
        this.isOpen = false
        this.$emit('change', this.isOpen)
      }
    }
  }
</script>
<style scoped>
  .dropdown .dropdown-toggle{
    cursor: pointer;
  }
</style>

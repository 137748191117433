<template>
  <div class="content" style="padding-top: 10px; background-color: #f7f7f7;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card>
            <template slot="header">
              <h4 class="card-title">Performance dos Estafetas</h4>
            </template>
            <div class="col" style="margin: auto;width: 100%;padding: 10px;border: 1px solid #DAA520;">
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="1" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Zona</p>
                </b-col>
                <b-col lg="3" class="my-1">
                  <b-form-select v-model="zone" style="cursor: pointer">
                    <b-form-select-option :value="''"></b-form-select-option>
                    <b-form-select-option v-for="zone in zones" :key="zone.id" :value="zone.id">{{zone.name}}</b-form-select-option>
                  </b-form-select>
                </b-col>
                <b-col lg="1">
                </b-col>
                <b-col lg="3">
                  <b-row>
                    <b-col lg="1.5" class="my-1">
                      <p style="text-align: left; padding: 6px 0;margin-right:10px;">De</p>
                    </b-col>
                    <b-col lg="1.5" class="my-1"  style="margin-right:10px;">
                      <b-form-datepicker id="startDatee" right size="sm" :max="this.endDate" button-only v-model="startDate" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"></b-form-datepicker>
                      {{startDate}}
                    </b-col>
                    <b-col lg="0.5" class="my-1">
                      <p style="text-align: left; padding: 6px 0;margin-right:10px;">a</p>
                    </b-col>
                    <b-col lg="1.5" class="my-1">
                      <b-form-datepicker id="endDatee" right size="sm" :min="this.startDate" button-only v-model="endDate" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"></b-form-datepicker>
                      {{endDate}}
                    </b-col>
                  </b-row>
                </b-col>
                <b-col lg="1" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Turno</p>
                </b-col>
                <b-col lg="3" class="my-1">
                  <b-form-select v-model="shift" style="cursor: pointer"
                    :disabled="this.startDateTime != '' || this.endDateTime != ''">
                    <b-form-select-option v-for="shift in shifts" :key="shift.id" :value="shift.id">{{shift.name}}</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
              <div style="margin:10px; display: flex; justify-content: flex-end">
                <b-col lg="6">
                  <b-row>
                    <b-col lg="2" class="my-1" style="padding: 0">
                      <p style="text-align: right; padding: 6px 0;margin-right:10px;">Das</p>
                    </b-col>
                    <b-col lg="3" class="my-1"  style="margin-right:10px;">
                      <b-row>
                        <b-col style="padding: 0">
                          <b-form-input
                            id="startDateTime-input"
                            v-model="startDateTime"
                            type="number"
                            step="1"
                            min="0"
                            :max="this.endDateTime != ''?this.endDateTime : '23'"
                            style="text-align: right;"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="1" style="padding: 0">
                          <p style="padding:6px;text-align: center;">:</p>
                        </b-col>
                        <b-col style="padding: 0">
                          <b-form-input
                            id="startDateTimeMinute-input"
                            v-model="startDateTimeMinute"
                            :disabled="this.startDateTime == ''"
                            type="number"
                            step="1"
                            min="0"
                            max="59"
                            style="text-align: right;"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col lg="2" class="my-1" style="padding: 0">
                      <p style="text-align: center; padding: 6px 0;margin-right:10px;">até às</p>
                    </b-col>
                    <b-col lg="3" class="my-1"  style="margin-right:10px;">
                      <b-row>
                        <b-col style="padding: 0">
                          <b-form-input
                            id="endDateTime-input"
                            v-model="endDateTime"
                            type="number"
                            step="1"
                            :min="this.startDateTime != '' ? this.startDateTime : '0'"
                            max="23"
                            style="text-align: right;"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="1" style="padding: 0">
                          <p style="padding:6px;text-align: center;">:</p>
                        </b-col>
                        <b-col style="padding: 0">
                          <b-form-input
                            id="endDateTimeMinute-input"
                            v-model="endDateTimeMinute"
                            :disabled="this.endDateTime == ''"
                            type="number"
                            step="1"
                            min="0"
                            max="59"
                            style="text-align: right;"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col lg="2">
                </b-col>
                <b-button size="md" variant="secondary" @click="toogleSearch()" :disabled="zone == ''" >Procurar</b-button>
              </div>
            </div>
            <b-tabs v-model="tabIndex" justified v-if="search">
              <b-tab title="Tabela">
                <div class="table-responsive" v-if="search">
                  <b-table  class="tables"
                    responsive
                    striped
                    bordered
                    outlined
                    hover
                    small
                    sort-icon-left
                    no-border-collapse
                    thead-class="cm-bg"
                    id="table"
                    ref="selectableTable"
                    :items="items"
                    :fields="fields"
                    :busy="isBusy"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @row-clicked="(item, index, event) => seeDAPOs(item.daId, index, event)" >
                    <template #table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{ width: field.key === 'selected' ? '4%' : '12%' }"
                      >
                    </template>
                    <template #table-busy>
                      <div class="text-center text-warning my-1">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>A carregar...</strong>
                      </div>
                    </template>
                    <template #cell(selected)="row">
                      <b-form-checkbox v-model="row.item.selected" name="check-button2" style="margin-right: 10px;" @change="onSelected(row.item)" v-if="dateDiffInDays(searchedStartDate, searchedEndDate) < 1"></b-form-checkbox>
                    </template>
                    <template #cell(nrRoutes)="row">
                        <div class="updateIcon" style="text-decoration: underline;" @click="seeAllAssignments(row.item.daId)">{{row.item.nrRoutes}}</div>
                    </template>
                    <template #cell(totalDeliveryDelay)="row">
                        {{(row.item.totalDeliveryDelay >= 0? "+"+ row.item.totalDeliveryDelay : row.item.totalDeliveryDelay) + " min"}}
                    </template>
                    <template #cell(totalKmsPayed)="row">
                        {{round(row.item.totalKmsPayed)}} Kms
                    </template>
                    <template #cell(ordersTotalPayed)="row">
                        {{round(row.item.ordersTotalPayed)}} €
                    </template>
                    <template #cell(totalBonus)="row">
                        <div class="updateIcon" style="text-decoration: underline;" @click="seeAssignments(row.item.daId)" v-if="row.item.totalBonus > 0">{{round(row.item.totalBonus)}} €</div>
                    </template>
                    <template #cell(totalPayed)="row">
                        {{round(row.item.totalPayed)}} €
                    </template>
                    <template #custom-foot>
                      <b-tr>
                        <b-td variant="secondary" class="text-center">
                          <b-form-checkbox v-model="selectAll" name="check-button" @change="selectAllRows(selectAll)" v-if="dateDiffInDays(searchedStartDate, searchedEndDate) < 1"></b-form-checkbox>
                        </b-td>
                        <b-td variant="secondary" class="text-center">
                          <b>TOTAL</b>
                        </b-td>
                        <b-td variant="secondary" class="text-center">
                          <b>{{ totalRow.nrRoutes }}</b>
                        </b-td>
                        <b-td variant="secondary" class="text-center">
                          <b>{{ totalRow.nrOrders }}</b>
                        </b-td>
                        <b-td variant="secondary" class="text-center">
                          <b>{{(totalRow.totalDeliveryDelay >= 0? "+"+totalRow.totalDeliveryDelay: totalRow.totalDeliveryDelay) + " min"}}</b>
                        </b-td>
                        <b-td variant="secondary" class="text-center">
                          <b>{{ totalRow.totalKmsPayed }} Kms</b>
                        </b-td>
                        <b-td variant="secondary" class="text-center">
                          <b>{{ totalRow.ordersTotalPayed }} €</b>
                        </b-td>
                        <b-td variant="secondary" class="text-center">
                          <b>{{ totalRow.totalBonus }} €</b>
                        </b-td>
                        <b-td variant="secondary">
                          <b>{{ totalRow.totalPayed }} €</b>
                        </b-td>
                      </b-tr>
                    </template>
                  </b-table>
                  <b-row>
                    <b-col lg="8" class="my-1">
                      <b-button size="sm" variant="warning" @click="seeLogBonus()">Histórico de Bónus</b-button>
                      <b-button size="sm" variant="warning" @click="addBonusInfo()" v-if="dateDiffInDays(searchedStartDate, searchedEndDate) < 1 && this.selectedDAs.length > 0 && this.hasPermissions('Pages.DAs.AplicarBonus')">Aplicar Bónus</b-button>
                    </b-col>
                    <b-col lg="2" class="my-1">
                      <b-form-group
                        label-for="per-page-select"
                        label-cols-lg="5"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          align="right"
                          size="sm"
                        ></b-form-select>
                          <!-- @change="handlePageSizeChange($event)" -->
                      </b-form-group>
                    </b-col>
                    <b-col lg="2" class="my-1">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                        <!-- @change="handlePageChange" -->
                    </b-col>
                  </b-row>
                </div>

                <div class="table-responsive border" v-if="this.showAssignments">
                  <h5 style="margin: 5px;font-weight: 600;">Rotas</h5>
                  <b-table  class="tables"
                    responsive
                    striped
                    bordered
                    outlined
                    hover
                    small
                    no-border-collapse
                    thead-class="cm-bg"
                    id="ssignmentsTable"
                    :items="assignments"
                    :fields="assignmentsFields"
                    :busy="isBusyAssignments"
                    @row-clicked="(item, index, event) => seePOModal(this.formatPOs(item.purchaseOrders)[0], index, event, 'assignment')">
                    <template #cell(completionDateTime)="row">
                      {{getDateWithHoursFormated(row.item.completionDateTime)}}
                    </template>
                    <template #cell(daPaymentAmount)="row">
                        {{round(row.item.daPaymentAmount)}} €
                    </template>
                    <template #cell(totalDistance)="row">
                        {{round(row.item.totalDistance)}} Kms
                    </template>
                    <template #cell(total)="row">
                        {{round(row.item.daPaymentAmount + row.item.daBonus)}} €
                    </template>
                    <template #cell(daBonus)="row">
                        <div class="updateIcon" style="text-decoration: underline;" @click="assignmentLogBonusInfo(row.item)" v-if="row.item.daBonus > 0">{{round(row.item.daBonus)}} €</div>
                    </template>
                  </b-table>
                  <b-row>
                    <b-col lg="9" class="my-1">
                      <b-form-group
                        label-for="per-page-assignments"
                        label-cols-lg="11"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-assignments"
                          v-model="perPageAssignments"
                          :options="pageOptions"
                          @change="handlePageSizeChangeAssignments($event)"
                          align="right"
                          size="sm"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col class="my-1">
                      <b-pagination
                        v-model="currentPageAssignments"
                        :total-rows="totalRowsAssignments"
                        :per-page="perPageAssignments"
                        @change="handlePageChangeAssignments"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                  <div v-if="selectedPONum != ''">
                    <POModal :v-show="openModal" :poNum="selectedPONum" :item="selectedPO" :closeFunction="closePOModal"></POModal>
                  </div>
                </div>


                <div class="table-responsive border" v-if="this.showPedidosDA">
                  <h5 style="margin: 5px;font-weight: 600;">Pedidos</h5>
                  <b-table  class="tables"
                    responsive
                    striped
                    bordered
                    outlined
                    hover
                    small
                    no-border-collapse
                    thead-class="cm-bg"
                    id="pedidosDATable"
                    :items="pedidosDA"
                    :fields="pedidosDAFields"
                    :busy="isBusyPOs"
                    @row-clicked="(item, index, event) => seePOModal(item, index, event, 'po')">
                    <template #cell(datedeliveryTimeWindowEnd1)="row">
                      <i class="fa fa-bolt" title="Entregar o mais rápido possivel" style="color: red; font-size: 16px" v-if="row.item.element.deliverAsap"></i>{{row.item.datedeliveryTimeWindowEnd1}}
                    </template>
                    <template #cell(supplierName)="row">
                      <i class="fa fa-percent" style="color: white; border-radius: 50%;padding:5px;font-size: 8px;background-color: #DAA520; margin-right: 10px" title="C/ Voucher" v-if="row.item.element.voucherUsed"></i>{{row.item.supplierName}}
                    </template>
                  </b-table>
                  <b-row>
                    <b-col lg="9" class="my-1">
                      <b-form-group
                        label-for="per-page-selectPOs"
                        label-cols-lg="11"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-selectPOs"
                          v-model="perPagePOs"
                          :options="pageOptions"
                          @change="handlePageSizeChangePOs($event)"
                          align="right"
                          size="sm"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col class="my-1">
                      <b-pagination
                        v-model="currentPagePOs"
                        :total-rows="totalRowsPOs"
                        :per-page="perPagePOs"
                        @change="handlePageChangePOs"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                  <div v-if="selectedPONum != ''">
                    <POModal :v-show="openModal" :poNum="selectedPONum" :item="selectedPO" :closeFunction="closePOModal"></POModal>
                  </div>
                </div>


                <div class="table-responsive border" v-if="this.showLogBonus">
                  <h5 style="margin: 5px;font-weight: 600;">Histórico de Bónus</h5>
                  <b-table  class="tables"
                    responsive
                    striped
                    bordered
                    outlined
                    hover
                    small
                    no-border-collapse
                    thead-class="cm-bg"
                    id="logBonusTable"
                    :items="logBonus"
                    :fields="logBonusFields"
                    :busy="isBusyLogBonus">
                    <template #cell(logDateTime)="row">
                      {{getDateWithHoursFormated(row.item.logDateTime)}}
                    </template>
                  </b-table>
                  <b-row>
                    <b-col lg="9" class="my-1">
                      <b-form-group
                        label-for="per-page-logBonus"
                        label-cols-lg="11"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-logBonus"
                          v-model="perPageLogBonus"
                          :options="pageOptions"
                          @change="handlePageSizeChangeLogBonus($event)"
                          align="right"
                          size="sm"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col class="my-1">
                      <b-pagination
                        v-model="currentPageLogBonus"
                        :total-rows="totalRowsLogBonus"
                        :per-page="perPageLogBonus"
                        @change="handlePageChangeLogBonus"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
              <b-tab title="Gráfico" @click="chartTabClicked">
                <DAPerformanceChart :key="chartRef" :zoneId="zone" :startDate="startDate" :endDate="endDate" :shift="shift" :startDateTime="startDateTime" :endDateTime="endDateTime" v-if="endDate != '' && startDate != '' && search && (chartRef.charAt(0) == '|')"></DAPerformanceChart>
              </b-tab>
            </b-tabs>



            <b-modal :id="addBonusModal.id" :title="addBonusModal.title" :ok-disabled="perc == ''" ok-variant="success" ok-title="Aplicar" cancel-title="Fechar" @hide="resetAddBonusModal" @ok="applyBonus(selectedDAs, searchedStartDate, searchedShift, perc, searchedStartDateTime,searchedEndDateTime, searchedStartDateTimeMinute, searchedEndDateTimeMinute)">
              <div class="row" style="margin-left: -2px;margin-top: 5px;margin-bottom: 5px;"><strong style="margin-right: 15px;">Zona:</strong> {{this.getZoneName(this.searchedZone)}}</div>
              <div class="row" style="margin-left: -2px;margin-top: 5px;margin-bottom: 5px;"><strong style="margin-right: 15px;">Estafeta(s) a aplicar bónus:</strong></div>
              <div class="row" style="margin-left: -2px;margin-top: 5px;margin-bottom: 5px;"><p v-for="da in selectedDAs" :key="da.daId" style="margin-left: 30px;width: 100%"> - {{ da.daName }}</p></div>
              <div class="row" style="margin-left: -2px;margin-top: 5px;margin-bottom: 5px;"><strong style="margin-right: 15px;">Dia:</strong> {{this.searchedStartDate}}</div>
              <div class="row" style="margin-left: -2px;margin-top: 5px;margin-bottom: 5px;" v-if="this.searchedStartDateTime == '' && this.searchedEndDateTime == ''"><strong style="margin-right: 15px;">Turno:</strong> {{this.getShiftName(this.searchedShift)}}</div>
              <div class="row" style="margin-left: -2px;margin-top: 5px;margin-bottom: 5px;" v-if="this.searchedStartDateTime != '' || this.searchedEndDateTime != ''">
                <p style="text-align: left; padding: 6px 0;margin-right:10px;">
                  <strong>Das</strong> {{this.searchedStartDateTime}}:{{ this.searchedStartDateTimeMinute != ''? this.searchedStartDateTimeMinute : '00'}} <strong>até às</strong> {{this.searchedEndDateTime}}:{{ this.searchedEndDateTimeMinute != ''? this.searchedEndDateTimeMinute : '00'}}
                </p>
              </div>
              <div class="row">
                <div class="col-sm-4" style="margin-top: 5px;">
                  <strong>Bónus a aplicar:</strong>
                </div>
                <div class="col">
                  <b-form-select v-model="perc" style="cursor: pointer">
                    <b-form-select-option :value="''"></b-form-select-option>
                    <b-form-select-option :value="10">10%</b-form-select-option>
                    <b-form-select-option :value="15">15%</b-form-select-option>
                    <b-form-select-option :value="20">20%</b-form-select-option>
                    <b-form-select-option :value="25">25%</b-form-select-option>
                  </b-form-select>
                </div>
              </div>
            </b-modal>

            <b-modal :id="assignmentLogBonusModal.id" :title="assignmentLogBonusModal.title" size="lg" ok-only ok-variant="default" ok-title="Fechar" @hide="resetAssignmentLogBonusModal">
              <b-table  class="tables"
                responsive
                striped
                bordered
                outlined
                hover
                small
                no-border-collapse
                thead-class="cm-bg"
                id="assignmentLogBonusTable"
                :items="assignmentLogBonus"
                :fields="assignmentLogBonusFields"
                :busy="isBusyAssignmentLogBonus">
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'foo' ? '13%' : '13%' }"
                  >
                </template>
                <template #cell(logDateTime)="row">
                  {{getDateWithHoursFormated(row.item.logDateTime)}}
                </template>
              </b-table>
            </b-modal>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import { BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BTr ,BTh, BFormSelect, BPagination, BTabs, BTab, BRow, BPopover, BTooltip, BFormTextarea, BFormSelectOption    } from 'bootstrap-vue'
import PurchaseOrdersOCDataService from "src/services/PurchaseOrdersOCDataService"
import SessionDataService from "src/services/SessionDataService"
import UserDataService from "src/services/UserDataService"
import LogAcaoDataService from "src/services/LogAcaoDataService"
import POModal from 'src/components/global/POModal.vue';
import DAPerformanceChart from 'src/pages/DAPerformanceChart.vue';
export default {
  components: {
    LTable,
    Card,
    BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BTr ,BTh, BFormSelect, BPagination, BTabs, BTab, BRow, BPopover, BTooltip, BFormTextarea, BFormSelectOption,
    POModal,
    DAPerformanceChart
  },

  mounted () {
    this.getCurrentLoginInfo()
    this.getZones()
    var today = new Date()
    this.endDate = today.getFullYear()+ "-" + (today.getMonth()+1) + "-" + today.getDate()
    today.setDate(today.getDate() - 7)
    this.startDate = today.getFullYear()+ "-" + (today.getMonth()+1) + "-" + today.getDate()
  },

  data() {
    return {
      currentUser: {},
      currentUserRoles: [],
      currentUserPermissions: [],

      fields: [
        {
          key: 'selected',
          label: '',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'daName',
          label: 'Estafeta',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'nrRoutes',
          label: 'Nº de Rotas',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'nrOrders',
          label: 'Nº de Pedidos',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'totalDeliveryDelay',
          label: 'Desvio de Entregas',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'totalKmsPayed',
          label: 'Nº de Kms Pagos',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'ordersTotalPayed',
          label: 'Valor Pago',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'totalBonus',
          label: 'Total em Bónus',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'totalPayed',
          label: 'Total Pago (IVA inc.)',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-white'
        }
      ],
      items: [],
      isBusy: true,
      sortBy: 'estafeta_horaRest',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: 'id',
      currentPage: 1,
      perPage: 20,
      pageOptions: [1, 5, 10, 20, 50, 100],
      totalRows: 1,

      sortByPOs: 'estafeta_horaRest',
      sortDescPOs: false,
      sortDirectionPOs: 'asc',
      filterPOs: null,
      filterOnPOs: 'id',
      currentPagePOs: 1,
      perPagePOs: 20,
      totalRowsPOs: 1,

      zones: [],
      shifts: [
        {
          id: 0,
          name: "Ambos"
        },
        {
          id: 1,
          name: "Só Almoço"
        },
        {
          id: 2,
          name: "Só Jantar"
        }
      ],
      search: false,
      startDate: '',
      endDate: '',
      startDateTime: '',
      endDateTime: '',
      startDateTimeMinute: '',
      endDateTimeMinute: '',
      timePTLabels: {
        labelHours: 'Horas',
        labelMinutes: 'Minutos',
        labelSeconds: 'Segundos',
        labelIncrement: 'Adicionar',
        labelDecrement: 'Diminuir',
        labelSelected: 'Hora Selecionada',
        labelNoTimeSelected: '',
        labelCloseButton: 'Fechar'
      },
      zone: '',
      shift: 0,

      sortByAssignments: 'completionDateTime',
      sortDescAssignments: false,
      sortDirectionAssignments: 'asc',
      filterAssignments: null,
      filterOnAssignments: 'id',
      currentPageAssignments: 1,
      perPageAssignments: 20,
      totalRowsAssignments: 1,

      assignmentsFields: [
        {
          key: 'completionDateTime',
          label: 'Data de Conclusão',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'assignmentId',
          label: 'Nº Rota',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'numOrders',
          label: 'Nº Pedidos',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'da.userName',
          label: 'Estafeta',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'totalDistance',
          label: 'KMs',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'daPaymentAmount',
          label: 'Valor Pago',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'daBonus',
          label: 'Bónus',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'total',
          label: 'Valor Total Pago',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
      ],
      showAssignments: false,
      assignments: [],


      pedidosDAFields: [
        {
          key: 'datedeliveryTimeWindowEnd1',
          label: 'Data Entrega',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'id',
          label: 'PO',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'assignmentId',
          label: 'Nº Rota',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'userNameEstafeta',
          label: 'Estafeta',
          tdClass: 'text-left',
          thClass: 'text-white'
        },
        {
          key: 'supplierName',
          label: 'Restaurante',
          tdClass: 'text-left',
          thClass: 'text-white'
        },
        {
          key: 'deliveryDistance',
          label: 'KMs',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'orderValue',
          label: 'Valor',
          tdClass: 'text-right',
          thClass: 'text-white'
        },
        {
          key: 'desvioDelivery',
          label: 'Desvio Entrega',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'deliveryCity',
          label: 'Cidade',
          tdClass: 'text-left',
          thClass: 'text-white'
        },
        {
          key: 'deliveryCustomerName',
          label: 'Cliente',
          tdClass: 'text-left',
          thClass: 'text-white'
        }
      ],
      showPedidosDA: false,
      pedidosDA: [],

      sortByLogBonus: 'estafeta_horaRest',
      sortDescLogBonus: false,
      sortDirectionLogBonus: 'asc',
      filterLogBonus: null,
      filterOnLogBonus: 'id',
      currentPageLogBonus: 1,
      perPageLogBonus: 20,
      totalRowsLogBonus: 1,

      logBonusFields: [
        {
          key: 'logDateTime',
          label: 'Data',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'user.userName',
          label: 'Efetuada Por',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'day',
          label: 'Dia de Bónus',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'shift',
          label: 'Turno',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'zone',
          label: 'Zona',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'bonus',
          label: '% Bónus',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'da',
          label: 'Beneficiário',
          tdClass: 'text-center',
          thClass: 'text-white'
        }
      ],
      showLogBonus: false,
      logBonus: [],

      openModal: false,
      poModalType: '',
      selectedPO: {},
      selectedPONum: '',
      selectedDaId: '',

      searchedZone: '',
      searchedStartDate: '',
      searchedEndDate: '',
      searchedStartDateTime: '',
      searchedEndDateTime: '',
      searchedStartDateTimeMinute: '',
      searchedEndDateTimeMinute: '',
      searchedShift: 0,

      totalRow: {
        nrRoutes: 0,
        nrOrders: 0,
        totalDeliveryDelay: 0,
        totalKmsPayed: 0,
        ordersTotalPayed: 0,
        totalBonus: 0,
        totalPayed: 0,
      },

      chartRef: 'chartRef',
      tabIndex: 0,

      selectedDAs: [],
      selectAll: false,

      addBonusModal: {
        id: 'add-bonus-modal',
        title: '',
        content: ''
      },
      perc: '',

      assignmentLogBonusModal: {
        id: 'assignment-logbonus-modal',
        title: '',
        content: ''
      },

      assignmentLogBonusFields: [
        {
          key: 'logDateTime',
          label: 'Data',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'acao.name',
          label: 'Ação',
          tdClass: 'text-left',
          thClass: 'text-white'
        },
        {
          key: 'user.userName',
          label: 'Efetuada Por',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'day',
          label: 'Dia de Bónus',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'shift',
          label: 'Turno',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'zone',
          label: 'Zona',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'bonus',
          label: 'Bónus',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'da',
          label: 'Beneficiário',
          tdClass: 'text-center',
          thClass: 'text-white'
        }
      ],
      isBusyAssignmentLogBonus: true,
      assignmentLogBonus: [],
      isAllAssignments: false
    };
  },
  methods: {
    getZones(){
      PurchaseOrdersOCDataService.GetCurrentUserSupplierZones({} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.zones = response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getDAsPerformance(){
      this.searchedStartDate = this.startDate;
      this.searchedEndDate = this.endDate;
      this.searchedStartDateTime = this.startDateTime;
      this.searchedEndDateTime = this.endDateTime;
      this.searchedStartDateTimeMinute = this.startDateTimeMinute;
      this.searchedEndDateTimeMinute = this.endDateTimeMinute;
      this.searchedShift = this.shift;
      this.searchedZone = this.zone;
      PurchaseOrdersOCDataService.GetDAPerformancesByZone({zoneId: this.zone, startDate: this.startDate, endDate: this.endDate, shift: this.shift, startDateTimeHour: this.startDateTime != ''? this.startDateTime:null, endDateTimeHour: this.endDateTime != ''? this.endDateTime:null, startDateTimeMinute: this.startDateTimeMinute != ''? this.startDateTimeMinute:null, endDateTimeMinute: this.endDateTimeMinute != ''? this.endDateTimeMinute:null} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.items = this.formatPerformances(response.data.result), this.totalRows = this.items.length, this.getTotalRow(this.items), this.isBusy = false))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    formatPerformances(items){
      var newList = []
      items.forEach(item => {
        newList.push({
          daId: item.daId,
          daName: item.daName,
          nrOrders: item.nrOrders,
          nrRoutes: item.nrRoutes,
          ordersTotalPayed: item.ordersTotalPayed,
          totalBonus: item.totalBonus,
          totalDeliveryDelay: item.totalDeliveryDelay,
          totalKmsPayed: item.totalKmsPayed,
          totalPayed: item.totalPayed,
          selected: false
        })
      })
      return newList;
    },
    getTotalRow(items){
      items.forEach(element => {
        this.totalRow.nrRoutes += element.nrRoutes
        this.totalRow.nrOrders += element.nrOrders
        this.totalRow.totalDeliveryDelay += element.totalDeliveryDelay
        this.totalRow.totalKmsPayed += element.totalKmsPayed
        this.totalRow.ordersTotalPayed += element.ordersTotalPayed
        this.totalRow.totalBonus += element.totalBonus
        this.totalRow.totalPayed += element.totalPayed
      })
      this.totalRow = {
        nrRoutes: this.totalRow.nrRoutes,
        nrOrders: this.totalRow.nrOrders,
        totalDeliveryDelay: this.totalRow.totalDeliveryDelay,
        totalKmsPayed: this.round(this.totalRow.totalKmsPayed),
        ordersTotalPayed: this.round(this.totalRow.ordersTotalPayed),
        totalBonus: this.round(this.totalRow.totalBonus),
        totalPayed: this.round(this.totalRow.totalPayed)
      }
      this.selectAll = false
    },
    chartTabClicked(){
      if(this.chartRef.charAt(0) != "|"){
        this.chartRef = "|"+this.chartRef
      }
    },

    toogleSearch(){
      this.search = true
      this.selectedDaId = ''
      this.showPedidosDA = false
      this.showLogBonus = false
      this.showAssignments = false
      this.pedidosDA = []
      this.logBonus = []
      this.assignments = []
      this.selectedDAs = []
      this.selectAll = false
      this.totalRow = {
        nrRoutes: 0,
        nrOrders: 0,
        totalDeliveryDelay: 0,
        totalKmsPayed: 0,
        ordersTotalPayed: 0,
        totalBonus: 0,
        totalPayed: 0,
      }
      this.chartRef = this.zone + " - " + this.shift + " - " + this.startDate + " - " + this.endDate + " - " + this.startDateTime + " - " + this.endDateTime + " - " + this.startDateTimeMinute + " - " + this.endDateTimeMinute
      if(this.tabIndex == 1){
        this.chartRef = "|"+this.chartRef
      }
      this.postSearch()
    },
    postSearch(){
      this.items = []
      this.isBusy = true
      this.getDAsPerformance()
    },
    handlePageChange(value) {
      this.isBusy = true
      this.currentPage = value;
      this.getDAsPerformance()
    },
    handlePageSizeChange(event) {
      this.isBusy = true
      this.pageSize = event;
      this.currentPage = 1;
      this.getDAsPerformance()
    },
    sortingChanged(ctx) {
      this.isBusy = true
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.getDAsPerformance()
    },


    seeAssignments(daId){
      this.selectedDaId = daId
      this.showAssignments = false
      this.showPedidosDA = false
      this.showLogBonus = false
      this.assignments = []
      this.isAllAssignments = false
      this.getAssignments(daId)
    },
    seeAllAssignments(daId){
      this.selectedDaId = daId
      this.showAssignments = false
      this.showPedidosDA = false
      this.showLogBonus = false
      this.assignments = []
      this.isAllAssignments = true
      this.getAllAssignments(daId)
    },
    getAssignments(daId){
      PurchaseOrdersOCDataService.GetAssignmentsByDA({pageNumber: this.currentPageAssignments, pageSize: this.perPageAssignments, sortOrder: this.sortByAssignments, sortDesc: this.sortDescAssignments, daId, startDate: this.searchedStartDate, endDate: this.searchedEndDate, shift: this.searchedShift, startDateTimeHour: this.searchedStartDateTime != ''? this.searchedStartDateTime:null, endDateTimeHour: this.searchedEndDateTime != ''? this.searchedEndDateTime:null, startDateTimeMinute: this.searchedStartDateTimeMinute != ''? this.searchedStartDateTimeMinute:null, endDateTimeMinute: this.searchedEndDateTimeMinute != ''? this.searchedEndDateTimeMinute:null} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.assignments = response.data.result.items, this.isBusyAssignments=false, this.totalRowsAssignments = response.data.result.totalCount, this.showAssignments = true))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getAllAssignments(daId){
      PurchaseOrdersOCDataService.GetAllAssignmentsByDA({pageNumber: this.currentPageAssignments, pageSize: this.perPageAssignments, sortOrder: this.sortByAssignments, sortDesc: this.sortDescAssignments, daId, startDate: this.searchedStartDate, endDate: this.searchedEndDate, shift: this.searchedShift, startDateTimeHour: this.searchedStartDateTime != ''? this.searchedStartDateTime:null, endDateTimeHour: this.searchedEndDateTime != ''? this.searchedEndDateTime:null, startDateTimeMinute: this.searchedStartDateTimeMinute != ''? this.searchedStartDateTimeMinute:null, endDateTimeMinute: this.searchedEndDateTimeMinute != ''? this.searchedEndDateTimeMinute:null} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.assignments = response.data.result.items, this.isBusyAssignments=false, this.totalRowsAssignments = response.data.result.totalCount, this.showAssignments = true))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    handlePageChangeAssignments(value) {
      this.isBusyAssignments = true
      this.currentPageAssignments = value;
      this.getAssignments(this.selectedDaId)
    },
    handlePageSizeChangeAssignments(event) {
      this.isBusyAssignments = true
      this.perPageAssignments = event;
      this.currentPageAssignments = 1;
      this.getAssignments(this.selectedDaId)
    },
    sortingChangedAssignments(ctx) {
      this.isBusyAssignments = true
      this.sortByAssignments = ctx.sortBy
      this.sortDescAssignments = ctx.sortDesc
      this.getAssignments(this.selectedDaId)
    },


    getPedidosByDA(daId){
      PurchaseOrdersOCDataService.GetPedidosByDA({pageNumber: this.currentPagePOs, pageSize: this.perPagePOs, sortOrder: this.sortByPOs, sortDesc: this.sortDescPOs, daId, startDate: this.searchedStartDate, endDate: this.searchedEndDate, shift: this.searchedShift, startDateTimeHour: this.searchedStartDateTime != ''? this.searchedStartDateTime:null, endDateTimeHour: this.searchedEndDateTime != ''? this.searchedEndDateTime:null, startDateTimeMinute: this.searchedStartDateTimeMinute != ''? this.searchedStartDateTimeMinute:null, endDateTimeMinute: this.searchedEndDateTimeMinute != ''? this.searchedEndDateTimeMinute:null},{ Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.pedidosDA = this.formatPOs(response.data.result.items), this.totalRowsPOs = response.data.result.totalCount, this.isBusyPOs = false, this.showPedidosDA = true))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    seeDAPOs(daId, index, button){
      if(button != ''){
        if(button.target.className == "updateIcon"){
          return;
        }
      }
      this.selectedDaId = daId
      this.showAssignments = false
      this.showPedidosDA = false
      this.showLogBonus = false
      this.pedidosDA = []
      this.getPedidosByDA(daId)
    },


    handlePageChangePOs(value) {
      this.isBusyPOs = true
      this.currentPagePOs = value;
      this.getPedidosByDA(this.selectedDaId)
    },
    handlePageSizeChangePOs(event) {
      this.isBusyPOs = true
      this.perPagePOs = event;
      this.currentPagePOs = 1;
      this.getPedidosByDA(this.selectedDaId)
    },
    sortingChangedPOs(ctx) {
      this.isBusyPOs = true
      this.sortByPOs = ctx.sortBy
      this.sortDescPOs = ctx.sortDesc
      this.getPedidosByDA(this.selectedDaId)
    },



    formatPOs(pos){
      var list = [];
      pos.forEach(element => {
        var now = new Date();
        if(element.isDelivered || element.isCancelled){
          if(element.isDelivered){
            this.est = '';
            this.estState = 8;
            this.estDescr = 'Finalizada';
          }else if(element.isCancelled){
            this.est = '';
            this.estState = 9;
            this.estDescr = 'Cancelada';
          }
        } else{
          if(element.assignment == null){
            var dt = new Date(element.deliveryTimeWindowStart);
            var dtAssign = new Date(dt.getTime() - 20*60000);
            var dif = (Math.round(((now-dtAssign)/(1000*60) + Number.EPSILON)))
            if(dif > 0){
              this.est = " "+ Math.round((Math.abs(now-dtAssign)/(1000*60) + Number.EPSILON)) + " min";
              this.estState = 2;
              this.estDescr = 'Á deriva há'+ Math.round((Math.abs(now-dtAssign)/(1000*60) + Number.EPSILON)) + " min";
            } else {
              this.est = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON)))-20 + " min";
              this.estState = 3;
              this.estDescr = 'Envio em '+ (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON)))-20 + " min";
            }
          } else{
            if(!element.isPickedup){
              if(element.daArrivedAtPickupLocationDateTime == null){
                if(element.assignedDateTime == null){
                  this.est = " ";
                  this.estState = 1;
                  this.estDescr = "A tocar no Estafeta";
                } else {
                  var dt = new Date(element.assignedDateTime);
                  this.est = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
                  this.estState = 4;
                  this.estDescr = '>>Rest há ' + (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
                }
              }else if(element.daArrivedAtPickupLocationDateTime != null){
                var dt = new Date(element.daArrivedAtPickupLocationDateTime);
                this.est = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
                this.estState = 5;
                this.estDescr = 'Rest há '+ (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
              }
            }else if(!element.isDelivered){
              if(element.daArrivedAtDeliveryLocationDateTime == null){
                var dt = new Date(element.pickedupDateTime);
                this.est = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
                this.estState = 6;
                this.estDescr = '>>Cliente há '+ (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
              }else if(element.daArrivedAtDeliveryLocationDateTime != null){
                var dt = new Date(element.daArrivedAtDeliveryLocationDateTime);
                this.est = " "+ (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
                this.estState = 7;
                this.estDescr = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
              }
            } else{
              this.est = '111';
              this.estState = '111';
            }
          }
        }
        var DateEntregaInicial = new Date(element.deliveryTimeWindowEnd)
        var DateEntrega = new Date(DateEntregaInicial.getTime() + 20*60000);
        var DateAssigned = new Date(element.assignedDateTime)
        var DatePO = new Date(element.poDateTime)
        var DateRest = new Date(element.deliveryTimeWindowStart)
        if(element.itemsDescription == null){
          element.itemsDescription = ' '
        }
        var alert = false
        if(new Date() > new Date(DateEntregaInicial.getTime() + 22*60000) && this.estState < 7)
        {
          alert = true
        }
        var desvioDelivery = ''
        if(element.isDelivered){
          var deliveryDate = new Date(element.daArrivedAtDeliveryLocationDateTime)
          if(deliveryDate > DateEntrega){
            var desvio = (Math.round((Math.abs(deliveryDate-DateEntrega)/(1000*60) + Number.EPSILON)))
            desvioDelivery = "+"+ desvio + " min"
          }else if(deliveryDate < DateEntregaInicial){
            var desvio = (Math.round((Math.abs(DateEntregaInicial-deliveryDate)/(1000*60) + Number.EPSILON)))
            desvioDelivery = "-"+ desvio + " min"
          }
        }
        if(element.assignment != null){
          var nomeEst = ''
          var userNameEst = ''
          var estafetaPhone = ''
          var daLocationDate = 'Sem Estafeta'
          if(element.assignment.da != null){
            nomeEst = element.assignment.da.fullName
            userNameEst = element.assignment.da.userName + " - " + element.assignment.da.daId
            estafetaPhone = element.assignment.da.phone
            if(element.assignment.da.locationUpdatedDate != null){
              var DALocationDate = new Date(element.assignment.da.locationUpdatedDate)
              daLocationDate = String(DALocationDate.getDate()).padStart(2, '0') + "/" + String(DALocationDate.getMonth()+1).padStart(2, '0') + "/" + String(DALocationDate.getFullYear()) + " " +String(DALocationDate.getHours()).padStart(2, '0') + ":" + String(DALocationDate.getMinutes()).padStart(2, '0')
            }else{
              daLocationDate = 'Sem Localização do Estafeta'
            }
          }
          list.push({
            element,
            estado: this.est,
            estDescr: this.estDescr,
            estadoState: this.estState,
            id: element.poNum,
            assignmentId: element.assignmentId,
            orderId: element.orderId,
            nomeEstafeta: nomeEst,
            userNameEstafeta: userNameEst,
            estafetaPhone: estafetaPhone,
            deliveryCustomerName: element.deliveryCustomerName,
            customerPhone: element.customerPhone.substring(0,2) + " " + element.customerPhone.substring(2,5) + " " + element.customerPhone.substring(5,7) + " " + element.customerPhone.substring(7,9),
            supplierName: element.supplierName,
            supplierPhone: element.supplierPhone,
            deliveryCity: element.deliveryCity,
            deliveryDistance: this.round(element.deliveryDistance),
            assignedDateTime: String(DateAssigned.getHours()).padStart(2, '0') + ":" + String(DateAssigned.getMinutes()).padStart(2, '0'),
            poDateTime: String(DatePO.getDate()).padStart(2, '0') + "/" + String(DatePO.getMonth()+1).padStart(2, '0') + "/" + String(DatePO.getFullYear()) + " " +String(DatePO.getHours()).padStart(2, '0') + ":" + String(DatePO.getMinutes()).padStart(2, '0'),
            datedeliveryTimeWindowStart1: String(DateRest.getDate()).padStart(2, '0') + "/" + String(DateRest.getMonth()+1).padStart(2, '0') + "/" + String(DateRest.getFullYear()) + " " +String(DateRest.getHours()).padStart(2, '0') + ":" + String(DateRest.getMinutes()).padStart(2, '0'),
            deliveryTimeWindowStart1: String(DateRest.getHours()).padStart(2, '0') + ":" + String(DateRest.getMinutes()).padStart(2, '0'),
            datedeliveryTimeWindowEnd1: String(DateEntregaInicial.getDate()).padStart(2, '0') + "/" + String(DateEntregaInicial.getMonth()+1).padStart(2, '0') + "/" + String(DateEntregaInicial.getFullYear()) + " " +String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0'),
            deliveryTimeWindowEnd1: String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0') + " - " + String(DateEntrega.getHours()).padStart(2, '0') + ":" + String(DateEntrega.getMinutes()).padStart(2, '0'),
            orderValue: this.round(element.orderValue) + "€" + " / " + element.paymentType,
            amountDue: this.round(element.amountDue) + "€",
            steps: JSON.parse(element.assignment.steps),
            totalDistance: this.round(element.assignment.totalDistance),
            assignmentPOs: element.assignment.POs,
            authorizationCode: element.authorizationCode,
            itemsDescription: element.itemsDescription,
            daLocationDate: daLocationDate,
            realizado: element.source == "STORE_FRONT"? "Web" : (element.source == "CONTACT_CENTER"? "Contact-Center" : (element.source == "OPS_CENTRAL"? "Central Operações" : "")),
            alert: alert,
            desvioDelivery: element.deliverAsap? '' : desvioDelivery,
            // _rowVariant: alert? 'danger': null
            _cellVariants: { deliveryTimeWindowEnd1: alert? 'danger': null}
          })
        }else{
          list.push({
            element,
            id: element.poNum,
            assignmentId: element.assignmentId,
            estado: this.est,
            estDescr: this.estDescr,
            estadoState: this.estState,
            orderId: element.orderId,
            nomeEstafeta: "",
            userNameEstafeta: "",
            deliveryCustomerName: element.deliveryCustomerName,
            customerPhone: element.customerPhone.substring(0,2) + " " + element.customerPhone.substring(2,5) + " " + element.customerPhone.substring(5,7) + " " + element.customerPhone.substring(7,9),
            supplierName: element.supplierName,
            supplierPhone: element.supplierPhone,
            deliveryCity: element.deliveryCity,
            deliveryDistance: this.round(element.deliveryDistance),
            assignedDateTime: '',
            poDateTime: String(DatePO.getDate()).padStart(2, '0') + "/" + String(DatePO.getMonth()+1).padStart(2, '0') + "/" + String(DatePO.getFullYear()) + " " +String(DatePO.getHours()).padStart(2, '0') + ":" + String(DatePO.getMinutes()).padStart(2, '0'),
            datedeliveryTimeWindowStart1: String(DateRest.getDate()).padStart(2, '0') + "/" + String(DateRest.getMonth()+1).padStart(2, '0') + "/" + String(DateRest.getFullYear()) + " " +String(DateRest.getHours()).padStart(2, '0') + ":" + String(DateRest.getMinutes()).padStart(2, '0'),
            deliveryTimeWindowStart1: String(DateRest.getHours()).padStart(2, '0') + ":" + String(DateRest.getMinutes()).padStart(2, '0'),
            datedeliveryTimeWindowEnd1: String(DateEntregaInicial.getDate()).padStart(2, '0') + "/" + String(DateEntregaInicial.getMonth()+1).padStart(2, '0') + "/" + String(DateEntregaInicial.getFullYear()) + " " +String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0'),
            deliveryTimeWindowEnd1: String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0') + " - " + String(DateEntrega.getHours()).padStart(2, '0') + ":" + String(DateEntrega.getMinutes()).padStart(2, '0'),
            orderValue: this.round(element.orderValue) + "€" + " / " + element.paymentType,
            amountDue:this.round(element.amountDue) + "€",
            totalDistance: '',
            steps: '',
            assignmentPOs:[],
            authorizationCode: element.authorizationCode,
            itemsDescription: element.itemsDescription,
            daLocationDate: daLocationDate,
            realizado: element.source == "STORE_FRONT"? "Web" : (element.source == "CONTACT_CENTER"? "Contact-Center" : (element.source == "OPS_CENTRAL"? "Central Operações" : "")),
            alert: alert,
            desvioDelivery: element.deliverAsap? '' : desvioDelivery,
            // _rowVariant: alert? 'danger': null
            _cellVariants: { deliveryTimeWindowEnd1: alert? 'danger': null}
          })
        }
      })
      return list
    },


    seePOModal(item, index, button, type) {
      if(button != ''){
        if(button.target.className == "updateIcon"){
          return;
        }
      }
      this.selectedPO = item
      this.selectedPONum = item.id
      this.poModalType = type
      if(this.poModalType == 'assignment'){
        if(this.isAllAssignments){
          this.poModalType = 'allAssignment'
        }
      }
      this.openModal = true
    },
    closePOModal() {
      this.selectedPO = {}
      this.selectedPONum = ''
      this.openModal = false
      if(this.poModalType == 'po'){
        this.getPedidosByDA(this.selectedDaId)
      } else if(this.poModalType == 'assignment'){
        this.getAssignments(this.selectedDaId)
      } else if(this.poModalType == 'allAssignment'){
        this.getAllAssignments(this.selectedDaId)
      }
      this.poModalType = ''
    },



    onSelected(item) {
      if(item.selected){
        this.selectedDAs.push(item)
        const i = this.items.findIndex(n => n.selected === false);
        if (i === -1) {
          this.selectAll = true
        }
      }else{
        const indexToRemove = this.selectedDAs.findIndex(n => n.daId === item.daId);
        if (indexToRemove !== -1) {
          this.selectedDAs.splice(indexToRemove, 1);
          this.selectAll = false
        }
      }
    },
    selectAllRows(selectAll){
      if(selectAll){
        this.items.forEach(element => element.selected = true)
        this.selectedDAs = this.softCopyList(this.items)
      } else {
        this.items.forEach(element => element.selected = false)
        this.selectedDAs = []
        this.selectAll = false
      }
    },
    softCopyList(items){
      var newList = []
      items.forEach(item => newList.push(item))
      return newList
    },

    addBonusInfo() {
      this.addBonusModal.title = 'Aplicar Bónus a Estafetas'
      this.addBonusModal.content = ''
      this.$root.$emit('bv::show::modal', this.addBonusModal.id, '')
    },
    resetAddBonusModal() {
      this.addBonusModal.title = ''
      this.addBonusModal.content = ''
      this.perc = ''
    },
    applyBonus(selDAs, date, shift, perc, startDateTime,endDateTime, startDateTimeMinute, endDateTimeMinute){
      var today = new Date();
      var logAcao = {
        acaoId: 35,
        userId: this.currentUser.id,
        previous:  '',
        parameters: '+'+perc+'%',
        daId: '',
        daUserName: '',
        logDateTime: today,
        observations: this.getZoneName(this.searchedZone) +' | '+ date + ' | ' + ((startDateTime == '' && endDateTime == '')? this.getShiftName(this.searchedShift) :  ((startDateTime != ''? ' Das ' + startDateTime + ':' + (startDateTimeMinute != ''? startDateTimeMinute: '00'): '') +  (endDateTime != ''? ' até às ' + endDateTime + ':' + (endDateTimeMinute != ''? endDateTimeMinute: '00') : ''))),
      }
      var logBonus = {
        das: selDAs,
        logAcao: logAcao
      }
      LogAcaoDataService.ApplyBonus(logBonus, {date: date, shift: shift, perc: perc, startDateTimeHour: startDateTime != ''? startDateTime:null, endDateTimeHour: endDateTime != ''? endDateTime:null, startDateTimeMinute: startDateTimeMinute != ''? startDateTimeMinute:null, endDateTimeMinute: endDateTimeMinute != ''? endDateTimeMinute:null} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.toogleSearch()))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getZoneName(zoneId){
      const zone = this.zones.findIndex(n => n.id === zoneId);
      if (zone !== -1) {
        return this.zones[zone].name
      }
      else{
        return ''
      }
    },
    getShiftName(shiftId){
      const shift = this.shifts.findIndex(n => n.id === shiftId);
      if (shift !== -1) {
        return this.shifts[shift].name
      }
      else{
        return ''
      }
    },


    seeLogBonus(){
      this.showAssignments = false
      this.showPedidosDA = false
      this.showLogBonus = false
      this.logBonus = []
      this.getLogBonus()
    },
    getLogBonus(){
      LogAcaoDataService.GetLogBonus({searchDataI: this.searchedStartDate, searchDataF: this.searchedEndDate, pageIndex: this.currentPageLogBonus, pageSize: this.perPageLogBonus} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.logBonus = this.formatLogBonus(response.data.result.items), this.isBusyLogBonus=false, this.totalRowsLogBonus = response.data.result.totalCount, this.showLogBonus = true))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    formatLogBonus(items){
      var newList = []
      items.forEach(item => {
        if(item.observations != ''){
          var a = item.observations.split(' | ');
          newList.push({
            logDateTime: item.logDateTime,
            acao: item.acao,
            user: item.user,
            day: a[1],
            shift: a[2],
            bonus: item.parameters,
            da: item.daUserName,
            zone: a[0]
          })
        } else {
          newList.push({
            logDateTime: item.logDateTime,
            acao: item.acao,
            user: item.user,
            day: '',
            shift: '',
            bonus: item.parameters,
            da: item.daUserName,
            zone: ''
          })
        }
      })
      return newList;
    },
    handlePageChangeLogBonus(value) {
      this.isBusyLogBonus = true
      this.currentPageLogBonus = value;
      this.getLogBonus()
    },
    handlePageSizeChangeLogBonus(event) {
      this.isBusyLogBonus = true
      this.perPageLogBonus = event;
      this.currentPageLogBonus = 1;
      this.getLogBonus()
    },



    assignmentLogBonusInfo(assignment) {
      this.assignmentLogBonusModal.title = 'Bónus aplicados'
      this.assignmentLogBonusModal.content = ''
      this.getAssignmentLogBonus(assignment)
      this.$root.$emit('bv::show::modal', this.assignmentLogBonusModal.id, '')
    },
    resetAssignmentLogBonusModal() {
      this.assignmentLogBonusModal.title = ''
      this.assignmentLogBonusModal.content = ''
      this.assignmentLogBonus = []
    },
    getAssignmentLogBonus(assignment){
      var poNums = []
      assignment.purchaseOrders.forEach(po => poNums.push(po.poNum))
      LogAcaoDataService.GetAssignmentLogBonus(poNums, {daId: assignment.daId, searchDataI: this.searchedStartDate, searchDataF: this.searchedEndDate, pageIndex: this.currentPageLogBonus, pageSize: this.perPageLogBonus} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.assignmentLogBonus = this.formatLogBonus(response.data.result.items), this.isBusyAssignmentLogBonus=false))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },





    getCurrentLoginInfo(){
      SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => {
          (this.currentUser = response.data.result.user);
          if(this.currentUser == null){
            this.$router.push({ path: '/login', query: { returnUrl: this.$route.path } })
          }else{
            this.getCurrentUserRoles()
            this.getCurrentUserPermissions()
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getCurrentUserRoles(){
      UserDataService.GetCurrentUserInfo({Id: this.currentUser.id} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.currentUserRoles = response.data.result.roleNames))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getCurrentUserPermissions(){
      UserDataService.GetCurrentUserPermissions({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.currentUserPermissions = response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    hasPermissions(element){
      if(this.currentUserPermissions.includes(element)){
        return true;
      }else{
        return false;
      }
    },

    dateDiffInDays(startDate, endDate){
      var sDate = new Date(startDate + " 00:00:00");
      var eDate = new Date(endDate + " 00:00:00");

      var dif= Math.abs(eDate-sDate);
      var d = dif/(1000 * 3600 * 24)

      return d;
    },
    getDateWithHoursFormated(date){
      var dt = new Date(date)
      var str = dt.getDate().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + "/" +
                (dt.getMonth()+1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + "/" + dt.getFullYear() + " " +
                dt.getHours().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + ":" +
                dt.getMinutes().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                })
      return str
    },
    round(value) {
      if(value != null){
        return Number(Math.round(value+'e'+2)+'e-'+2).toFixed(2)
      } else {
        return '0.00';
      }
    }

  },


};
</script>
<style>
  .border{
    /* border: 1px solid #DAA520 !important; */
    margin: 0;
  }
</style>

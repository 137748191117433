<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <b-row style="width:100%">
        <b-col>
          <b-row style="width:100%">
            <b-col sm="1" style="margin:auto; width:10%">
              <button type="button"
                      class="navbar-toggler navbar-toggler-left"
                      :class="{toggled: $sidebar.showSidebar}"
                      style="display: block !important;"
                      aria-controls="navigation-index"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      @click="toggleSidebar">
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
              </button>
            </b-col>
            <b-col sm="11" style="width:90%">
              <a class="navbar-brand" href="#/admin/pedidosativos">Central de Operações</a>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <ul class="navbar-nav ml-auto" style="flex-direction: unset !important;">
            <li class="dropdown nav-item tables"
                :class="{show: isOpen}"
                aria-haspopup="true"
                :aria-expanded="isOpen"
                @click="toggleDropDown"
                style="margin-left: auto; margin-right: 0;"
                v-click-outside="closeDropDown" v-if="this.currentUser != ''">
              <a class="nav-link dropdown-toggle"
                data-toggle="dropdown">
                  <p style="color: #DAA520"><i class="nc-icon nc-circle-09" style="margin-right: 10px"></i>{{this.currentUser.userName}} <label style="font-size:10px;color: gray; cursor: pointer">{{this.currentUserRoles}}</label></p>
              </a>
              <div class="dropdown-menu show" v-show="isOpen">
                <slot>
                  <a class="dropdown-item" @click="changePasswordInfo($event.target)">Alterar Palavra-Passe</a>
                </slot>
              </div>
            </li>
            <li class="nav-item" v-if="this.currentUser == ''"
                style="margin-left: auto; margin-right: 0;">
              <a class="nav-link" @click="loginInfo($event.target)">
                Entrar
              </a>
              <b-modal :id="loginModal.id" :title="loginModal.title"  ok-variant="success" @hide="resetLoginModal" @ok="postLogin(username, password)">
                UserName
                <b-form-input
                  id="username-input"
                  v-model="username"
                  required
                ></b-form-input>
                Password
                <b-form-input
                  id="password-input"
                  v-model="password"
                  required
                  type="password"
                ></b-form-input>
              </b-modal>
            </li>
            <li class="nav-item"  v-if="this.currentUser != ''"
                style=" margin-right: 0;"><!--margin-left: auto;-->
              <a class="nav-link" @click="logoutInfo($event.target)">
                Sair
              </a>
            </li>
          </ul>
        </b-col>
      </b-row>


      <b-modal :id="logoutModal.id" :title="logoutModal.title" ok-variant="success" ok-title="Sim" cancel-title="Não" @hide="resetLogoutModal" @ok="logout()">
      </b-modal>
      <b-modal :id="changePasswordModal.id" :title="changePasswordModal.title" :ok-disabled="oldPassword == '' || newPassword == '' || newPasswordConf == '' || !newPasswordState || !newPasswordConfState" ok-variant="warning" ok-title="Alterar" cancel-title="Cancelar" @hide="resetChangePasswordModal" @ok="changePassword(oldPassword, newPassword)">
        <b-row style="margin: auto;width: 100%;">
          <b-col lg="5" class="my-1">
            <p style="text-align: right; padding: 6px 0;">Palavra-Passe</p>
          </b-col>
          <b-col lg="6" class="my-1">
            <b-form-input
              id="oldPassword-input"
              v-model="oldPassword"
              required
              :type="oldPasswordType"
            ></b-form-input>
          </b-col>
          <b-col lg="1" class="my-1">
            <i class="fa fa-eye-slash reload" title="Mostrar" style="color: gray" @click="changeOldPasswordType" v-if="this.oldPasswordType == 'password'"></i>
            <i class="fa fa-eye reload" title="Esconder" style="color: green" @click="changeOldPasswordType" v-if="this.oldPasswordType != 'password'"></i>
          </b-col>
        </b-row>
        <b-row style="margin: auto;width: 100%;">
          <b-col lg="5" class="my-1">
            <p style="text-align: right; padding: 6px 0;">Nova Palavra-Passe</p>
          </b-col>
          <b-col lg="6" class="my-1">
            <b-form-input
              id="newPassword-input"
              v-model="newPassword"
              required
              :type="newPasswordType"
              :state="newPasswordState"
              @change="verifyNewPassword"
            ></b-form-input>
            <b-form-invalid-feedback id="newPassword-input-feedback">
              A Palavra-Passe deve ter pelo menos:<br>
              * 8 caracteres;<br>
              * uma letra Maiúscula;<br>
              * uma letra Minúscula;<br>
              * um  número;
            </b-form-invalid-feedback>
          </b-col>
          <b-col lg="1" class="my-1">
            <i class="fa fa-eye-slash reload" title="Mostrar" style="color: gray" @click="changeNewPasswordType" v-if="this.newPasswordType == 'password'"></i>
            <i class="fa fa-eye reload" title="Esconder" style="color: green" @click="changeNewPasswordType" v-if="this.newPasswordType != 'password'"></i>
          </b-col>
        </b-row>
        <b-row style="margin: auto;width: 100%;">
          <b-col lg="5" class="my-1">
            <p style="text-align: right; padding: 6px 0;">Confirmação da Nova Palavra-Passe</p>
          </b-col>
          <b-col lg="6" class="my-1">
            <b-form-input
              id="newPasswordConf-input"
              v-model="newPasswordConf"
              required
              :type="newPasswordType"
              :state="newPasswordConfState"
              @change="verifyNewPasswordConf"
            ></b-form-input>
            <b-form-invalid-feedback id="newPasswordConf-input-feedback">
              A Confirmação da Nova Palavra-Passe deve ser igual à Nova Palavra-Passe.
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </nav>
</template>
<script>
import { BFormInput, BModal } from 'bootstrap-vue'
import axios from 'axios'
import AuthenticateDataService from "src/services/AuthenticateDataService"
import SessionDataService from "src/services/SessionDataService"
import UserAssignmentDataService from "src/services/UserAssignmentDataService"
import UserDataService from 'src/services/UserDataService'

  export default {
      components: {
        BFormInput, BModal
      },
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      }
    },
    mounted () {
      this.getCurrentLoginInfo()
    },
    data () {
      return {
        logoutModal: {
          id: 'logout-modal',
          title: '',
          content: ''
        },
        loginModal: {
          id: 'login-modal',
          title: '',
          content: ''
        },
        changePasswordModal: {
          id: 'changePassword-modal',
          title: '',
          content: ''
        },
        username: '',
        password: '',
        currentUser: {},
        currentUserRoles: '',
        isOpen: false,

        oldPassword: '',
        newPassword: '',
        newPasswordConf: '',
        oldPasswordType: 'password',
        newPasswordType: 'password',

        newPasswordState: null,
        newPasswordConfState: null
      }
    },
    methods: {
      toggleDropDown () {
        this.isOpen = !this.isOpen
        this.$emit('change', this.isOpen)
      },
      closeDropDown () {
        this.isOpen = false
        this.$emit('change', this.isOpen)
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      logoutInfo(button) {
        this.logoutModal.title = `Tem a certeza que pretende sair da conta?`
        this.logoutModal.content = ''
        this.$root.$emit('bv::show::modal', this.logoutModal.id, button)
      },
      resetLogoutModal() {
        this.logoutModal.title = ''
        this.logoutModal.content = ''
      },
      loginInfo(button) {
        this.loginModal.title = `Login`
        this.loginModal.content = ''
        this.$root.$emit('bv::show::modal', this.loginModal.id, button)
      },
      resetLoginModal() {
        this.loginModal.title = ''
        this.loginModal.content = ''
        this.username = ''
        this.password = ''
      },
      changePasswordInfo(button) {
        this.changePasswordModal.title = 'Alterar Palavra-Passe'
        this.changePasswordModal.content = ''
        this.$root.$emit('bv::show::modal', this.changePasswordModal.id, button)
      },
      resetChangePasswordModal() {
        this.changePasswordModal.title = ''
        this.changePasswordModal.content = ''
        this.oldPassword = ''
        this.newPassword = ''
        this.newPasswordConf = ''
        this.oldPasswordType = 'password'
        this.newPasswordType = 'password'
        this.newPasswordState = null
        this.newPasswordConfState = null
      },
      verifyNewPassword(){
        this.verifyNewPasswordConf()
        let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
        if(!strongPassword.test(this.newPassword)) {
          this.newPasswordState = false
          return false
        }
        this.newPasswordState = true

        return true
      },
      verifyNewPasswordConf(){
        if(this.newPassword != this.newPasswordConf){
          this.newPasswordConfState = false
          return false
        }
        this.newPasswordConfState = true
        return true
      },
      changeOldPasswordType(){
        if(this.oldPasswordType == 'password'){
          this.oldPasswordType = 'text'
        }
        else
        {
          this.oldPasswordType = 'password'
        }
      },
      changeNewPasswordType(){
        if(this.newPasswordType == 'password'){
          this.newPasswordType = 'text'
        }
        else
        {
          this.newPasswordType = 'password'
        }
      },
      getCurrentLoginInfo(){
        if(localStorage.getItem('user-token') != null){
          SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
            .then(response => {
              this.currentUser = response.data.result.user;
              if(this.currentUser != null){
                this.getCurrentUserRoles()
              }
            })
            .catch(error => {
              console.log(error)
            })
            .finally()
        }
      },
      getCurrentUserRoles(){
        UserAssignmentDataService.GetCurrentUserRolesNames({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
          .then(response => (this.currentUserRoles = this.formatRoles(response.data.result)))
          .catch(error => {
            console.log(error)
          })
          .finally()
      },
      postLogin(username, password){
        const body = {userNameOrEmailAddress: username, password: password, rememberClient: true}
        AuthenticateDataService.Login(body)
          .then(response => (
            console.log(response), this.$fire({
              title: "Sucesso",
              type: "success",
              timer: 2000
            }), localStorage.setItem('user-token', response.data.result.accessToken), this.getCurrentLoginInfo()
          ))
          .catch(error => {
            console.log(error)
            this.errored = true
            this.$fire({
              title: "Erro",
              text: "Ocorreu um erro, verifique os dados!",
              type: "error",
              timer: 3000
            })
            localStorage.removeItem('user-token')
          })
          .finally(() => this.errored = false)
      },
      logout(){
        this.currentUser = {}
        localStorage.removeItem('user-token')
        this.$emit("authenticated", false);
        this.$router.replace({ name: "login" });
        // this.$parent.logout()
      },
      changePassword(oldPassword, newPassword){
        UserDataService.ChangePassword({currentPassword: oldPassword, newPassword: newPassword}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
          .then(response => (this.$fire({
              title: "Palavra-Passe alterada com sucesso!",
              type: "success",
              timer: 2000
            }), this.logout()
          ))
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.errored = false)
      },

      formatRoles(roles){
        var str = '('
        roles.forEach((r, index) => str = str + r + (index < (roles.length-1)? " | ": ')'))
        return str
      }
    }
  }

</script>
<style>
</style>


import http from "../http-common-oc";

class UserAssignmentDataService {
  AddZoneToUser(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/UserAssignmentToZones/ActivePOsByZones", {}, config);
  };

  GetAllUsers(params, headers) {
    let config = {
      params: params,
      headers: headers
    }
    return http.get("/services/app/UserAssignmentToZones/GetUserWithZones", config);
  };
  GetAllPermissions(params, headers) {
    let config = {
      params: params,
      headers: headers
    }
    return http.get("/services/app/Role/GetAllPermissions", config);
  };
  GetAllRoles(params, headers) {
    let config = {
      params: params,
      headers: headers
    }
    return http.get("/services/app/Role/GetAll", config);
  };
  GetAllSupplierZones(params, headers) {
    let config = {
      params: params,
      headers: headers
    }
    return http.get("/services/app/SupplierZone/GetAll", config);
  };
  RemoveZoneFromUser(params, headers) {
    let config = {
      params: params,
      headers: headers
    }
    return http.delete("/services/app/UserAssignmentToZones/RemoveZoneFromUser", config);
  };
  AddZoneToUser(payload, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/UserAssignmentToZones/Create", payload, config);
  };

  UpdateUser(payload, headers) {
    let config = {
      headers: headers
    }
    return http.put("/services/app/User/Update", payload, config);
  };
  UpdateRolesPermissions(payload, headers) {
    let config = {
      headers: headers
    }
    return http.put("/services/app/Role/UpdateRolesPermissions", payload, config);
  };

  GetCurrentUserRolesNames(headers) {
    let config = {
      headers: headers
    }
    return http.get("/services/app/UserAssignmentToZones/GetCurrentUserRoles", config);
  };
  CreateRole(payload, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/Role/Create", payload, config);
  };
}

export default new UserAssignmentDataService();

<template>
  <div>
    <gmap-map
      id="map"
      :center="staticCenter"
      :zoom="13"
      :options="options"
    >
      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="closeInfoText">
      </gmap-info-window>
      <gmap-marker v-for="daAlert in daAlerts" :key="daAlert.id" :icon="'img/fa-exclamation-triangle-red.png'" :position="daAlert.location" :zIndex="2" >
      </gmap-marker>
      <gmap-marker v-for="(da, i) in das" :key="da.id" :icon="'img/da_pin_'+da.style.color+'.png'" :position="da.location" :zIndex="1" @click="toggleInfoWindow({position: da.location, infoText: da.infoText}, i)" @mouseover="toggleInfoWindow({position: da.location, infoText: da.infoText}, i)" @mouseleave="closeInfoText">
      </gmap-marker>
      <gmap-marker v-for="(da, i) in freedas" :key="da.id" :icon="'img/da_pin_'+da.style.color+'.png'" :position="da.location" @click="toggleInfoWindow({position: da.location, infoText: da.infoText}, i)" @mouseover="toggleInfoWindow({position: da.location, infoText: da.infoText}, i)" @mouseleave="closeInfoText">
      </gmap-marker>
      <gmap-marker v-for="(sup, i) in sups" :key="sup.id" icon="img/rest_pin.svg" :position="sup.location" @click="toggleInfoWindow({position: sup.location, infoText: sup.infoText}, i)" @mouseover="toggleInfoWindow({position: sup.location, infoText: sup.infoText}, i)" @mouseleave="closeInfoText">
      </gmap-marker>
      <gmap-marker v-for="(cli, i) in clients" :key="cli.id" :icon="'img/client_pin_'+cli.style.color+'.svg'" :position="cli.location" @click="toggleInfoWindow({position: cli.location, infoText: cli.infoText}, i)" @mouseover="toggleInfoWindow({position: cli.location, infoText: cli.infoText}, i)" @mouseleave="closeInfoText">
      </gmap-marker>
      <gmap-polyline v-for="(path, i) in paths" :key="path.pos[0]" :path="path.path" v-bind:options="path.style"  @click="highlightPath(path, i)"  @mouseover="highlightPath(path, i)" @mouseleave="closeInfoText">
      </gmap-polyline>
      <gmap-circle v-for="sZone in supplierZones" :key="sZone.id" :center="{lat: sZone.latitude, lng: sZone.longitude}" :radius="sZone.radius * 1000" :options="{strokeColor: 'black',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0,
        clickable: false}"></gmap-circle>
    </gmap-map>
    Carregado às {{ this.getHoursAndSecondsFormated(this.today) }}
  </div>
</template>
<script>
  export default {
    props: {
      pos: Array,
      freeDeliveryAgents: Array,
      supplierZones: Array,
      center: Object,
      showAllPaths: Boolean
    },
    mounted(){
      this.today = new Date()
      this.formatPOs(this.pos)

      this.staticCenter = this.center

      console.log('Carreguei o Mapa')
    },
    // watch: {
    //   freeDeliveryAgents(freeDAs) {
    //     this.formatDAs(this.freeDeliveryAgents)
    //   },
    //   pos(newPos){
    //     this.formatPOs(this.pos)
    //   },
    //   center(newCenter){
    //     if(this.center[0] != this.staticCenter[0]){
    //       this.staticCenter = this.center
    //     }
    //   }
    // },
    data () {
      return {
        staticCenter: [],
        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,
        pathClicked: false,

        infoOptions: {
        content: '',
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
        options: {
          styles: [
            {'featureType': 'poi', 'stylers': [{'visibility': 'off'}]}
          ]
        },
        colors: ['0022ff','942b2b','94862b','3a7f64','8800ff','ff7700','00ffe5','22ff00','ff00ea','2b9494'],
        paths: [],
        secPaths: [],
        sups: [],
        das: [],
        freedas: [],
        daAlerts: [],
        clients: [],
        dapathscount: 0,

        delayFactor: 0,
        today: ''
      }
    },
    methods: {
      directions(start, finish, waypoints, pos, style){
        let directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: start,
            destination: finish,
            travelMode: "DRIVING",
            avoidTolls: true,
            waypoints: waypoints
          },
          (response, status) => {
            if (status == "OK") {
              var txt = `<div><h5 style='font-size: 12px;margin:0px'>POs</h5>`
              pos.forEach(po => txt = txt + '<a href="#/admin/pesquisa/'+po+'" target="_blank"> '+po+' </a>')
              txt = txt +'</div>'
              var path =
              {
                pos: pos,
                style: style,
                path:response.routes[0].overview_path,
                location: null,
                infoText: txt
              }
              var pathArr = JSON.parse(JSON.stringify(path.path))
              path.location = pathArr[Math.trunc(pathArr.length / 2)]
              this.paths.push(path)
            } else if (status == "OVER_QUERY_LIMIT") {
              // this.setDirectionsWithDelay(start, finish, waypoints, pos, style)
            } else {
                console.log("Route: " + status);
            }
          }
        );
      },
      setDirectionsWithDelay(start, finish, waypoints, pos, style){
        this.delayFactor = this.delayFactor + 1
        setTimeout(() => {
          this.directions(start, finish, waypoints, pos, style)
        }, this.delayFactor * 1000)
      },
      getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1) ) + min;
      },
      toggleInfoWindow(marker, idx) {
        this.infoWindowPos = marker.position;
        this.infoOptions.content = marker.infoText;

        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;

        }
      },
      highlightPath(path, i){
        this.pathClicked = true
        var index = this.paths.findIndex(p => p.pos[0] == path.pos[0])
        this.paths[index].style.zIndex = 1;
        this.paths[index].style.strokeWeight = 5;
        this.paths.forEach(p => {
          if(p.pos[0] != path.pos[0]){
            p.style.zIndex = 0
            p.style.strokeWeight = 1
          }
        })
        this.toggleInfoWindow({position: path.location, infoText: path.infoText}, i)
      },
      closeInfoText(){
        this.infoWinOpen = false
        if(this.pathClicked){
          this.paths.forEach(p => {
            p.style.zIndex = 0
            p.style.strokeWeight = p.style.initialStrokeWeight
          })
        }
        this.pathClicked = false
      },
      async formatPOs(pos){
        var d = []
        var c = []
        var s = []
        var p = []
        var a = []
        this.dapathscount = 0
        pos.forEach(el => {
          // var element = el
          var element = el.element
          var poPickupDate = new Date(element.deliveryTimeWindowStart)
          var showPath = true;
          if(!this.showAllPaths && element.assignment && this.freeDeliveryAgents[0].id != element.assignment.da.daId){
            showPath = false;
          }
          if(!element.isPickedup && (new Date() < new Date(poPickupDate.getTime() - 40*60000))){ //25*60000
            showPath = false;
          }
          if(showPath){
            // var color = '#'+ this.getRndInteger(100000,999999)
            var color = this.colors[(p.length - this.dapathscount) > this.colors.length? (p.length - this.dapathscount-this.colors.length) : (p.length - this.dapathscount)]

            var suptxt = '<div>'
            suptxt = suptxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/rest_pin.svg"></img>'+element.supplierName+'</h6>'
            suptxt = suptxt +'</div>'

            var sup = {
              id: element.supplierId,
              name: element.supplierName,
              phone: element.supplierPhone,
              location: {
                lat: element.pickupGpsLatitude,
                lng: element.pickupGpsLongitude
              },
              infoText: suptxt
            }
            s.push(sup)

            var DateEntregaInicial = new Date(element.deliveryTimeWindowEnd)
            var DateEntrega = new Date(DateEntregaInicial.getTime() + 20*60000);

            if(element.assignment){
              var index = p.findIndex(pa => pa.id == element.assignment.assignmentId)
              if( index > -1){
                var cor = p[index].style.color
                var clitxt = '<div>'
                clitxt = clitxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/client_pin_'+cor+'.svg"></img>'+element.deliveryCustomerName+'</h6>'
                clitxt = clitxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/rest_pin.svg"></img>'+element.supplierName+'</h6>'
                clitxt = clitxt + '<h5 style="font-size: 12px;margin:0px;width: 100%">PO <a href="#/admin/pesquisa/'+element.poNum+'" target="_blank" style="margin-right:20px"> '+element.poNum+' </a>'+String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0') + " - " + String(DateEntrega.getHours()).padStart(2, '0') + ":" + String(DateEntrega.getMinutes()).padStart(2, '0')
                if(element.deliverAsap){
                  clitxt = clitxt + '<i class="fa fa-bolt" title="Entregar o mais rápido possivel" style="color: red; font-size: 16px"></i></h5>'
                }else{
                  clitxt = clitxt +'</h5>'
                }
                clitxt = clitxt +'</div>'
                var cli = {
                  id: element.customerPhone,
                  name: element.deliveryCustomerName,
                  phone: element.customerPhone,
                  location: {
                    lat: element.deliveryGpsLatitude,
                    lng: element.deliveryGpsLongitude
                  },
                  style: {
                    color: cor
                  },
                  infoText: clitxt
                }
                c.push(cli)

              }else{
                var path = {
                  id: element.assignment.assignmentId,
                  pos: [],
                  start: '',
                  finish: '',
                  waypoints: [],
                  style: {
                    strokeColor: '#'+color,
                    strokeWeight: 5,
                    zIndex: 0,
                    initialStrokeWeight: 5,
                    color: color,
                  },
                  infoText: '<div>'
                }

                var steps = JSON.parse(element.assignment.steps)
                var DApoNums = []
                steps.forEach((step,index) => {
                  if(index > 0){
                    if(index == 1){
                      path.start = {location: step.Location}
                    } else if(index == steps.length - 1){
                      path.finish = {location: step.Location}
                    } else {
                      path.waypoints.push({location: step.Location})
                    }
                  }else{
                    if(!steps[index + 1].IsCompleted){
                      var lineSymbol = {
                        path: 'M 0,-1 0,1',
                        strokeOpacity: 1,
                        scale: 4
                      };
                      var pathDA = {
                        id: element.assignment.assignmentId + "_" + element.assignment.da.daId,
                        pos: step.PONums,
                        start: {location: {lat: element.assignment.da.locationGpsLatitude,lng: element.assignment.da.locationGpsLongitude}},
                        finish: {location: steps[index + 1].Location},
                        waypoints: [],
                        style: {
                          strokeColor: '#'+color,
                          strokeWeight: 5 ,
                          strokeOpacity: 0,
                          initialStrokeWeight: 5,
                          color: color,
                          icons: [{
                            icon: lineSymbol,
                            offset: '0',
                            repeat: '20px'
                          }]
                        }
                      }
                      p.push(pathDA)
                      this.dapathscount = this.dapathscount + 1
                    }
                    path.pos = step.PONums
                    DApoNums = step.PONums
                  }
                })
                p.push(path)



                var datxt = '<div>'
                datxt = datxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/da_pin_'+color+'.png" style="margin-right:10px;"></img>'+element.assignment.da.userName+'</h6>'
                datxt = datxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%">Atualizado às: '+this.getHoursAndSecondsFormated(element.assignment.da.locationUpdatedDate)+'</h6>'
                datxt = datxt + '<h6 style="font-size: 12px;margin-right:20px">POs</h6>'
                DApoNums.forEach(po => datxt = datxt + '<a href="#/admin/pesquisa/'+po+'" target="_blank"> '+po+' </a>')
                // datxt = datxt + '<h5 style="margin-right:20px;width: 100%">PO <a href="#/admin/pesquisa/'+element.poNum+'" target="_blank"> '+element.poNum+' </a></h5>'
                // datxt = datxt + '<h5 style="margin-right:20px;width: 100%">'+String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0') + " - " + String(DateEntrega.getHours()).padStart(2, '0') + ":" + String(DateEntrega.getMinutes()).padStart(2, '0')
                datxt = datxt +'</div>'
                var da = {
                  id: element.assignment.da.daId,
                  name: element.assignment.da.userName,
                  phone: element.assignment.da.phone,
                  location: {
                    lat: element.assignment.da.locationGpsLatitude,
                    lng: element.assignment.da.locationGpsLongitude
                  },
                  locationDate: element.assignment.da.locationUpdatedDate,
                  style: {
                    color: color
                  },
                  infoText: datxt
                }
                d.push(da)

                var daLocationDate = new Date(element.assignment.da.locationUpdatedDate)
                if(!this.isDAInTransit(steps) && (new Date() > new Date(daLocationDate.getTime() + 2*60000))){
                  var alertDA = {
                    id: element.assignment.da.daId + "alert",
                    location: {
                      lat: element.assignment.da.locationGpsLatitude,
                      lng: element.assignment.da.locationGpsLongitude
                    }
                  }
                  a.push(alertDA)
                }



                var clitxt = '<div>'
                clitxt = clitxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/client_pin_'+color+'.svg"></img>'+element.deliveryCustomerName+'</h6>'
                clitxt = clitxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/rest_pin.svg"></img>'+element.supplierName+'</h6>'
                clitxt = clitxt + '<h5 style="font-size: 12px;margin:0px;width: 100%">PO <a href="#/admin/pesquisa/'+element.poNum+'" target="_blank" style="margin-right:20px"> '+element.poNum+' </a>'+String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0') + " - " + String(DateEntrega.getHours()).padStart(2, '0') + ":" + String(DateEntrega.getMinutes()).padStart(2, '0')
                if(element.deliverAsap){
                  clitxt = clitxt + '<i class="fa fa-bolt" title="Entregar o mais rápido possivel" style="color: red; font-size: 16px"></i></h5>'
                }else{
                  clitxt = clitxt +'</h5>'
                }
                clitxt = clitxt +'</div>'
                var cli = {
                  id: element.customerPhone,
                  name: element.deliveryCustomerName,
                  phone: element.customerPhone,
                  location: {
                    lat: element.deliveryGpsLatitude,
                    lng: element.deliveryGpsLongitude
                  },
                  style: {
                    color: color
                  },
                  infoText: clitxt
                }
                c.push(cli)
              }
            }
            else
            {
              var path = {
                id: element.poNum,
                pos: [element.poNum],
                start: {location: { lat: element.pickupGpsLatitude,lng: element.pickupGpsLongitude}},
                finish: {location: {lat: element.deliveryGpsLatitude,lng: element.deliveryGpsLongitude}},
                waypoints: [],
                style: {
                  strokeColor: 'black',
                  strokeWeight: 1,
                  zIndex: 1,
                  initialStrokeWeight: 1,
                  color: 'black',
                }
              }
              p.push(path)



              var clitxt = '<div>'
              clitxt = clitxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/client_pin_27c2af.svg"></img>'+element.deliveryCustomerName+'</h6>'
              clitxt = clitxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/rest_pin.svg"></img>'+element.supplierName+'</h6>'
              clitxt = clitxt + '<h5 style="font-size: 12px;margin:0px;width: 100%">PO <a href="#/admin/pesquisa/'+element.poNum+'" target="_blank" style="margin-right:20px"> '+element.poNum+' </a>'+String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0') + " - " + String(DateEntrega.getHours()).padStart(2, '0') + ":" + String(DateEntrega.getMinutes()).padStart(2, '0')
              if(element.deliverAsap){
                clitxt = clitxt + '<i class="fa fa-bolt" title="Entregar o mais rápido possivel" style="color: red; font-size: 16px"></i></h5>'
              }else{
                clitxt = clitxt +'</h5>'
              }
              clitxt = clitxt +'</div>'

              var cli = {
                id: element.customerPhone,
                name: element.deliveryCustomerName,
                phone: element.customerPhone,
                location: {
                  lat: element.deliveryGpsLatitude,
                  lng: element.deliveryGpsLongitude
                },
                style: {
                  color: '27c2af'
                },
                infoText: clitxt
              }
              c.push(cli)
            }

          }
        })
        if(pos.length == 0){
          this.clients = []
          this.sups = []
          this.das = []
        }
        var ps = this.getDistinctArray(p)
        this.clients = this.getDistinctArray(c)
        this.sups = this.getDistinctArray(s)
        this.das = this.getDistinctArray(d)
        this.daAlerts = this.getDistinctArray(a)

        this.delayFactor = 0
        this.paths = []
        for(var i = 0; (i*8) < ps.length; i++){
          var final = (i + 1)*8
          if(ps.length < final){
            final = ps.length
          }
          for(var j = i * 8; j < final; j++){
            this.directions(ps[j].start, ps[j].finish, ps[j].waypoints, ps[j].pos, ps[j].style)
          }
          await new Promise(r => setTimeout(r, 5000));
        }
        this.formatDAs(this.freeDeliveryAgents)
        // ps.forEach(path => this.directions(path.start, path.finish, path.waypoints, path.pos, path.style))
        // this.paths = this.secPaths
        // this.secPaths = []
      },
      getDistinctArray(array){
        const arrayUniqueByKey = [...new Map(array.map(item => [item['id'], item])).values()];
        return arrayUniqueByKey;
      },
      formatDAs(das){
        var d = []
        das.forEach(element => {
          if(element.estadoState != 5 && element.estadoState != 2 && element.estadoState != 4 && element.locationUpdatedDate != ""){
            var datxt = '<div>'
            datxt = datxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/da_pin_DAA520.png" style="margin-right:10px;"></img>'+element.userName+'</h6>'
            datxt = datxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%">Atualizado às: '+element.locationUpdatedDate+'</h6>'
            datxt = datxt +'</div>'
            var da = {
              id: element.id,
              name: element.userName,
              phone: element.phone,
              location: {
                lat: element.locationGpsLatitude,
                lng: element.locationGpsLongitude
              },
              locationDate: element.locationUpdatedDate,
              infoText: datxt,
              style:{
                color: 'DAA520'
              }
            }
            d.push(da)
          }
          else if(element.estadoState == 2 || element.estadoState == 5){
            var p = this.pos.filter(p => p.element.assignmentId == element.assignments[0].assignmentId)
            if(p.length == 0){
              this.createOtherZonePath(element, element.assignments[0])
            }
          }
        })
        this.freedas = d
      },
      createOtherZonePath(da, assignment){
        var p = []
        var c = this.clients
        var s = this.sups
        var d = this.das
        var color = '2b9494'
        var steps = JSON.parse(assignment.steps)

        var path = {
          id: steps[0].PONums[0],
          pos: [],
          start: {location: steps[1].Location},
          finish: {location: steps[steps.length-1].Location},
          waypoints: [],
          style: {
            strokeColor: '#'+color,
            strokeWeight: 3,
            zIndex: 0,
            initialStrokeWeight: 3,
            color: color,
          }
        }
        p.push(path)

        var datxt = '<div>'
        datxt = datxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/da_pin_'+color+'.png" style="margin-right:10px;"></img>'+da.userName+'</h6>'
        datxt = datxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%">Atualizado às: '+da.locationUpdatedDate+'</h6>'
        datxt = datxt +'</div>'
        var deliveryAgent = {
          id: da.id,
          name: da.userName,
          phone: da.phone,
          location: {
            lat: da.locationGpsLatitude,
            lng: da.locationGpsLongitude
          },
          locationDate: da.locationUpdatedDate,
          style: {
            color: color
          },
          infoText: datxt
        }
        d.push(deliveryAgent)

        var suptxt = '<div>'
        suptxt = suptxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/rest_pin.svg"></img>Parceiro de outra Zona</h6>'
        suptxt = suptxt +'</div>'

        var sup = {
          id: 'Parceiro de outra Zona',
          name: 'Parceiro de outra Zona',
          phone: '',
          location: steps[1].Location,
          infoText: suptxt
        }
        s.push(sup)



        var clitxt = '<div>'
        clitxt = clitxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/client_pin_27c2af.svg"></img>Cliente</h6>'
        clitxt = clitxt + '<h6 style="font-size: 12px;margin-right:20px;width: 100%"><img src="img/rest_pin.svg"></img>Parceiro de outra Zona</h6>'
        clitxt = clitxt +'</div>'

        var cli = {
          id: 'Cliente',
          name: 'Cliente',
          phone: '',
          location: steps[steps.length-1].Location,
          style: {
            color: color
          },
          infoText: clitxt
        }
        c.push(cli)

        this.directions(p[0].start, p[0].finish, p[0].waypoints, p[0].pos, p[0].style)

      },

      isDAInTransit(steps){
        if(steps != null && steps != ''){
          var aux = 0;
          var lastStep = steps[0];
          steps.forEach((step,index) => {
            if(aux == 0){
              if(!step.IsCompleted){
                aux = 1;
                lastStep = step
              }
            }
          })
          if(lastStep.Type == 'GotoPickupLocation' || lastStep.Type == 'GotoDeliveryLocation'){
            return false;
          }
          else{
            return true;
          }
        }else{
          return true;
        }
      },

      getHoursAndSecondsFormated(date){
        var dt = new Date(date)
        var str = dt.getHours().toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  }) + ":" +
                  dt.getMinutes().toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  }) + ":" +
                  dt.getSeconds().toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  })
        return str
      },
    }
  }
</script>
<style>
  #map {
    max-height: calc(100vh - 123px);
    min-height: 400px;
  }
</style>

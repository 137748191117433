let exports = {};

Date.prototype.addDays = function(days) {
  var dat = new Date(this.valueOf())
  dat.setDate(dat.getDate() + days);
  return dat;
};

const getDateFormated = (date) => {
  var dt = new Date(date)
  var str = dt.getDate().toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            }) + "/" +
            (dt.getMonth()+1).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            }) + "/" + dt.getFullYear()
  return str
};

exports.getDates = (startDate, stopDate) => {
 var dateArray = new Array();
 var currentDate = startDate;
 while (currentDate <= stopDate) {
   dateArray.push(getDateFormated(currentDate))
   currentDate = currentDate.addDays(1);
 }
 return dateArray;
};

export default exports;

import http from "../http-common-RestAPI";

class PurchaseOrderDataService {
  GetAllPurchaseOrders(params, headers) {
    return http.get("/PurchaseOrder", { params }, { headers });
  }
  GetAllActivePurchaseOrdersByZone(suppliers, params, headers) {
    return http.get(`/PurchaseOrder/activeByZone?${suppliers.map((n, index) => `suppliers=${n}`).join('&')}`, { params }, { headers });
  }
  GetPurchaseOrderById(params, headers) {
    return http.get(`/PurchaseOrder/${params}`, { headers });
  }
  GetAllPurchaseOrdersByPesquisa(params, headers) {
    return http.get("/PurchaseOrder/pesquisa", { params }, { headers });
  }
  AtribuirPedido(params, headers) {
    return http.post("/PurchaseOrder/atribuirPedido", {},  { params }, { headers });
  }
  CancelarPedido(params, headers) {
    return http.post("/PurchaseOrder/cancelarPedido", {},  { params }, { headers });
  }
  RetirarPedido(params, headers) {
    return http.post("/PurchaseOrder/retirarPedido", {},  { params }, { headers });
  }
  ReAtivarPedido(params, headers) {
    return http.post("/PurchaseOrder/reAtivarPedido", {},  { params }, { headers });
  }
  ForcarFechoPedido(params, headers) {
    return http.post("/PurchaseOrder/forcarFechoPedido", {},  { params }, { headers });
  }
  Reclamacao(params, headers) {
    return http.post("/PurchaseOrder/reclamacao", {},  { params }, { headers });
  }
  AcrescentarRetirarProduto(params, headers) {
    return http.post("/PurchaseOrder/acrescentarRetirarProduto", {},  { params }, { headers });
  }
  AtivarCredito(params, headers) {
    return http.post("/PurchaseOrder/ativarCredito", {}, { params }, { headers });
  }
  AlterarHorario(params, headers) {
    return http.post("/PurchaseOrder/alterarHorario", {}, { params }, { headers });
  }
  AtribuirMaisPedidosEstafeta(params, headers) {
    return http.post("/PurchaseOrder/atribuirMaisPedidosEstafeta", {}, {params} , { headers });
  }
  CountPedidosByCliente(params, headers) {
    return http.get("/PurchaseOrder/countByCliente", { params }, { headers });
  }
  AvgOrderValue(params, headers) {
    return http.get("/PurchaseOrder/avgOrderValueByCliente", { params }, { headers });
  }
  GetPedidosByCliente(params, headers) {
    return http.get("/PurchaseOrder/getPurchaseOrdersByCliente", { params }, { headers });
  }
  ChangeObsDA(params, headers) {
    return http.post("/PurchaseOrder/changeObsDA", {}, {params} , { headers });
  }
  ChangeClientAdress(params, headers) {
    return http.post("/PurchaseOrder/changeClientAdress", {}, {params} , { headers });
  }
}

export default new PurchaseOrderDataService();

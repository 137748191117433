import http from "../http-common-oc";

class AlertDataService {
  GetAllByClient(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Alert/GetAllByClientWithDelay", config);
  };
  GetAllByPO(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Alert/GetAllByPO", config);
  };
  GetAllActiveByPO(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Alert/GetAllActiveByPO", config);
  };
  CreateAlert(alert, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/Alert/Create", alert, config);
  };
  SeeingAlert(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Alert/SeeingAlert", {}, config);
  };
  NotSeeingAlert(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Alert/NotSeeingAlert", {}, config);
  };
  CompleteAlert(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Alert/CompleteAlert", {}, config);
  };
  AddDescription(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Alert/AddDescription", {}, config);
  };
  GetAlertById(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Alert/Get", config);
  };
  GetAlertByPOMessage(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Alert/GetAlertByPOMessage", config);
  };
  GetAlertByPartnerMessage(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Alert/GetAlertByPartnerMessage", config);
  };
  DeleteAlert(params, headers) {
    let config = {
      params: params,
      headers: headers
    }
    return http.delete("/services/app/Alert/Delete", config);
  };
  UpdateAlert(payload, headers) {
    let config = {
      headers: headers
    }
    return http.put("/services/app/Alert/Update", payload, config);
  };
  EditAlert(params, headers) {
    let config = {
      params: params,
      headers: headers
    }
    return http.post("/services/app/Alert/EditAlert", {}, config);
  };
}

export default new AlertDataService();

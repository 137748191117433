<template>
  <div id="backlogin">
    <b-overlay id="overlay" :show="show" variant="transparent" rounded="lg" spinner-variant="warning">
      <div  id="login">
          <img src="img/logo.png" style="width:100%;height:100%;">
          <!-- <h1>Login</h1> -->
          Nome de utilizador
          <b-form-input
            id="username-input"
            v-model="input.username"
            required
            @keyup.enter.native="postLogin"
          ></b-form-input>
          Palavra-Passe
          <b-form-input
            id="password-input"
            v-model="input.password"
            required
            type="password"
            @keyup.enter.native="postLogin"
          ></b-form-input>
          <h5 style="color: red;font-size: 12px" v-if="this.error!=''">*{{this.error}}</h5>
          <br>
          <b-button style="width:100%;color: white; border-color: #DAA520;background-color: #DAA520" @click="postLogin()">Entrar</b-button>
      </div>
    </b-overlay>
  </div>
</template>

<script>

import AuthenticateDataService from "src/services/AuthenticateDataService"
import SessionDataService from "src/services/SessionDataService"
  export default {
      name: 'Login',

      data() {
          return {
              input: {
                  username: "",
                  password: ""
              },
              error: '',
              currentUser: '',
              show: false,

              routeTo: '/admin/pesquisa'
          }
      },
      mounted(){
        if(this.$route.query.returnUrl != undefined){
          this.routeTo = this.$route.query.returnUrl
        }
        this.getCurrentLoginInfo()
      },
      methods: {
        getCurrentLoginInfo(){
          if(localStorage.getItem('user-token') != null){
            SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
              .then(response => {
                (this.currentUser = response.data.result.user);
                if(this.currentUser != null){
                  this.$router.push(this.routeTo)
                }
              })
              .catch(error => {
                console.log(error)
              })
              .finally()
          }
        },
        postLogin(){
          if(this.input.username != "" && this.input.password != "") {
            const body = {userNameOrEmailAddress: this.input.username, password: this.input.password, rememberClient: true}
            this.show = true
            AuthenticateDataService.Login(body)
              .then(response => (
                localStorage.setItem('user-token', response.data.result.accessToken), this.getCurrentLoginInfo(),this.$emit("authenticated", true),this.$router.push(this.routeTo),this.show = false
              ))
              .catch(error => {
                this.error = "O nome de utilizador e/ou a palavra-passe estão incorretos";
                this.show = false
                this.errored = true
                localStorage.removeItem('user-token')
              })
              .finally(() => this.errored = false)
          }else {
            this.error = "Deve existir um nome de utilizador e uma palavra-passe";
          }
        },
      }
    }
</script>

<style scoped>
    #login {
        width: 35%;
        margin: auto;
        margin-top: 10%;
        padding: 20px;
        color: white;
    }
    #backlogin {
        background-image: url("../../public/img/header.png");
        background-color: black;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        z-index: 3;box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
    }

</style>

<template>
  <div>
    <div v-if="!editQuantityClicked">
      <strong style="font-size: 12px;">[{{this.timeslots}}]</strong><i class="fa fa-edit edit" title="Editar" style="font-size:10px" v-if="hasPermissions('Pages.Zones.EditarTimeSlots')" @click="editQuantity()"></i>
    </div>
    <div v-if="editQuantityClicked">
      <input
        id="tsquantity-input"
        v-model="tsquantity"
        min="0"
        type="number"
        style="text-align: right; width: 30px;font-size:10px"
      >
      <i class="nc-icon nc-check-2 checkIcon" title="Confirmar" style="font-weight: bold;margin:5px" @click="postChangeQuantityByZoneAndShift(zoneId, tsquantity, shift)"></i>
      <i class="nc-icon nc-simple-remove removeIcon" title="Cancelar" style="font-weight: bold;margin:5px" @click="cancelEditQuantity()"></i>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import UserDataService from "src/services/UserDataService"
import TimeSlotDataService from "src/services/TimeSlotDataService"
dayjs.extend(weekday);
export default {
  name: "TimeSlotEdit",

  props: {
    zoneId: {
      type: Number,
      required: true
    },
    shift: {
      type: Number,
      default: true
    },
    day: {
      type: Object,
      required: true
    },
  },
  mounted () {
    this.getCurrentUserPermissions()
    this.getTimeSlotsByZoneAndShift(this.zoneId, this.shift)
  },
  data() {
    return {
      currentUserPermissions: [],
      timeslots: 0,
      tsquantity: 0,
      editQuantityClicked: false
    }
  },

  methods:{
    getWeekday(date) {
      return dayjs(date).weekday();
    },
    getTimeSlotsByZoneAndShift(zoneId, shift){
      let weekday = this.getWeekday(this.day.date)
      TimeSlotDataService.GetQuantityByZone({zoneId: zoneId, shift: shift, dayOfWeek: weekday}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => this.timeslots=response.data.result)
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    editQuantity(){
      this.editQuantityClicked = true
      this.tsquantity = this.timeslots
    },
    cancelEditQuantity(){
      this.editQuantityClicked = false
      this.tsquantity = 0
    },
    postChangeQuantityByZoneAndShift(zoneId, quantity, shift){
      let weekday = this.getWeekday(this.day.date)
      TimeSlotDataService.ChangeQuantityByZone({zoneId: zoneId, quantity: quantity, shift: shift, dayOfWeek: weekday}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.timeslots=(response.data.result!=null?response.data.result.quantity: 0)), this.cancelEditQuantity())
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },

    getCurrentUserPermissions(){
      UserDataService.GetCurrentUserPermissions({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.currentUserPermissions = response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    hasPermissions(element){
      if(this.currentUserPermissions.includes(element)){
        return true;
      }else{
        return false;
      }
    },
  }
};
</script>

<style scoped>
  .edit {
    color: #DAA520;
    font-size: 1.2em;
  }

  .edit:hover {
    color: black;
    cursor: pointer;
  }

  .checkIcon{
    color:lightgreen;
    cursor: pointer;
    font-size: 1.2em;
  }

  .checkIcon:hover{
    color:black;
  }

  .removeIcon{
    color:gray;
    cursor: pointer;
    font-size: 1.2em;
  }

  .removeIcon:hover{
    color:black;
  }
</style>

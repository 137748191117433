<template>
  <li
    class="calendar-day"
    :class="{
      'calendar-day--not-current': !isCurrentMonth,
      'calendar-day--today': isToday
    }"
    :style="'min-height:'+ height +'px;'"
  >
    <p style="font-size: 20px; text-align: center;font-weight: bold"><span>{{label}}</span></p>
    <div style="height: 45%;border-top: 1px solid #DAA520;">
      <div class="row">
        <div class="col-sm-4" style="padding:0; text-align: right">
          <p style="font-size: 12px;padding: 5px">Almoço</p>
        </div>
        <div class="col" style="padding:0;">
          <TimeSlotEdit :key="zoneId + 1" :zoneId="zoneId" :shift="1" :day="day" v-if="zoneId != null"></TimeSlotEdit>
        </div>
        <div class="col-sm-2">
          <i class="nc-icon nc-simple-add addChangeIcon" @click="openChangeModal(day,1)" v-if="withReinforceButton && todayValidation(day, 1)"></i>
        </div>
      </div>
      <div v-for="event in events? events.filter(e => e.shift == 1) : events" :key="event.id" :class="event.className">{{event.title}}<i class="nc-icon nc-simple-remove removeEventIcon" @click="removeEventClicked(event, day, 1)" v-if="todayValidation(day, 1) && withReinforceButton"></i></div>
    </div>
    <div style="height: 50%;border-top: 1px solid #DAA520;">
      <div class="row">
        <div class="col-sm-4" style="padding:0; text-align: right">
          <p style="font-size: 12px;padding: 5px">Jantar</p>
        </div>
        <div class="col" style="padding:0;">
          <TimeSlotEdit :key="zoneId + 2" :zoneId="zoneId" :shift="2" :day="day" v-if="zoneId != null"></TimeSlotEdit>
        </div>
        <div class="col-sm-2">
          <i class="nc-icon nc-simple-add addChangeIcon" @click="openChangeModal(day,2)" v-if="withReinforceButton && todayValidation(day, 2)"></i>
        </div>
      </div>
      <div v-for="event in events? events.filter(e => e.shift == 2) : events" :key="event.id" :class="event.className">{{event.title}}<i class="nc-icon nc-simple-remove removeEventIcon" @click="removeEventClicked(event, day, 2)" v-if="todayValidation(day, 2) && withReinforceButton"></i></div>
    </div>
  </li>
</template>
<script>
import dayjs from "dayjs";
import TimeSlotEdit from '../global/TimeSlotEdit.vue';

export default {
  components: { TimeSlotEdit },
  name: "CalendarWeekDayItem",

  props: {
    zoneId: {
      type: Number,
      required: false,
    },

    day: {
      type: Object,
      required: true
    },

    isCurrentMonth: {
      type: Boolean,
      default: false
    },

    isToday: {
      type: Boolean,
      default: false
    },

    events: {
      type: Array,
      required: false
    },

    withReinforceButton: {
      type: Boolean,
      default: true
    },

    height: {
      type: Number,
      default: 500
    },
  },

  computed: {
    label() {
      return dayjs(this.day.date).format("D");
    }
  },
  methods:{
    removeEventClicked(event, day, shift){
      var item = {
        event: event,
        day: day.date,
        shift: shift
      }
      this.$emit("removeEvent", item);
    },
    openChangeModal(day, shift) {
      var item = {
        day: day.date,
        shift: shift
      }
      this.$emit("changeSchedule", item);
    },
    todayValidation(day, shift){
      var today = dayjs()
      var date = dayjs(day.date)
      if(date.format("YYYY-MM-DD") === today.format("YYYY-MM-DD")){
        if(today.hour() > 14 && shift == 1){
          return false;
        }
        if(today.hour() > 14 && shift == 2){
          return true;
        }
        if(today.hour() <= 14){
          return true;
        }
      }
      return date >= today
    }
  }
};
</script>

<style scoped>
.calendar-day {
  position: relative;
  font-size: 16px;
  background-color: #fff;
  color: var(--grey-800);
  padding: 5px;
  border: 1px solid #dfdfdf;
}

.calendar-day > span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2px;
  width: var(--day-label-size);
  height: var(--day-label-size);
}

.calendar-day--not-current {
  background-color: var(--grey-100);
  color: var(--grey-300);
}

.calendar-day--today {
  padding-top: 4px;
  background-color: #ffea0039;
}

/* .calendar-day--today > span {
  color: #fff;
  border-radius: 9999px;
  background-color: #DAA520;
  border: 1px solid black;
} */

.addChangeIcon{
  color: black;
  font-size: 1em;
  float:right;
  font-weight:bold

}
.addChangeIcon:hover{
  color: #DAA520;

  cursor: pointer;
}
.removeEventIcon{
  float:right;
  margin-right:5px;
  margin-top:2px;
  font-weight: bold;
}
.removeEventIcon:hover{
  color: black;

  cursor: pointer;
}

.eventsummoned {background-color: rgba(203, 241, 167, 0.9);border: 1px solid rgb(117, 182, 96);color: rgb(0, 0, 0);border-radius: 15px;margin: 1px;font-size: 14px;text-align: center;}
.eventunavailable {background-color: rgba(249, 158, 158, 0.9);border: 1px solid rgb(235, 82, 82);color: rgb(0, 0, 0);border-radius: 15px;margin: 1px;font-size: 14px;text-align: center;}
.eventavailable {background-color: rgba(253, 156, 66, 0.9);border: 1px solid rgb(233, 136, 46);color: rgb(0, 0, 0);border-radius: 15px;margin: 1px;font-size: 14x;text-align: center;}
.eventreinforced {background-color: rgba(144, 214, 233, 0.9);border: 1px solid rgb(56, 155, 222);color: rgb(0, 0, 0);border-radius: 15px;margin: 1px;font-size: 14px;text-align: center;}
</style>

import http from "../http-common-Core";

class PurchaseOrdersCoreDataService {
  GetIsFirstOrder(params) {
    return http.get("/PurchaseOrder/GetIsFirstOrder", {params});
  }
  GetIsVoucher(params) {
    return http.get("/PurchaseOrder/GetIsVoucher", {params});
  }
}

export default new PurchaseOrdersCoreDataService();


import http from "../http-common-oc";

class PurchaseOrdersOCDataService {
  GetAllPOsByPesquisaByUser(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetPOsBySearch", config);
  };
  GetAllActivePOsByUser(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetActivePOs", config);
  };
  GetActivePOsByZone(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetActivePOsByZone", config);
  };
  ActivePOsByZones(payload,params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/UserAssignmentToZones/ActivePOsByZones", payload, config);
  };

  GetActiveSuppliers(headers) {
    let config = {
      headers: headers
    }
    return http.get("/services/app/UserAssignmentToZones/GetActiveSuppliersByCurrentUser", config);
  };
  GetPedidosByCliente(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetPurchaseOrdersByClient", config);
  }
  GetPedidosByDA(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetPurchaseOrdersByDA", config);
  }
  GetAssignmentsByDA(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetAssignmentsByDA", config);
  }
  GetAllAssignmentsByDA(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetAllAssignmentsByDA", config);
  }
  GetOrdersClientAddressInfo(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAcao/GetOrdersClientAddressInfo", config);
  }
  GetDAPerformancesByZone(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetDAPerformancesByZone", config);
  }
  GetDATotalPayedByZone(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetDATotalPayedByZone", config);
  }
  GetCurrentUserSupplierZones(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetCurrentUserSupplierZones", config);
  }
}

export default new PurchaseOrdersOCDataService();

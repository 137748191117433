import { render, staticRenderFns } from "./VoucherIcon.vue?vue&type=template&id=17b7cfe7&scoped=true&"
import script from "./VoucherIcon.vue?vue&type=script&lang=js&"
export * from "./VoucherIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17b7cfe7",
  null
  
)

export default component.exports
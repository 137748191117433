import http from "../http-common-oc";

class ZonaEstafetaDataService {
  GetAllZonaEstafeta(headers) {
    return http.get("/services/app/DeliveryAgentZone/GetAll", headers);
  }
  CreateZonaEstafeta(zona, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/DeliveryAgentZone/Create", zona, config);
  }
  UpdateZonaEstafeta(zona, headers) {
    let config = {
      headers: headers
    }
    return http.put("/services/app/DeliveryAgentZone/Update", zona, config);
  }
  DeleteZonaEstafeta(params, headers) {
    let config = {
      params: params,
      headers: headers
    }
    return http.delete("/services/app/DeliveryAgentZone/Delete", config);
  }
}

export default new ZonaEstafetaDataService();

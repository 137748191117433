<template>
  <div class="wrapper">
    <side-bar>
      <!-- <mobile-menu slot="content"></mobile-menu> -->
      <!-- <sidebar-link to="/admin/overview">
        <i class="nc-icon nc-chart-pie-35"></i>
        <p>Dashboard</p>
      </sidebar-link> -->
      <sidebar-link to="/admin/pesquisa" v-if="hasPermissions('Pages.POs')">
        <i class="nc-icon nc-zoom-split"></i>
        <p>Pesquisa</p>
      </sidebar-link>
      <sidebar-link to="/admin/pedidosAtivos" v-if="hasPermissions('Pages.POs')">
        <i class="nc-icon nc-single-copy-04"></i>
        <p>Pedidos Ativos</p>
      </sidebar-link>
      <base-dropdown title="Estafetas" icon="nc-icon nc-delivery-fast" @click.native="showDA = !showDA" v-if="hasPermissions('Pages.DAs')"></base-dropdown>
      <div style="margin-left: 15px" v-if="showDA">
        <sidebar-link to="/admin/estafetas" v-if="hasPermissions('Pages.DAs')">
          <i class="nc-icon nc-circle-09"></i>
          <p>Estafetas</p>
        </sidebar-link>
        <sidebar-link to="/admin/turnos" v-if="hasPermissions('Pages.DAs.Schedule')">
          <i class="fa fa-calendar"></i>
          <p>Turnos</p>
        </sidebar-link>
        <sidebar-link to="/admin/zonas" v-if="hasPermissions('Pages.Zones')">
          <i class="nc-icon nc-square-pin"></i>
          <p>Zonas</p>
        </sidebar-link>
        <sidebar-link to="/admin/daPerformance" v-if="hasPermissions('Pages.DAs.Performance')">
          <i class="fa fa-user-plus"></i>
          <p>Performance</p>
        </sidebar-link>
        <base-dropdown title="Candidatos" icon="nc-icon nc-paper-2" @click.native="showCandidate = !showCandidate" v-if="hasPermissions('Pages.Candidates')"></base-dropdown>
        <div style="margin-left: 15px" v-if="showCandidate">
          <sidebar-link to="/admin/candidates" v-if="hasPermissions('Pages.DAs')">
            <i class="nc-icon nc-circle-09"></i>
            <p>Candidatos</p>
          </sidebar-link>
          <sidebar-link to="/admin/newCandidate" v-if="hasPermissions('Pages.Candidates.NewCandidate')">
            <i class="nc-icon nc-simple-add"></i>
            <p>Novo Candidato</p>
          </sidebar-link>
        </div>
      </div>
      <base-dropdown title="Administração" icon="nc-icon nc-settings-gear-64" @click.native="showAdmin = !showAdmin" v-if="hasPermissions('Pages.Actions')"></base-dropdown>
      <div style="margin-left: 15px" v-if="showAdmin">
        <sidebar-link to="/admin/acoes" v-if="hasPermissions('Pages.Actions')">
          <i class="nc-icon nc-grid-45"></i>
          <p>Ações</p>
        </sidebar-link>
        <sidebar-link to="/admin/candidatesManagementDocs" v-if="hasPermissions('Pages.Candidates.ManagementDocs')">
          <i class="nc-icon nc-single-copy-04"></i>
          <p>Documentos</p>
        </sidebar-link>
        <sidebar-link to="/admin/adminUsers" v-if="hasPermissions('Pages.Actions')">
          <i class="nc-icon nc-single-02"></i>
          <p>Utilizadores</p>
        </sidebar-link>
        <!-- <sidebar-link to="/admin/config" v-if="hasPermissions('Pages.Actions')">
          <i class="nc-icon nc-settings-gear-64"></i>
          <p>Configurações</p>
        </sidebar-link> -->
      </div>
      <!-- <sidebar-link to="/admin/potest" v-if="hasPermissions('Pages.Actions')">
        <i class="nc-icon nc-single-copy-04"></i>
        <p>POs Ativos OldVersion</p>
      </sidebar-link> -->

      <!-- <sidebar-link to="/admin/chat">
        <i class="nc-icon nc-grid-45"></i>
        <p>Chat</p>
      </sidebar-link> -->
      <!-- <sidebar-link to="/admin/assignment">
        <i class="nc-icon nc-bullet-list-67"></i>
        <p>Assignment</p>
      </sidebar-link> -->
      <!-- <sidebar-link to="/admin/icons">
        <i class="nc-icon nc-atom"></i>
        <p>Icons</p>
      </sidebar-link> -->
      <!-- <sidebar-link to="/admin/user">
        <i class="nc-icon nc-circle-09"></i>
        <p>User Profile</p>
      </sidebar-link>
      <sidebar-link to="/admin/table-list">
        <i class="nc-icon nc-notes"></i>
        <p>Table list</p>
      </sidebar-link>
      <sidebar-link to="/admin/typography">
        <i class="nc-icon nc-paper-2"></i>
        <p>Typography</p>
      </sidebar-link>
      <sidebar-link to="/admin/icons">
        <i class="nc-icon nc-atom"></i>
        <p>Icons</p>
      </sidebar-link>
      <sidebar-link to="/admin/maps">
        <i class="nc-icon nc-pin-3"></i>
        <p>Maps</p>
      </sidebar-link>
      <sidebar-link to="/admin/notifications">
        <i class="nc-icon nc-bell-55"></i>
        <p>Notifications</p>
      </sidebar-link>

      <template slot="bottom-links">
        <sidebar-link class="active"
                      to="/admin/temaspendentes">
          <i class="nc-icon nc-alien-33"></i>
          <p>Temas Pendentes</p>
        </sidebar-link>
      </template>-->
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './MobileMenu.vue'
  import UserDataService from "src/services/UserDataService"
  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu
    },

    data() {
        return {
          currentUserPermissions: ['Pages.POs', 'Pages.DAs','Pages.Actions', 'Pages.DAs.Schedule', 'Pages.Zones', 'Pages.DAs.Performance'],
          showAdmin: false,
          showDA: false,
          showCandidate: false
        }
    },
    mounted(){
      this.getCurrentUserPermissions()
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },

      getCurrentUserPermissions(){
        UserDataService.GetCurrentUserPermissions({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
          .then(response => (this.currentUserPermissions = response.data.result))
          .catch(error => {
            console.log(error)
          })
          .finally()
      },
      hasPermissions(element){
        if(this.currentUserPermissions.includes(element)){
          return true;
        }else{
          return false;
        }
      },
    }
  }

</script>

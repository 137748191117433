import http from "../http-common-oc";

class CallDataService {
  ExistsCallByPoNum(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Call/ExistsCallByPoNum", {},  config);
  }
  SendAutoCallToPartner(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Call/SendAutoCallToPartner", {},  config);
  }
  SendAutoCallToPartnerPhone(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Call/SendAutoCallToPartnerPhone", {},  config);
  }
  SendAutoCallToDA(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Call/SendAutoCallToDA", {},  config);
  }
  SendAutoCallToDACheckIn(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Call/SendAutoCallToDACheckIn", {},  config);
  }
}

export default new CallDataService();

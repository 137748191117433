import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
export default {
  install (Vue) {
    const poHub = new Vue()
    Vue.prototype.$poHub = poHub


    let connection = null
    let startedPromise = null
    let manuallyClosed = false

    Vue.prototype.startPOSignalR = () => {
      connection = new HubConnectionBuilder().withUrl(process.env.VUE_APP_API_URL+"pochathub/").configureLogging(LogLevel.Information).build();

      connection.on('ReceivePOMessage', () => {
        poHub.$emit('ReceivePOMessage')
      })

      connection.on('ReceivePOStateMessage', () => {
        poHub.$emit('ReceivePOStateMessage')
      })

      function start () {
        startedPromise = connection.start()
          .catch(err => {
            console.error('Failed to connect with hub', err)
            return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000))
          })
        return startedPromise
      }
      connection.onclose(() => {
        if (!manuallyClosed) start()
      })

      manuallyClosed = false
      start()
    }

    Vue.prototype.stopPOSignalR = () => {
      if (!startedPromise) return

      manuallyClosed = true
      return startedPromise
        .then(() => connection.stop())
        .then(() => { startedPromise = null })
    }

    Vue.prototype.startPOSignalR()

    poHub.joinPOChat = (poNum) => {
      if (!startedPromise) return

      return startedPromise
        .then(() => connection.invoke('JoinPOChat', poNum))
        .catch(console.error)
    }
    poHub.leavePOChat = (poNum) => {
      if (!startedPromise) return

      return startedPromise
        .then(() => connection.invoke('LeavePOChat', poNum))
        .catch(console.error)
    }
  }
}

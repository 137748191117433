import http from "../http-common-oc";

class CandidateManagementDocsDataService {
  GetFileByDocType(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/CandidateManagementDoc/GetFileByDocType", config);
  };
  GetFilesGroupedByDocType(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/CandidateManagementDoc/GetFilesGroupedByDocType", config);
  };
  UploadFiles(payload, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/CandidateManagementDoc/UploadFiles", payload, config);
  };
  DownloadFile(params, headers) {
    let config = {
      headers: headers,
      params: params,
      responseType: 'arraybuffer'
    }
    return http.post("/services/app/CandidateManagementDoc/DownloadFile", {}, config);
  };
  GetContractFileContent(params, headers) {
    let config = {
      headers: headers,
      params: params,
      responseType: 'arraybuffer'
    }
    return http.post("/services/app/CandidateManagementDoc/ContractFileContent", {}, config);
  };

}

export default new CandidateManagementDocsDataService();

import http from "../http-common-oc";

class TimeSlotDataService {
  GetQuantityByZone(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/TimeSlot/GetQuantityByZone", config);
  }
  ChangeQuantityByZone(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/TimeSlot/ChangeQuantityByZone", {}, config);
  }
}

export default new TimeSlotDataService();

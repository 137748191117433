<template>
<div style="background-color: gray;">
  <CandidatesForm :key="token" :token="token" :isFormPage="true" class="form" v-if="token != ''"></CandidatesForm>
</div>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import CandidatesForm from "src/pages/Candidates/CandidatesForm.vue"
export default {
  components: {
    VuePdfEmbed,
    CandidatesForm
  },
  name: "CandidatesFormPage",

  mounted () {
    if(this.$route.params.token != undefined){
      this.token = this.$route.params.token
    }
    else{
      this.$router.push({ path: '/candidates/form/formNotFound', query: { returnUrl: this.$route.path } })
    }
  },
  beforeRouteUpdate(to, from) {
    if(to.params.token != undefined){
      this.token = to.params.token
    }
    else{
      this.$router.push({ path: '/candidates/form/formNotFound', query: { returnUrl: this.$route.path } })
    }
  },

  data() {
    return {
      token: ''
    }
  },

  methods:{

  }
};
</script>

<style scoped>
.form{
  width: 70%;
  margin:auto;
}
@media (max-width: 768px) {
    .form {
      width:100%;
    }
}
</style>

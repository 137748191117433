<script>
  import { Line } from 'vue-chartjs'
  export default {
    extends: Line,
    props:{
      labels: {
        type: Array,
        required: true,
        default: []
      },
      datasets: {
        type: Array,
        required: true,
        default: []
      }
    },
    data () {
      return {
        chartData: {
          labels: this.labels,
          datasets: this.datasets
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted () {
      this.renderChart(this.chartData, this.options)
    },
  }
</script>

<template>
  <div v-if="poNum != '' && hasPermissions('Pages.POs')" style="margin: auto;width: 90%;">
    <div>
      <b-row style="margin: 0px; ">
        <hr style="margin-bottom: 0px;height:2px; width: 100%;border-width:0;color:#DAA520;background-color:#DAA520">
        <div class="edit" style="text-align:center; font-size:10px; width: 100%" v-if="hideObservations" @click="changeHideObservations">
          <i class="nc-icon nc-stre-down" style="padding:0px;margin:0px"></i>
          <p style="font-size:10px; padding:0px;margin:0px">Ver Observações</p>
        </div>
      </b-row>
      <b-card no-body v-if="!hideObservations">
        <div class="edit" style="text-align:center; font-size:10px; width: 100%" v-if="!hideObservations" @click="changeHideObservations">
          <i class="nc-icon nc-stre-up" style="padding:0px;margin:0px;"></i>
          <p style="font-size:10px; padding:0px;margin:0px">Esconder Observações</p>
        </div>
        <div>
          <b-overlay id="overlay2" :show="showChat" variant="transparent" rounded="lg" spinner-variant="warning" style="margin:20px" v-if="showChat">
          </b-overlay>
          <b-card-text v-if="items == ''" style="text-align:center">
          </b-card-text>
          <b-card-text v-if="items != ''" style="max-height: 400px; overflow:auto; display:flex; flex-direction:column-reverse;">
            <div>
              <div v-for="item in items" :key="item.id">
                <div class="talk-bubble-right-d tri-right-d btm-right" style="float: right">
                  <div class="talktext">
                    <p style="font-size:14px;font-weight: bold;">{{item.observationType}}</p>
                    <p style="font-size:14px">{{item.observation}}</p>
                    <p style="float:right; font-size:10px">{{item.createdBy.userName}} {{getDateWithHoursFormated(item.creationDate)}}</p>
                  </div>
                </div>
              </div>
            </div>
          </b-card-text>
          <hr style="height:2px; width: 100%;border-width:0;color:gray;background-color:gray">
          <b-row style="margin-right: 0px">
            <b-col lg="10">
              <b-row style="margin: 0px">
                <b-form-select v-model="newObservationType" style="cursor: pointer">
                  <b-form-select-option value="">Escolha o motivo</b-form-select-option>
                  <b-form-select-option value="Tentativa de Contacto">Tentativa de Contacto</b-form-select-option>
                  <b-form-select-option value="Outro">Outro</b-form-select-option>
                </b-form-select>
             </b-row>
              <b-form-textarea
                id="newObservation-input"
                v-model="newObservation"
              ></b-form-textarea>
            </b-col>
            <b-col lg="2">
              <b-button variant="success" style=" height: 100%; width:100%;padding:0" :disabled="newObservation == '' || newObservationType == ''" @click="sendNewPOObservation(newObservationType, newObservation, poNum)">ENVIAR</b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>

    <b-modal :id="newObeservationModal.id" :title="newObeservationModal.title" :ok-disabled="newObservation == ''" ok-variant="success" ok-title="Enviar" cancel-title="Fechar" @hide="resetNewObeservationModal" @ok="postPayOffDebt(closeDebt.content, description)">
      <b-row style="margin: auto;width: 100%;">
        <b-form-input
          id="description-input"
          v-model="newObservation"
          type="text"
        ></b-form-input>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import StaffPOObservationsDataService from 'src/services/StaffPOObservationsDataService';
import UserDataService from "src/services/UserDataService"
import SessionDataService from "src/services/SessionDataService"
export default {
  name: "StaffPOObservations",

  props: {
    poNum: {
      type: Number,
      required: true
    }
  },
  mounted () {
    this.getStaffPOObservations(this.poNum)
    this.getCurrentLoginInfo()
  },
  watch: {
    poNum(newVal, oldVal) {
      this.getStaffPOObservations(this.poNum)
    },
  },
  data() {
    return {
      items: [],

      newObeservationModal: {
        id: 'new-observation-modal',
        title: '',
        content: ''
      },
      newObservation: '',
      newObservationType: '',
      hideObservations: false,

      currentUserPermissions: [],
      currentUser: {}
    }
  },

  methods:{
    getStaffPOObservations(poNum){
      StaffPOObservationsDataService.GetAllByPoNum({poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.items = response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    newObeservationInfo(item, index, button) {
      this.newObeservationModal.title = 'Nova Observação'
      this.newObeservationModal.content = item
      this.$root.$emit('bv::show::modal', this.newObeservationModal.id, button)
    },
    resetNewObeservationModal() {
      this.newObeservationModal.title = ''
      this.newObeservationModal.content = ''
      this.newObservation = ''
      this.newObservationType = ''
    },

    sendNewPOObservation(newObservationType, newObservation, poNum){
      var newObservation = {
        creationDate: new Date(),
        poNum: poNum,
        observationType: newObservationType,
        observation: newObservation,
        createdById: this.currentUser.id
      };
      StaffPOObservationsDataService.CreateNewPOObservation(newObservation, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Nova observação criada com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.newObservation = '', this.newObservationType = '', this.getStaffPOObservations(this.poNum)
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },

    changeHideObservations(){
      this.hideObservations = !this.hideObservations
    },

    getCurrentLoginInfo(){
      SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => {
          (this.currentUser = response.data.result.user);
          if(this.currentUser == null){
            this.$router.push({ path: '/login', query: { returnUrl: this.$route.path } })
          }else{
            // this.getCurrentUserRoles()
            this.getCurrentUserPermissions()
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getCurrentUserPermissions(){
      UserDataService.GetCurrentUserPermissions({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.currentUserPermissions = response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    hasPermissions(element){
      if(this.currentUserPermissions.includes(element)){
        return true;
      }else{
        return false;
      }
    },

    getDateWithHoursFormated(date){
      var dt = new Date(date)
      var str = dt.getDate().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + "/" +
                (dt.getMonth()+1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + "/" + dt.getFullYear() + " " +
                dt.getHours().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + ":" +
                dt.getMinutes().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                })
      return str
    },
  }
};
</script>

<style scoped>
  .talk-bubble-right-d {
    margin: 2px;
    display: inline-block;
    position: relative;
    width: 95%;
    height: auto;
    background-color: lightgrey;
    border-radius: 5px;
  }
  .tri-right-d.btm-right:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 0px;
    bottom: -20px;
    border: 12px solid;
    border-color: lightgrey lightgrey transparent transparent;
  }
</style>

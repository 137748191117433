import http from "../http-common-oc";

class LogMessageDataService {
  GetAllByPO(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogMessage/GetByPO", config);
  }
  GetAllGeneralBySupplier(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogMessage/GetGeneralBySupplier", config);
  }
  SendGeneralMessageToPartner(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogMessage/CreateGeneralMessageToPartner", {}, config);
  }
  SendPurchaseOrderMessageToPartner(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogMessage/CreatePurchaseOrderMessageToPartner", {}, config);
  }
  MarkPOMessagesAsRead(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogMessage/MarkPOMessagesAsRead", {}, config);
  }
  MarkGeneralMessagesAsRead(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogMessage/MarkGeneralMessagesAsRead", {}, config);
  }
  SendGeneralMessageToAllPartners(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogMessage/SendGeneralMessageToAllPartners", {}, config);
  }
  SendASAPMessageToPartner(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogMessage/SendASAPMessageToPartner", {}, config);
  }
}

export default new LogMessageDataService();

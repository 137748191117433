import axios from "axios";

export default axios.create({
  // baseURL: "http://localhost:5000/api", //local
  baseURL: process.env.VUE_APP_REST_API_URL,
  headers: {
    "Content-type": "application/json",
    'Authorization': 'Basic dGVzdDp0ZXN0'
  }
});

import http from "../http-common-oc";

class StaffPOObservationsDataService {
  GetAllByPoNum(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/StaffPOObservation/GetAllByPoNum", config);
  }
  CreateNewPOObservation(newObservation, headers) {
    return http.post("/services/app/StaffPOObservation/Create", newObservation, { headers });
  };
}

export default new StaffPOObservationsDataService();

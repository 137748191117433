<template>
  <ol class="day-of-week">
    <li
      v-for="weekday in weekdays"
      :key="weekday"
      :class="(weekday.toLowerCase() == today.format('ddd').toLowerCase())"
    >
      {{ weekday }}
    </li>
  </ol>
</template>
<script>
const WEEKDAYS = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"];
import dayjs from "dayjs";

export default {
  name: 'CalendarWeekdays',

  props: {
    selectedDate: {
      type: Object,
      required: true
    }
  },

  computed: {
    weekdays() {
      return WEEKDAYS
    }
  },
  data() {
    return {
      today: dayjs()
    };
  },
}
</script>

<style scoped>
.day-of-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 0 !important;
  font-weight: bold;
}

.day-of-week > * {
  text-align: center;
  padding-right: 5px;
}

.day-today {
  background-color: #ffea0039;
}
</style>

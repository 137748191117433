import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
export default {
  install (Vue) {
    const partnerHub = new Vue()
    Vue.prototype.$partnerHub = partnerHub


    let connection = null
    let startedPromise = null
    let manuallyClosed = false

    Vue.prototype.startPartnerSignalR = () => {
      connection = new HubConnectionBuilder().withUrl(process.env.VUE_APP_API_URL+"partnerchathub/").configureLogging(LogLevel.Information).build();

      connection.on('ReceiveGeneralMessage', () => {
        partnerHub.$emit('ReceiveGeneralMessage')
      })

      function start () {
        startedPromise = connection.start()
          .catch(err => {
            console.error('Failed to connect with hub', err)
            return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000))
          })
        return startedPromise
      }
      connection.onclose(() => {
        if (!manuallyClosed) start()
      })

      manuallyClosed = false
      start()
    }

    Vue.prototype.stopPartnerSignalR = () => {
      if (!startedPromise) return

      manuallyClosed = true
      return startedPromise
        .then(() => connection.stop())
        .then(() => { startedPromise = null })
    }

    Vue.prototype.startPartnerSignalR()

    partnerHub.joinSupplierChat = (poNum) => {
      if (!startedPromise) return

      return startedPromise
        .then(() => connection.invoke('JoinSupplierChat', poNum))
        .catch(console.error)
    }
    partnerHub.leaveSupplierChat = (poNum) => {
      if (!startedPromise) return

      return startedPromise
        .then(() => connection.invoke('LeaveSupplierChat', poNum))
        .catch(console.error)
    }
  }
}

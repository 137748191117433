<template>
  <div class="contact-us full-screen">
    <nav class="navbar navbar-ct-default" role="navigation-demo">
      <div class="container">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <img src="img/logo.png" style="width:100%;height:100%;">
          <!-- <h2 style="color: #DAA520">COMERMELHOR</h2> -->
        </div>
      </div>
    </nav>
    <div class="wrapper wrapper-full-page section content">
      <div class="">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-md-offset-2 text-center">
              <i class="nc-icon nc-check-2 text-success" style="padding: 20px;border-radius: 100px;border: 5px solid;font-size: 150px;"></i>
              <h2 class="title text-success">Presença Confirmada</h2>
              <h6>Está confirmada a sua presença no dia e local mencionado no email. Para qualquer situação, entre em contacto</h6>
              <!-- <h2 class="title">O formulário foi submetido com sucesso.</h2> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer-demo">
      <div class="container">
        <div class="copyright text-center">
        &copy; {{new Date().getFullYear()}} ComerMelhor. All Rights Reserved. Powered By
        <a href="https://www.inovpower.pt/" target="_blank" class="inovpower">InovPower</a>.
      </div>
      </div>
    </footer>
  </div>
</template>

<script>
  export default {}

</script>

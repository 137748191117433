<template>
  <div v-if="poNum != '' && isVoucher == true">
    <i class="fa fa-percent" style="color: white; border-radius: 50%;padding:5px;font-size: 8px;background-color: #DAA520;" title="C/ Voucher"></i>
  </div>
</template>
<script>
import PurchaseOrderCoreDataService from "src/services/PurchaseOrderCoreDataService"
export default {
  name: "VoucherIcon",

  props: {
    poNum: {
      type: Number,
      required: true
    },
  },
  mounted () {
    this.getIsVoucher(this.poNum)
  },
  watch: {
    poNum(newVal, oldVal) {
      this.isVoucher = false
      this.getIsVoucher(this.poNum)
    },
  },
  data() {
    return {
      isVoucher: false
    }
  },

  methods:{
    getIsVoucher(poNum){
      PurchaseOrderCoreDataService.GetIsVoucher({poNum: poNum})
        .then(response => this.isVoucher = response.data)
        .catch(error => {
          console.log(error)
        })
        .finally()
    }
  }
};
</script>

<style scoped>
</style>

import axios from "axios";

export default axios.create({
  // baseURL: "http://localhost:21021/api/", //local
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-type": "application/json",
    Authorization: 'Bearer ' + localStorage.getItem('user-token')
  }
});

<template>
  <b-row style="margin:5px">
    <b-col lg="6">
    </b-col>
    <b-col lg="2">
      <b-row>
        <b-col lg="3">
          <div class="eventsummoned"></div>
        </b-col>
        <b-col lg="9">
          <h5>Convocado</h5>
        </b-col>
      </b-row>
    </b-col>
    <b-col lg="2">
      <b-row>
        <b-col lg="3">
          <div class="eventunavailable"></div>
        </b-col>
        <b-col lg="9">
          <h5>Ausente</h5>
        </b-col>
      </b-row>
    </b-col>
    <b-col lg="2">
      <b-row>
        <b-col lg="3">
          <div class="eventreinforced"></div>
        </b-col>
        <b-col lg="9">
          <h5>Reforço</h5>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>

export default {
  name: 'CalendarSubtitle',
}
</script>

<style scoped>
.eventsummoned {background-color: rgba(203, 241, 167, 0.9);border: 1px solid rgb(117, 182, 96);color: rgb(0, 0, 0);border-radius: 15px;padding: 10px;}
.eventunavailable {background-color: rgba(249, 158, 158, 0.9);border: 1px solid rgb(235, 82, 82);color: rgb(0, 0, 0);border-radius: 15px;padding: 10px;}
.eventavailable {background-color: rgba(253, 156, 66, 0.9);border: 1px solid rgb(233, 136, 46);color: rgb(0, 0, 0);border-radius: 15px;padding: 10px;}
.eventreinforced {background-color: rgba(144, 214, 233, 0.9);border: 1px solid rgb(56, 155, 222);color: rgb(0, 0, 0);border-radius: 15px;padding: 10px;}
</style>

<template>
  <div class="content" style="padding-top: 0px; background-color: #f7f7f7;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <h4 class="card-title">Estafetas</h4>
            </template>
            <div class="table-responsive">
              <div>
                <b-row>
                  <b-col lg="3" class="my-1">
                  </b-col>
                  <b-col lg="5" class="my-1">
                    <b-form-group
                      v-model="sortDirection"
                      label="Filtrar por"
                      label-cols-sm="2"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="filterOn"
                        :aria-describedby="ariaDescribedby"
                        class="mt-0"
                      >
                        <b-form-radio value="name">Nome</b-form-radio>
                        <b-form-radio value="email">Email</b-form-radio>
                        <b-form-radio value="phone">Telefone</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" class="my-1">
                    <b-form-group
                      label-for="filter-input"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Escreva para procurar"
                          @keyup.enter.native="pesquisar"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button @click="pesquisar()">Pesquisar</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-table  class="tables"
                  responsive
                  striped
                  bordered
                  outlined
                  hover
                  small
                  thead-class="cm-bg"
                  sort-icon-left
                  no-border-collapse :items="daData" :fields="fields" :busy="isBusy"
                  @sort-changed="sortingChanged" no-local-sorting
                  @row-clicked="(item, index, event) => getOCDA(item)">
                  <template #table-busy>
                    <div class="text-center text-warning my-1">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>A carregar...</strong>
                    </div>
                  </template>
                  <template #cell(locationUpdatedDate)="row">
                    {{ row.item.locationUpdatedDate}}
                    <a :href="mapsLink + row.item.locationGpsLatitude + ',' + row.item.locationGpsLongitude" target="_blank" v-if="row.item.locationUpdatedDate!=''">
                      <i class="nc-icon nc-pin-3" style="color: red;  font-size: 1.3em;  font-weight: bold;" title="Ver Localização"></i>
                    </a>
                  </template>
                  <template #cell(estado)="row">
                    <i v-if="row.item.estadoState == 1" class="fa fa-ban" style="color: red;  font-size: 1.5em;"></i>
                    <i v-if="row.item.estadoState == 2" class="fa fa-user" style="color: gray;  font-size: 1.5em;"></i>
                    <i v-if="row.item.estadoState == 3" class="fa fa-user" style=" color: lightgreen;  font-size: 1.5em;"></i>
                    {{ row.item.estado}}
                  </template>
                  <template #cell(phone)="row">
                    <a :href="'tel:'+row.item.phone" v-if="row.item.phone != ''" target="_blank" class="tel" title="Ligar"><i class="fa fa-phone" title="Ligar"></i>{{row.item.phone}}</a>
                  </template>
                </b-table>
                <b-row>
                  <b-col lg="8" class="my-1">
                    <!-- <b-button size="sm" variant="danger">Ver Localização Estafetas</b-button> -->
                    <b-button size="sm" variant="success" @click="addDa()">Adicionar Estafeta</b-button>
                  </b-col>
                  <b-col lg="2" class="my-1">
                    <b-form-group
                      label="Por Página"
                      label-for="per-page-select"
                      label-cols-lg="5"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        @change="handlePageSizeChange($event)"
                        align="right"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="2" class="my-1">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      @change="handlePageChange"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
                <b-modal :id="infoModal.id" size="xl" :title="infoModal.title" ok-only ok-variant="default" ok-title="Fechar" @hide="resetInfoModal">
                  <div style="margin: auto;width: 100%;padding:10px">
                    <b-row style="border: 3px solid #DAA520;border-style: none none solid none;">
                      <b-col lg="8">
                        <h4 style="color:#DAA520; margin-top: 0">Informação Pessoal</h4>
                      </b-col>
                      <b-col lg="4">
                        <b-button size="sm" variant="secondary" :pressed="seeHistorico" pill style=" margin-left:10px;padding:5px;border-color: white;float:right" @click="seeHistoricoInfo(infoModal.daapp.id)" title="Ver/Esconder Histórico de edições"  v-if="this.history != ''">
                          <i class="fa fa-hourglass" style="color: #DAA520; font-size: 1.5em;"></i>
                        </b-button>
                        <b-button size="sm" variant="secondary" pill style="padding:5px;border-color: white;float:right" @click="seeCandidateModal(infoModal.content?.element?.candidateId)" title="Ver informação da Candidatura"  v-if="infoModal.content?.element?.candidateId">
                          <i class="nc-icon nc-circle-09" style="color: #DAA520; font-size: 1.7em;-webkit-text-stroke: 1px #DAA520;"></i>
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Nome Completo<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.fullName}}</strong></b-row>
                        <b-form-input v-if="editBool" style="margin-bottom:15px"
                          id="name-input"
                          v-model="name"
                          type="text"
                        ></b-form-input>
                      </b-col>
                      <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Username DAApp</b-row>
                        <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{infoModal.content.userName}}</strong></b-row>
                      </b-col>
                      <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                        <b-row style="margin-left: 5px;color: gray">Nacionalidade<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.nationality}}</strong></b-row>
                        <b-form-select v-model="nationality" class="a" v-if="editBool" style="margin-bottom:15px">
                          <b-form-select-option disabled value=""></b-form-select-option>
                          <b-form-select-option value="Portuguesa">Portuguesa</b-form-select-option>
                          <b-form-select-option value="Brasileira">Brasileira</b-form-select-option>
                          <b-form-select-option value="Outra">Outra</b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="12" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                        <b-row style="margin-left: 5px;color: gray">Morada<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.address}}</strong>
                          <a :href="'https://www.google.pt/maps/place/'+ infoModal.content.address" target="_blank">
                            <i class="nc-icon nc-pin-3" style="color: red;  font-size: 1.3em;  font-weight: bold;" title="Ver Localização"></i>
                          </a>
                        </b-row>
                        <b-form-input v-if="editBool" style="margin-bottom:15px"
                          id="address-input"
                          v-model="address"
                          type="text"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="2" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Telefone<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool">
                          <a :href="'tel:'+infoModal.content.phone" v-if="infoModal.content.phone != ''" target="_blank" class="tel" title="Ligar"><i class="fa fa-phone" title="Ligar"></i><strong style="font-size: 12px;">{{infoModal.content.phone}}</strong></a>
                        </b-row>
                        <b-form-input v-if="editBool" style="margin-bottom:15px"
                          id="phone-input"
                          v-model="phone"
                          type="text"
                        ></b-form-input>
                      </b-col>
                      <b-col lg="2" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Telefone Secundário</b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool">
                          <a :href="'tel:'+infoModal.content.secondaryPhone" v-if="infoModal.content.secondaryPhone != ''" target="_blank" class="tel" title="Ligar"><i class="fa fa-phone" title="Ligar"></i><strong style="font-size: 12px;">{{infoModal.content.secondaryPhone}}</strong></a>
                        </b-row>
                        <b-form-input v-if="editBool" style="margin-bottom:15px"
                          id="secphone-input"
                          v-model="secondaryPhone"
                          type="text"
                        ></b-form-input>
                      </b-col>
                      <b-col lg="5" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Email<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.email}}</strong></b-row>
                        <b-form-input v-if="editBool" style="margin-bottom:15px"
                          id="email-input"
                          v-model="email"
                          type="text"
                        ></b-form-input>
                      </b-col>
                      <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                        <b-row style="margin-left: 5px;color: gray">Veículo<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.vehicle}}</strong></b-row>
                        <b-form-select v-model="vehicle" class="a" v-if="editBool" style="margin-bottom:15px">
                          <b-form-select-option disabled value=""></b-form-select-option>
                          <b-form-select-option value="Carro">Carro</b-form-select-option>
                          <b-form-select-option value="Mota">Mota</b-form-select-option>
                          <b-form-select-option value="Carro/Mota">Carro/Mota</b-form-select-option>
                          <b-form-select-option value="Mota/Carro">Mota/Carro</b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </div>
                  <div style="margin: auto;width: 100%;padding:10px">
                    <b-row style="border: 3px solid #DAA520;border-style: none none solid none;"><h4 style="color:#DAA520;margin-left: 10px; margin-top: 0">Faturação</h4></b-row>
                    <b-row>
                      <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Tipo Documento<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.docType}}</strong></b-row>
                        <b-form-select v-model="docType" class="a" v-if="editBool" style="margin-bottom:15px">
                          <b-form-select-option disabled value=""></b-form-select-option>
                          <b-form-select-option value="CC">Cartão de Cidadão</b-form-select-option>
                          <b-form-select-option value="CR">Cartão de Residência</b-form-select-option>
                          <b-form-select-option value="P">Passaporte</b-form-select-option>
                        </b-form-select>
                      </b-col>
                      <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Nº Documento<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.docNum}}</strong></b-row>
                        <b-form-input v-if="editBool" style="margin-bottom:15px"
                          id="docNum-input"
                          v-model="docNum"
                          type="text"
                        ></b-form-input>
                      </b-col>
                      <b-col lg="4" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Validade Documento<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.docExpireDate}}</strong></b-row>
                        <b-row v-if="editBool" style="margin-bottom:15px">
                          <b-col lg="3">
                            <b-form-input
                              id="docExpireDay-input"
                              v-model="docExpireDay"
                              type="number"
                              min="1"
                              max="31"
                            ></b-form-input>
                          </b-col>
                          <b-col lg="1">
                            <h3 style="text-align:center; margin-top: 0">/</h3>
                          </b-col>
                          <b-col lg="3">
                            <b-form-input
                              id="docExpireMon-input"
                              v-model="docExpireMon"
                              type="number"
                              min="1"
                              max="12"
                            ></b-form-input>
                          </b-col>
                          <b-col lg="1">
                            <h3 style="text-align:center; margin-top: 0">/</h3>
                          </b-col>
                          <b-col lg="4">
                            <b-form-input
                              id="docExpireYear-input"
                              v-model="docExpireYear"
                              type="number"
                              :min="(new Date()).getFullYear()"
                            ></b-form-input>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col lg="2" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                        <b-row style="margin-left: 5px;color: gray">NIF<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.nif}}</strong></b-row>
                        <b-form-input v-if="editBool" style="margin-bottom:15px"
                          id="nif-input"
                          v-model="nif"
                          type="text"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="2" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Nome Bancário</b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.bankAccountName}}</strong></b-row>
                        <b-form-input v-if="editBool" style="margin-bottom:15px"
                          id="bankAccName-input"
                          v-model="bankAccName"
                          type="text"
                        ></b-form-input>
                      </b-col>
                      <b-col lg="4" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">IBAN</b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.iban}}</strong></b-row>
                        <b-form-input v-if="editBool" style="margin-bottom:15px"
                          id="iban-input"
                          v-model="iban"
                          type="text"
                        ></b-form-input>
                      </b-col>
                      <b-col lg="1" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                        <b-row style="margin-left: 5px;color: gray">Tarifário<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.paymentSettingsId}}</strong></b-row>
                        <b-form-select v-model="paymentSettings" class="a" v-if="editBool" style="margin-bottom:15px">
                          <b-form-select-option disabled value=""></b-form-select-option>
                          <b-form-select-option value="1">1</b-form-select-option>
                          <b-form-select-option value="2">2</b-form-select-option>
                        </b-form-select>
                      </b-col>
                      <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                        <b-row style="margin-left: 5px;color: gray">Retenção</b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.retention}}</strong></b-row>
                        <b-form-select v-model="retention" class="a" v-if="editBool">
                          <b-form-select-option disabled value=""></b-form-select-option>
                          <b-form-select-option value="Sem Retenção">Sem Retenção</b-form-select-option>
                          <b-form-select-option value="11.5%">11.5%</b-form-select-option>
                          <b-form-select-option value="25%">25%</b-form-select-option>
                        </b-form-select>
                      </b-col>
                      <b-col lg="2" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                        <b-row style="margin-left: 5px;color: gray">IVA</b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.iva? 'Sim':'Não'}}</strong></b-row>
                        <b-form-checkbox
                          id="iva"
                          v-model="iva"
                          name="iva"
                          v-if="editBool"
                        >
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </div>
                  <div style="margin: auto;width: 100%;padding:10px">
                    <b-row style="border: 3px solid #DAA520;border-style: none none solid none;"><h4 style="color:#DAA520;margin-left: 10px; margin-top: 0">ComerMelhor</h4></b-row>
                    <b-row>
                      <b-col lg="2" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Data de Começo<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.startDateCM}}</strong></b-row>
                        <div v-if="editBool">
                          <b-form-datepicker id="startDate" size="sm" :min="new Date()" button-only v-model="startDate" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar" v-if="editBool" style="margin-bottom:15px"></b-form-datepicker>
                          {{startDate}}
                        </div>
                      </b-col>
                      <b-col lg="2" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Data de Término<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.finishDateCM}}</strong></b-row>
                        <div v-if="editBool">
                          <b-form-datepicker id="finishDate" size="sm" button-only v-model="finishDate" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar" v-if="editBool" style="margin-bottom:15px"></b-form-datepicker>
                          {{finishDate}}
                        </div>
                      </b-col>
                      <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Nº do POS<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.posNum}}</strong></b-row>
                        <b-form-input v-if="editBool" style="margin-bottom:15px"
                          id="posNum-input"
                          v-model="posNum"
                          type="text"
                        ></b-form-input>
                      </b-col>
                      <b-col lg="2" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                        <b-row style="margin-left: 5px;color: gray">Tipo de Mochila<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.bagType}}</strong></b-row>
                        <b-form-select v-model="bagType" class="a" v-if="editBool" style="margin-bottom:15px">
                          <b-form-select-option disabled value=""></b-form-select-option>
                          <b-form-select-option value="V1">V1</b-form-select-option>
                          <b-form-select-option value="V2">V2</b-form-select-option>
                          <b-form-select-option value="V3">V3</b-form-select-option>
                          <b-form-select-option value="V4">V4</b-form-select-option>
                        </b-form-select>
                      </b-col>
                      <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                        <b-row style="margin-left: 5px;color: gray">Zona<p style="color: red;font-size: 16px" v-if="editBool">*</p></b-row>
                        <b-row style="margin-left: 5px;" v-if="!editBool"><strong style="font-size: 12px;">{{infoModal.content.zone}}</strong></b-row>
                        <b-form-select v-model="zone" class="a" v-if="editBool" style="margin-bottom:15px">
                          <b-form-select-option disabled value="">Escolha a Zona</b-form-select-option>
                          <b-form-select-option v-for="zona in zonas" :key="zona.name" :value="zona">{{zona.name}}</b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </div>
                  <div style="margin: auto;width: 100%;padding:10px" v-if="this.daSchedule != null">
                    <b-row style="border: 3px solid #DAA520;border-style: none none solid none;height:50px"><h4 style="color:#DAA520;margin-left: 10px; margin-top: 0;">Horário</h4><i class="fa fa-calendar absentIcon" title="Ver Calendário do Estafeta" style="margin-left: 20px;font-size: 30px" @click="daCalendarModalInfo(infoModal.content, infoModal.id, $event.target)"></i><i class="fa fa-calendar absentIcon" title="Ver Férias do Estafeta" style="margin-left: 20px;font-size: 30px" @click="daAbsenceModalInfo(infoModal.content, infoModal.id, $event.target)"><i class="nc-icon nc-simple-remove" style="color: red;font-size:1.3em;font-weight: bold;position:relative;left:-6px;bottom:34px;"></i></i></b-row>
                    <div class="row justify-content-center" style="margin: auto;width: 90%;border: 1px solid #DAA520;border-style: none none solid none;">
                      <b-col>
                        <b-row style="border: 1px solid #DAA520;">
                          <h5 style="text-align: center;font-weight:bold;width:100%;color:white">HORÁRIO</h5>
                        </b-row>
                        <b-row style="border: 1px solid #DAA520;height:31px">
                          <h5 style="text-align: center;font-weight:bold;width:100%">ALMOÇO</h5>
                        </b-row>
                        <b-row style="border: 1px solid #DAA520;height:31px">
                          <h5 style="text-align: center;font-weight:bold;width:100%">JANTAR</h5>
                        </b-row>
                      </b-col>
                      <b-col v-for="day in scheduleHeader" :key="day.id">
                        <b-row style="border: 1px solid #DAA520;">
                          <h5 style="text-align: center;font-weight:bold;width:100%">{{day.header}}</h5>
                        </b-row>
                        <b-row style="border: 1px solid #DAA520;">
                          <b-form-select size="md" v-model="(daSchedule.filter(s => s.shift == 1 && s.dayOfWeek == day.id)[0]).state" :style="'background-color:'+((daSchedule.filter(s => s.shift == 1 && s.dayOfWeek == day.id)[0]).state == 'Summoned'? 'lightgreen':((daSchedule.filter(s => s.shift == 1 && s.dayOfWeek == day.id)[0]).state == 'Available'? 'lightblue':'gray')) +';cursor: pointer; color: black; font-size:10px;text-align:center'" :disabled="!editBool">
                            <b-form-select-option value="Unavailable" style="background-color:gray">Indisponivel</b-form-select-option>
                            <b-form-select-option value="Summoned" style="background-color:lightgreen">Convocado</b-form-select-option>
                            <b-form-select-option value="Available" style="background-color:lightblue">Disponivel</b-form-select-option>
                          </b-form-select>
                        </b-row>
                        <b-row style="border: 1px solid #DAA520;">
                          <b-form-select size="md" v-model="(daSchedule.filter(s => s.shift == 2 && s.dayOfWeek == day.id)[0]).state" :style="'background-color:'+((daSchedule.filter(s => s.shift == 2 && s.dayOfWeek == day.id)[0]).state == 'Summoned'? 'lightgreen':((daSchedule.filter(s => s.shift == 2 && s.dayOfWeek == day.id)[0]).state == 'Available'? 'lightblue':'gray')) +';cursor: pointer; color: black; font-size:10px;text-align:center'" :disabled="!editBool">
                            <b-form-select-option value="Unavailable" style="background-color:gray">Indisponivel</b-form-select-option>
                            <b-form-select-option value="Summoned" style="background-color:lightgreen">Convocado</b-form-select-option>
                            <b-form-select-option value="Available" style="background-color:lightblue">Disponivel</b-form-select-option>
                          </b-form-select>
                        </b-row>
                      </b-col>
                      <b-button size="sm" variant="warning" style="float:right" @click="changeDASchedule(daSchedule, daScheduleSaved, infoModal.content.element.id)" v-if="JSON.stringify(this.daSchedule)!=JSON.stringify(this.daScheduleSaved)">Guardar Alterações</b-button>
                    </div>
                  </div>
                  <div style="margin: auto;width: 100%;padding:10px">
                    <b-row style="border: 3px solid #DAA520;border-style: none none solid none;"><h4 style="color:#DAA520;margin-left: 10px; margin-top: 0">Observações</h4></b-row>
                    <b-row style="border: 1px solid #DAA520;border-style: none none solid none;" v-if="!editBool"><p style="font-size: 12px;color:gray; margin:15px">{{infoModal.content.observations}}</p></b-row>
                    <b-form-textarea v-if="editBool"
                      id="daObservation-input"
                      v-model="daObservation"
                      placeholder="Observações sobre o estafeta.."
                    ></b-form-textarea>
                  </div>
                  <b-button size="sm" variant="warning" style="float:right" @click="editDaModal()" v-if="!editBool">Editar</b-button>
                  <b-button size="sm" variant="success" style="float:right" @click="postEditDA(infoModal.content, infoModal.daapp, name, username, phone, secondaryPhone, email, paymentSettings, iva, retention, zone, nif, docNum, docType, docExpireDay, docExpireMon, docExpireYear, nationality, address, iban, bankAccName, vehicle, daObservation, startDate, finishDate, bagType, posNum)" v-if="editBool">Gravar</b-button>
                  <b-button size="sm" variant="secondary" style="float:right" @click="cancelEditDaModal(infoModal.content)" v-if="editBool">Cancelar</b-button>
                  <!-- <b-button size="sm" variant="info" @click="getDAFiles(infoModal.content.element.id)" v-if="!seeDocs && !editBool">Ver Documentos</b-button> -->
                  <div style="margin: auto;width: 100%;padding:10px" v-if="!editBool && seeDocs">
                    <b-row style="border: 3px solid #DAA520;border-style: none none solid none;"><h4 style="color:#DAA520;margin-left: 10px; margin-top: 0">Documentos</h4></b-row>
                    <div>
                      <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
                        <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid solid;">
                          <b-row style="margin-left: 5px;font-size: 18px;color: gray">Tipo de Documento</b-row>
                        </b-col>
                        <b-col lg="9">
                          <b-row>
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;font-size: 18px;color: gray">Data de Upload</b-row>
                            </b-col>
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;font-size: 18px;color: gray">Utilizador</b-row>
                            </b-col>
                            <b-col lg="6" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;font-size: 18px;color: gray">Documento</b-row>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
                        <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid solid;">
                          <b-row style="margin-left: 5px;"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Currículos" @click="submitFilesInfo( infoModal.content.element.id, 'CV', infoModal.id, $event.target)"></i><strong style="font-size: 12px;">Curriculos</strong></b-row>
                        </b-col>
                        <b-col lg="9">
                          <b-row v-for="doc in daFiles.cv" :key="doc.id">
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
                            </b-col>
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{doc.user.userName}}</strong></b-row>
                            </b-col>
                            <b-col lg="5" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                              <b-row style="margin-left: 5px;" class="docsView" @click="downloadFiles(doc.id, doc.docName)"><i class="fa fa-file" style="font-size: 15px;"></i><strong style="font-size: 12px;">{{doc.docName}}</strong></b-row>
                            </b-col>
                            <b-col lg="1" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <i class="fa fa-minus-square removeFileIcon" title="Remover Ficheiro" @click="deleteDa(doc, infoModal.id, $event.target)"></i>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
                        <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid solid;">
                          <b-row style="margin-left: 5px;"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Documentos de Identificação" @click="submitFilesInfo( infoModal.content.element.id, 'IDDoc', infoModal.id, $event.target)"></i><strong style="font-size: 12px;">Documentos de Identificação</strong></b-row>
                        </b-col>
                        <b-col lg="9">
                          <b-row v-for="doc in daFiles.idDoc" :key="doc.id">
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
                            </b-col>
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{doc.user.userName}}</strong></b-row>
                            </b-col>
                            <b-col lg="5" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                              <b-row style="margin-left: 5px;" class="docsView" @click="downloadFiles(doc.id, doc.docName)"><i class="fa fa-file" style="font-size: 15px;"></i><strong style="font-size: 12px;">{{doc.docName}}</strong></b-row>
                            </b-col>
                            <b-col lg="1" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <i class="fa fa-minus-square removeFileIcon" title="Remover Ficheiro" @click="deleteDa(doc, infoModal.id, $event.target)"></i>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
                        <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid solid;">
                          <b-row style="margin-left: 5px;"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Comprovativos de Morada" @click="submitFilesInfo( infoModal.content.element.id, 'AddressProof', infoModal.id, $event.target)"></i><strong style="font-size: 12px;">Comprovativos de Morada</strong></b-row>
                        </b-col>
                        <b-col lg="9">
                          <b-row v-for="doc in daFiles.addressProof" :key="doc.id">
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
                            </b-col>
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{doc.user.userName}}</strong></b-row>
                            </b-col>
                            <b-col lg="5" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                              <b-row style="margin-left: 5px;" class="docsView" @click="downloadFiles(doc.id, doc.docName)"><i class="fa fa-file" style="font-size: 15px;"></i><strong style="font-size: 12px;">{{doc.docName}}</strong></b-row>
                            </b-col>
                            <b-col lg="1" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <i class="fa fa-minus-square removeFileIcon" title="Remover Ficheiro" @click="deleteDa(doc, infoModal.id, $event.target)"></i>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
                        <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid solid;">
                          <b-row style="margin-left: 5px;"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Comprovativos Bancários" @click="submitFilesInfo( infoModal.content.element.id, 'BankProof', infoModal.id, $event.target)"></i><strong style="font-size: 12px;">Comprovativos Bancários</strong></b-row>
                        </b-col>
                        <b-col lg="9">
                          <b-row v-for="doc in daFiles.bankProof" :key="doc.id">
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
                            </b-col>
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{doc.user.userName}}</strong></b-row>
                            </b-col>
                            <b-col lg="5" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                              <b-row style="margin-left: 5px;" class="docsView" @click="downloadFiles(doc.id, doc.docName)"><i class="fa fa-file" style="font-size: 15px;"></i><strong style="font-size: 12px;">{{doc.docName}}</strong></b-row>
                            </b-col>
                            <b-col lg="1" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <i class="fa fa-minus-square removeFileIcon" title="Remover Ficheiro" @click="deleteDa(doc, infoModal.id, $event.target)"></i>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
                        <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid solid;">
                          <b-row style="margin-left: 5px;"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Seguros" @click="submitFilesInfo( infoModal.content.element.id, 'Insurance', infoModal.id, $event.target)"></i><strong style="font-size: 12px;">Seguros</strong></b-row>
                        </b-col>
                        <b-col lg="9">
                          <b-row v-for="doc in daFiles.insurance" :key="doc.id">
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
                            </b-col>
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{doc.user.userName}}</strong></b-row>
                            </b-col>
                            <b-col lg="5" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                              <b-row style="margin-left: 5px;" class="docsView" @click="downloadFiles(doc.id, doc.docName)"><i class="fa fa-file" style="font-size: 15px;"></i><strong style="font-size: 12px;">{{doc.docName}}</strong></b-row>
                            </b-col>
                            <b-col lg="1" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <i class="fa fa-minus-square removeFileIcon" title="Remover Ficheiro" @click="deleteDa(doc, infoModal.id, $event.target)"></i>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
                        <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid solid;">
                          <b-row style="margin-left: 5px;"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Contratos CM" @click="submitFilesInfo( infoModal.content.element.id, 'CMContract', infoModal.id, $event.target)"></i><strong style="font-size: 12px;">Contratos CM</strong></b-row>
                        </b-col>
                        <b-col lg="9">
                          <b-row v-for="doc in daFiles.cmContract" :key="doc.id">
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
                            </b-col>
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{doc.user.userName}}</strong></b-row>
                            </b-col>
                            <b-col lg="5" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                              <b-row style="margin-left: 5px;" class="docsView" @click="downloadFiles(doc.id, doc.docName)"><i class="fa fa-file" style="font-size: 15px;"></i><strong style="font-size: 12px;">{{doc.docName}}</strong></b-row>
                            </b-col>
                            <b-col lg="1" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <i class="fa fa-minus-square removeFileIcon" title="Remover Ficheiro" @click="deleteDa(doc, infoModal.id, $event.target)"></i>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
                        <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid solid;">
                          <b-row style="margin-left: 5px;"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Outros" @click="submitFilesInfo( infoModal.content.element.id, 'Others', infoModal.id, $event.target)"></i><strong style="font-size: 12px;">Outros</strong></b-row>
                        </b-col>
                        <b-col lg="9">
                          <b-row v-for="doc in daFiles.others" :key="doc.id">
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
                            </b-col>
                            <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <b-row style="margin-left: 5px;"><strong style="font-size: 12px;">{{doc.user.userName}}</strong></b-row>
                            </b-col>
                            <b-col lg="5" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
                              <b-row style="margin-left: 5px;" class="docsView" @click="downloadFiles(doc.id, doc.docName)"><i class="fa fa-file" style="font-size: 15px;"></i><strong style="font-size: 12px;">{{doc.docName}}</strong></b-row>
                            </b-col>
                            <b-col lg="1" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
                              <i class="fa fa-minus-square removeFileIcon" title="Remover Ficheiro" @click="deleteDa(doc, infoModal.id, $event.target)"></i>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <div class="table-responsive" v-if="this.seeHistorico">
                    <b-table class="tables"
                      responsive
                      striped
                      bordered
                      outlined
                      hover
                      small
                      no-border-collapse
                      thead-class="cm-bg"
                      id="historyTeable"
                      :items="history"
                      :fields="histFields"
                      v-if="this.history != ''">
                      <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{ width: field.key === 'foo' ? '20%' : '20%' }"
                        >
                      </template>
                      <!-- <template #row-details="row">
                        <b-row>
                          <b-col lg="3">
                          </b-col>
                          <b-col lg="9">
                            {{ row.item.observations }}
                          </b-col>
                        </b-row>
                      </template> -->
                      <template #cell(previous)="row">
                        <p v-html="row.item.previous" style="white-space: pre-wrap;font-size: 10px"></p>
                      </template>
                      <template #cell(parameters)="row">
                        <p v-html="row.item.parameters" style="white-space: pre-wrap;font-size: 10px"></p>
                      </template>
                    </b-table>
                  </div>
                  <div class="row justify-content-center" v-if="infoModal.content != ''">
                    <div style="margin:10px" v-if="infoModal.daapp.isAuthorized == true">
                      <b-button size="md" variant="secondary" class="acaobutton" @click="zonaInfo(infoModal.daapp, infoModal.id, $event.target)" title="Definir Zona">
                        <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                          <div class="font-icon-detail">
                            <i class="nc-icon nc-square-pin" style="color: #DAA520; font-size: 2.5em;"></i>
                          </div>
                        </div>
                      </b-button>
                    </div>
                    <div style="margin:10px" v-if="infoModal.daapp.isCheckedin == true && infoModal.daapp.isAuthorized == true">
                      <b-button size="md" variant="secondary" class="acaobutton" @click="checkoutInfo(infoModal.daapp, infoModal.id, $event.target)" title="Forçar Checkout">
                        <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                          <div class="font-icon-detail">
                            <i class="nc-icon nc-button-power" style="color: #DAA520; font-size: 2.5em;"></i>
                          </div>
                        </div>
                      </b-button>
                    </div>
                    <div style="margin:10px" v-if="infoModal.daapp.isAuthorized == true">
                      <b-button size="md" variant="secondary" class="acaobutton" @click="bloquearInfo(infoModal.daapp, infoModal.id, $event.target)" title="Bloquear Estafeta">
                        <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                          <div class="font-icon-detail">
                            <i class="nc-icon nc-simple-remove" style="color: #DAA520; font-size: 2.5em;"></i>
                          </div>
                        </div>
                      </b-button>
                    </div>
                    <div style="margin:10px" v-if="infoModal.daapp.isAuthorized == false">
                      <b-button size="md" variant="secondary" class="acaobutton" @click="desbloquearInfo(infoModal.daapp, infoModal.id, $event.target)" title="Desbloquear Estafeta">
                        <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                          <div class="font-icon-detail">
                            <i class="nc-icon nc-simple-add" style="color: #DAA520; font-size: 2.5em;"></i>
                          </div>
                        </div>
                      </b-button>
                    </div>
                  </div>
                </b-modal>
                <b-modal :id="submitFilesModal.id" :title="submitFilesModal.title"  :ok-disabled="files == ''"  ok-variant="success" ok-title="Guardar" cancel-title="Cancelar" @hide="resetSubmitFilesModal" @ok="submitFiles(submitFilesModal.deliveryAgentId, submitFilesModal.docType)">
                  <div>
                    <b-row style="border: 3px solid #DAA520;border-style: none none solid none;"><h4 style="color: #DAA520;margin-left: 10px; margin-top: 0">Carregamento de Documentos</h4> <h4 style="font-size:10px; margin-top: 0">(tamanho max. 2MB)</h4></b-row>
                    <div style="border: 1px solid gray;margin:20px; padding: 10px">
                      <label>
                        <input type="file" multiple @change="handleFileUploads( $event )"/>
                      </label>
                      <div style="width:fit-content">
                          <b-row v-for="(file, key) in files" :key="key" style="margin: 10px;border: 1px solid #DAA520;padding:5px;">
                            <i class="fa fa-file" style="font-size: 15px;"></i><strong style="font-size: 12px;">{{ file.name }}</strong>
                          </b-row>
                      </div>
                    </div>
                  </div>
                </b-modal>
                <b-modal :id="zonaModal.id" :title="zonaModal.title"  :ok-disabled="zona == ''"  ok-variant="success" ok-title="Definir" cancel-title="Cancelar" @hide="resetZonaModal" @ok="postDefinirZona(zonaModal.content, zona, observation)">
                  <b-form-select v-model="zona" class="a">
                    <b-form-select-option disabled value="">Escolha a Zona</b-form-select-option>
                    <b-form-select-option v-for="zona in zonas" :key="zona.name" :value="zona">{{zona.name}}</b-form-select-option>
                  </b-form-select>
                </b-modal>
                <b-modal :id="checkoutModal.id" :title="checkoutModal.title"   :ok-disabled="observation == ''" ok-variant="success" ok-title="Checkout" cancel-title="Cancelar" @hide="resetCheckoutModal" @ok="postForcarCheckout(checkoutModal.content, observation)">
                  <b-row style="margin-left:5px">Observações da Ação<p style="color: red;font-size: 16px">*</p></b-row>
                  <b-form-textarea
                    id="observation-input"
                    v-model="observation"
                    placeholder="Observações sobre a ação.."
                  ></b-form-textarea>
                </b-modal>
                <b-modal :id="bloquearModal.id" :title="bloquearModal.title"  :ok-disabled="observation == ''"  ok-variant="success" ok-title="Bloquear" cancel-title="Cancelar" @hide="resetBloquearModal" @ok="postBloquearDA(bloquearModal.content, observation)">
                  <b-row style="margin-left:5px">Observações da Ação<p style="color: red;font-size: 16px">*</p></b-row>
                  <b-form-textarea
                    id="observation-input"
                    v-model="observation"
                    placeholder="Observações sobre a ação.."
                  ></b-form-textarea>
                </b-modal>
                <b-modal :id="desbloquearModal.id" :title="desbloquearModal.title"  :ok-disabled="observation == ''"  ok-variant="success" ok-title="Desbloquear" cancel-title="Cancelar" @hide="resetDesbloquearModal" @ok="postDesbloquearDA(desbloquearModal.content, observation)">
                  <b-row style="margin-left:5px">Observações da Ação<p style="color: red;font-size: 16px">*</p></b-row>
                  <b-form-textarea
                    id="observation-input"
                    v-model="observation"
                    placeholder="Observações sobre a ação.."
                  ></b-form-textarea>
                </b-modal>
                <b-modal :id="addDaModal.id" size="xl" :title="addDaModal.title" :ok-disabled="usernameAvailable != true || name == '' || phone == '' || email == '' || username == '' || password == '' || zone == '' || paymentSettings == '' || address == '' || zipcode1 == '' || zipcode2 == '' || local == '' || vehicle == '' || docType == '' || docNum == '' || docExpireDay == '' || docExpireMon == '' || docExpireYear == '' || nif == '' || startDate == '' || posNum == '' || bagType == ''" ok-variant="success" ok-title="Adicionar" cancel-title="Cancelar" @hide="resetAddDaModal" @ok="postAddDA(addDaModal.content, name, username, password, phone, secondaryPhone, email, paymentSettings, iva, retention, zone, nif, docNum, docType, docExpireDay, docExpireMon, docExpireYear, nationality, address, zipcode1, zipcode2, local, iban, bankAccName, vehicle, daObservation, startDate, bagType, posNum)">
                  <div>
                    <div style="margin: auto;width: 100%;border: 1px solid #DAA520;padding:10px">
                      <b-row><h4 style="color:#DAA520;margin-left: 10px; margin-top: 0">Informação Pessoal</h4></b-row>
                      <b-row>
                        <b-col lg="5" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Nome Completo<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-input
                            id="name-input"
                            v-model="name"
                            type="text"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Username DAApp<p style="color: red;font-size: 16px">*</p>
                          <i class="nc-icon nc-check-2" style="color: lightgreen;  font-size: 1.5em;  font-weight: bold;margin-left:15px" title="Username Disponivel" v-if="usernameAvailable == true"></i>
                          <i class="nc-icon nc-check-2" style="color: gray;  font-size: 1.5em;  font-weight: bold;margin-left:15px; cursor: pointer" title="Ver disponibilidade do Username" v-if="usernameAvailable == 'changed'" @click="validateUsername(username)"></i>
                          <i class="nc-icon nc-check-2" style="color: red;  font-size: 1.5em;  font-weight: bold;margin-left:15px" title="Username Indisponivel" v-if="usernameAvailable == false"></i></b-row>
                          <b-form-input
                            id="username-input"
                            v-model="username"
                            type="text"
                            @change="usernameChanged"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Password DAApp<p style="color: red;font-size: 16px">*</p>
                          <i class="fa fa-eye-slash" :title="'Esconder'" style="color: gray;  font-size: 1.5em;margin-left:15px; cursor: pointer" v-if="showPassword" @click="showPassword = !showPassword"></i>
                          <i class="fa fa-eye" :title="'Mostrar'" style="color: gray;  font-size: 1.5em;margin-left:15px; cursor: pointer" v-if="!showPassword" @click="showPassword = !showPassword"></i></b-row>
                          <b-form-input
                            id="password-input"
                            v-model="password"
                            :type="this.showPassword? 'text': 'password'"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Nacionalidade<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-select v-model="nationality" class="a">
                            <b-form-select-option disabled value=""></b-form-select-option>
                            <b-form-select-option value="Portuguesa">Portuguesa</b-form-select-option>
                            <b-form-select-option value="Brasileira">Brasileira</b-form-select-option>
                            <b-form-select-option value="Outra">Outra</b-form-select-option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="5" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Morada<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-input
                            id="address-input"
                            v-model="address"
                            type="text"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Código de Postal<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-row>
                            <b-col lg="5">
                              <b-form-input
                                id="zipcode1-input"
                                v-model="zipcode1"
                                type="number"
                              ></b-form-input>
                            </b-col>
                            <b-col lg="2">
                              <h3 style="text-align:center; margin-top: 0">-</h3>
                            </b-col>
                            <b-col lg="5">
                              <b-form-input
                                id="zipcode2-input"
                                v-model="zipcode2"
                                type="number"
                              ></b-form-input>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Localidade<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-input
                            id="local-input"
                            v-model="local"
                            type="text"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="2" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Telefone<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-input
                            id="phone-input"
                            v-model="phone"
                            type="text"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Telefone Secundário</b-row>
                          <b-form-input
                            id="secphone-input"
                            v-model="secondaryPhone"
                            type="text"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="5" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Email<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-input
                            id="email-input"
                            v-model="email"
                            type="text"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Veículo<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-select v-model="vehicle" class="a">
                            <b-form-select-option disabled value=""></b-form-select-option>
                            <b-form-select-option value="Carro">Carro</b-form-select-option>
                            <b-form-select-option value="Mota">Mota</b-form-select-option>
                            <b-form-select-option value="Carro/Mota">Carro/Mota</b-form-select-option>
                            <b-form-select-option value="Mota/Carro">Mota/Carro</b-form-select-option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                    </div>
                    <div style="margin: auto;width: 100%;border: 1px solid #DAA520;padding:10px">
                      <b-row><h4 style="color:#DAA520;margin-left: 10px; margin-top: 0">Faturação</h4></b-row>
                      <b-row>
                        <b-col lg="3" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Tipo Documento<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-select v-model="docType" class="a">
                            <b-form-select-option disabled value=""></b-form-select-option>
                            <b-form-select-option value="CC">Cartão de Cidadão</b-form-select-option>
                            <b-form-select-option value="CR">Cartão de Residência</b-form-select-option>
                            <b-form-select-option value="P">Passaporte</b-form-select-option>
                          </b-form-select>
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Nº Documento<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-input
                            id="docNum-input"
                            v-model="docNum"
                            type="text"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Validade Documento<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-row>
                            <b-col lg="3">
                              <b-form-input
                                id="docExpireDay-input"
                                v-model="docExpireDay"
                                type="number"
                                min="1"
                                max="31"
                              ></b-form-input>
                            </b-col>
                            <b-col lg="1">
                              <h3 style="text-align:center; margin-top: 0">/</h3>
                            </b-col>
                            <b-col lg="3">
                              <b-form-input
                                id="docExpireMon-input"
                                v-model="docExpireMon"
                                type="number"
                                min="1"
                                max="12"
                              ></b-form-input>
                            </b-col>
                            <b-col lg="1">
                              <h3 style="text-align:center; margin-top: 0">/</h3>
                            </b-col>
                            <b-col lg="4">
                              <b-form-input
                                id="docExpireYear-input"
                                v-model="docExpireYear"
                                type="number"
                                :min="(new Date()).getFullYear()"
                              ></b-form-input>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">NIF<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-input
                            id="nif-input"
                            v-model="nif"
                            type="text"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="2" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Nome Bancário</b-row>
                          <b-form-input
                            id="bankAccName-input"
                            v-model="bankAccName"
                            type="text"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">IBAN</b-row>
                          <b-form-input
                            id="iban-input"
                            v-model="iban"
                            type="text"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="1" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Tarifário<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-select v-model="paymentSettings" class="a">
                            <b-form-select-option disabled value=""></b-form-select-option>
                            <b-form-select-option value="1">1</b-form-select-option>
                            <b-form-select-option value="2">2</b-form-select-option>
                          </b-form-select>
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Retenção</b-row>
                          <b-form-select v-model="retention" class="a">
                            <b-form-select-option disabled value=""></b-form-select-option>
                            <b-form-select-option value="Sem Retenção">Sem Retenção</b-form-select-option>
                            <b-form-select-option value="11.5%">11.5%</b-form-select-option>
                            <b-form-select-option value="25%">25%</b-form-select-option>
                          </b-form-select>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">IVA</b-row>
                          <b-form-checkbox
                            id="iva"
                            v-model="iva"
                            name="iva"
                          >
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                    </div>
                    <div style="margin: auto;width: 100%;border: 1px solid #DAA520;padding:10px">
                      <b-row><h4 style="color:#DAA520;margin-left: 10px; margin-top: 0">ComerMelhor</h4></b-row>
                      <b-row>
                        <b-col lg="3" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Data de Começo<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-datepicker id="startDate" size="sm" :min="new Date()" button-only v-model="startDate" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"></b-form-datepicker>
                          {{startDate}}
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Nº do POS<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-input
                            id="posNum-input"
                            v-model="posNum"
                            type="text"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Tipo de Mochila<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-select v-model="bagType" class="a">
                            <b-form-select-option disabled value=""></b-form-select-option>
                            <b-form-select-option value="V1">V1</b-form-select-option>
                            <b-form-select-option value="V2">V2</b-form-select-option>
                            <b-form-select-option value="V3">V3</b-form-select-option>
                            <b-form-select-option value="V4">V4</b-form-select-option>
                          </b-form-select>
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <b-row style="margin-left: 5px;color: gray">Zona<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-select v-model="zone" class="a">
                            <b-form-select-option disabled value="">Escolha a Zona</b-form-select-option>
                            <b-form-select-option v-for="zona in zonas" :key="zona.name" :value="zona">{{zona.name}}</b-form-select-option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                    </div>
                    <div style="margin: auto;width: 100%;border: 1px solid #DAA520;padding:10px">
                      <b-row><h4 style="color:#DAA520;margin-left: 10px; margin-top: 0">Observações</h4></b-row>
                      <b-form-textarea
                        id="daObservation-input"
                        v-model="daObservation"
                        placeholder="Observações sobre o estafeta.."
                      ></b-form-textarea>
                    </div>
                  </div>
                  <i class="fa fa-info-circle" style="font-size: 20px; float:right; cursor: help" title="Se botão estiver desativado, experimente verificar a disponibilidade do Username DAAPP"></i>
                </b-modal>
                <b-modal :id="mapDaModal.id" :title="mapDaModal.title" ok-only ok-variant="secondary" ok-title="Fechar" @hide="resetMapDaModal">
                  <a :href="mapsLink" target="_blank">
                    <i class="nc-icon nc-pin-3" style="color: red;  font-size: 1.3em;  font-weight: bold;" title="Ver Localização"></i>
                  </a>
                </b-modal>
                <b-modal :id="deleteDaModal.id" :title="deleteDaModal.title" ok-variant="success" ok-title="Sim" cancel-variant="secondary" cancel-title="Não" @hide="resetDeleteDaModal" @ok="deleteDAFile(deleteDaModal.content.id, deleteDaModal.content.deliveryAgentId)">
                </b-modal>
                <b-modal size="xl" :id="daCalendarModal.id" :title="daCalendarModal.title" ok-only ok-variant="secondary" ok-title="Fechar" @hide="resetDACalendarModal">
                  <CalendarWeekDAAbsences :deliveryAgentId="daCalendarModal.content.id"></CalendarWeekDAAbsences>
                </b-modal>
                <b-modal size="xl" :id="daAbsenceModal.id" :title="daAbsenceModal.title" ok-only ok-variant="secondary" ok-title="Fechar" @hide="resetDAAbsenceModal">
                  <div class="table-responsive justify-content-center" v-if="(absences != '' && absencesRetrieved) || !absencesRetrieved" style="margin: auto;width: 90%;padding: 10px;">
                    <b-table  class="tables"
                      responsive
                      striped
                      bordered
                      outlined
                      hover
                      small
                      no-border-collapse
                      thead-class="cm-bg"
                      id="absencesTable"
                      :busy="isBusyAbsences"
                      :items="absences"
                      :fields="absencesFields">
                      <!-- @row-clicked="(item, index, event) => openAbsenceModal(item, index, event)" -->
                      <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{ width: field.key === 'foo' ? '20%' : '20%' }"
                        >
                      </template>
                      <template #cell(show_details)="row">
                        <b-row style="margin-left: 0; width:80px">
                          <b-button size="xs" variant="secondary" class="acaobutton" @click="openAbsenceModal(row.item, '', '')" title="Editar">
                            <i class="nc-icon nc-settings-90" style="color: #DAA520; font-size: 1.5em;"></i>
                          </b-button>
                          <b-button size="xs" variant="secondary" class="acaobutton" @click="absenceDeleteInfo(row.item)" title="Remover">
                            <i class="nc-icon nc-simple-remove" style="color: #DAA520; font-size: 1.5em;"></i>
                          </b-button>
                        </b-row>
                      </template>
                    </b-table>
                  </div>
                  <div v-if="absences == '' && absencesRetrieved">
                    Não existem férias marcadas.
                  </div>
                </b-modal>
                <b-modal :id="scheduleAbsenceModal.id" :title="scheduleAbsenceModal.title" cancel-title="Cancelar" :ok-disabled="(!initialDateshift1 && !initialDateshift2) || (notSameDay && !finalDateshift1 && !finalDateshift2)" ok-variant="success" ok-title="Alterar" @hide="resetScheduleAbsenceModal" @ok="putAbsence(scheduleAbsenceModal.content.element, scheduleAbsenceModal.content.deliveryAgentId, initialDate, initialDateshift1, initialDateshift2, finalDate, finalDateshift1, finalDateshift2, notSameDay)">
                  <div class="row justify-content-center" style="margin: auto;width: 100%;">
                    <b-row style="margin: auto;width: 100%;">
                      <b-col lg="3" class="my-1">
                        <p style="text-align: left; padding: 6px 0;margin-top:10px" v-if="!notSameDay">Data</p>
                        <p style="text-align: left; padding: 6px 0;margin-top:10px" v-if="notSameDay">Data Inicial</p>
                      </b-col>
                      <b-col lg="9" class="my-1">
                        <b-row class="my-1">
                          <b-col lg="2" class="my-1">
                            <b-form-datepicker id="initialDate" size="sm" button-only v-model="initialDate" :min="todayDate" :max="notSameDay? finalDate: null" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"></b-form-datepicker>
                          </b-col>
                          <b-col lg="4" class="my-1">
                            <p>{{initialDate}}</p>
                          </b-col>
                          <b-col lg="3" class="my-1">
                            <p><b-form-checkbox v-model="initialDateshift1" name="shift1-check-button" style="text-align:center"></b-form-checkbox>Almoço</p>
                          </b-col>
                          <b-col lg="3" class="my-1">
                            <p><b-form-checkbox v-model="initialDateshift2" name="shift2-check-button" style="text-align:center" :disabled="notSameDay"></b-form-checkbox>Jantar</p>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row style="margin: auto;width: 100%;">
                      <a @click="notSameDayChange">
                        <p style="margin-left:15px;font-size:12px; color: #DAA520;text-decoration: underline" v-if="!notSameDay">Escolher outra data final</p>
                        <p style="margin-left:15px;font-size:12px; color: #DAA520;text-decoration: underline" v-if="notSameDay">Falta apenas no dia</p>
                      </a>
                    </b-row>
                    <b-row style="margin: auto;width: 100%;" v-if="notSameDay">
                      <b-col lg="3" class="my-1">
                        <p style="text-align: left; padding: 6px 0;">Data Final</p>
                      </b-col>
                      <b-col lg="9" class="my-1">
                        <b-row class="my-1">
                          <b-col lg="2" class="my-1">
                            <b-form-datepicker id="finalDate" size="sm" button-only v-model="finalDate" :min="initialDate" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"></b-form-datepicker>
                          </b-col>
                          <b-col lg="4" class="my-1">
                            <p>{{finalDate}}</p>
                          </b-col>
                          <b-col lg="3" class="my-1">
                            <p><b-form-checkbox v-model="finalDateshift1" name="shift1-check-button" style="text-align:center" :disabled="notSameDay"></b-form-checkbox>Almoço</p>
                          </b-col>
                          <b-col lg="3" class="my-1">
                            <p><b-form-checkbox v-model="finalDateshift2" name="shift2-check-button" style="text-align:center"></b-form-checkbox>Jantar</p>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-modal>
                <b-modal :id="absenceDeleteModal.id" :title="absenceDeleteModal.title" ok-variant="danger" ok-title="Sim" cancel-title="Não" @hide="resetAbsenceDeleteModal" @ok="deleteAbsence(absenceDeleteModal.content)">
                </b-modal>
                <b-modal :id="candidateModal.id" size="xl" :title="candidateModal.title" ok-only ok-variant="default" ok-title="Fechar" @hide="resetCandidateModal">
                  <CandidatesForm :key="candidateModal.content" :candidateId="candidateModal.content" :isFormPage="false"></CandidatesForm>
                </b-modal>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import { BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BPagination, BTabs, BTab, BRow, BPopover, BTooltip, BFormTextarea, BFormSelectOption    } from 'bootstrap-vue'
import SessionDataService from "src/services/SessionDataService"
import LogAcaoDataService from "src/services/LogAcaoDataService"
import DeliveryAgentsOCDataService from "src/services/DeliveryAgentsOCDataService"
import DAScheduleDataService from "src/services/DAScheduleDataService"
import CalendarWeekDAAbsences from "src/components/Calendar/CalendarWeekDAAbsences";
import CandidatesForm from "src/pages/Candidates/CandidatesForm.vue"
export default {
  components: {
    LTable,
    Card,
    CalendarWeekDAAbsences,
    CandidatesForm,
    BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BPagination, BRow, BPopover, BTooltip, BFormSelectOption
  },
  async mounted () {
    this.getCurrentLoginInfo()
    this.getZonasByUser();
    this.timer = setInterval(() => {
        this.getDAs();
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  data() {
    return {
      fields: [
        {
          key: 'estado',
          label: 'Estado',
          sortable: true,
          tdClass: 'text-center', thClass: 'text-white'
        },
        {
          key: 'fullName',
          label: 'Nome',
          sortable: true,
          tdClass: 'text-left', thClass: 'text-white'
        },
        {
          key: 'phone',
          label: 'Telefone',
          sortable: true,
          tdClass: 'text-center', thClass: 'text-white'
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          tdClass: 'text-left', thClass: 'text-white'
        },
        {
          key: 'locationUpdatedDate',
          label: 'Data Última Localização',
          sortable: true,
          tdClass: 'text-center', thClass: 'text-white'
        },
        {
          key: 'zona',
          label: 'Zona',
          sortable: true,
          tdClass: 'text-left', thClass: 'text-white'
        }
      ],
      histFields: [
        {
          key: 'logDateTime',
          label: 'Data',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'user.userName',
          label: 'Efetuada Por',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'acao.name',
          label: 'Ação',
          tdClass: 'text-left',
          thClass: 'text-white'
        },
        {
          key: 'previous',
          label: 'Antes',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'parameters',
          label: 'Depois',
          tdClass: 'text-center',
          thClass: 'text-white'
        }
      ],
      absencesFields: [
        {
          key: 'startDate',
          label: 'De:',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'endDate',
          label: 'A:',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'durationDays',
          label: 'Duração (dias)',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'creationDate',
          label: 'Data de Registo',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'creationUsername',
          label: 'Registado Por',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'show_details',
          label: 'Ações',
          tdClass: 'text-center',
          thClass: 'text-white'
        }
      ],
      items: [],
      daData: [],
      absences: [],
      isBusyAbsences: true,
      absencesRetrieved: false,
      zonas: [],
      est: '',
      estState: '',
      isBusy: true,
      sortBy: 'estado',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: 'name',
      mapsLink:'https://maps.google.com/?q=',
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
        daapp: ''
      },
      zonaModal: {
        id: 'zona-modal',
        title: '',
        content: ''
      },
      zona: '',
      checkoutModal: {
        id: 'checkout-modal',
        title: '',
        content: ''
      },
      bloquearModal: {
        id: 'bloquear-modal',
        title: '',
        content: ''
      },
      desbloquearModal: {
        id: 'desbloquear-modal',
        title: '',
        content: ''
      },
      addDaModal: {
        id: 'addDa-modal',
        title: '',
        content: ''
      },
      mapDaModal: {
        id: 'map-modal',
        title: '',
        content: ''
      },
      daCalendarModal: {
        id: 'daCalendar-modal',
        title: '',
        content: ''
      },
      daAbsenceModal: {
        id: 'daAbsence-modal',
        title: '',
        content: ''
      },
      deleteDaModal: {
        id: 'deleteDA-modal',
        title: '',
        content: ''
      },
      scheduleAbsenceModal: {
        id: 'scheduleAbsence-modal',
        title: '',
        content: ''
      },
      initialDate: '',
      finalDate: '',
      initialDateshift1: false,
      initialDateshift2: false,
      finalDateshift1: false,
      finalDateshift2: false,
      notSameDay: false,
      absenceDeleteModal: {
        id: 'absenceDelete-modal',
        title: '',
        content: ''
      },

      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 15, 20, 100],
      currentUser:'',

      name: '',
      username: '',
      password: '',
      nationality: 'Portuguesa',
      address: '',
      zipcode1: '',
      zipcode2: '',
      local: '',
      phone: '',
      secondaryPhone: '',
      email: '',
      vehicle: '',
      docType: 'CC',
      docNum: '',
      docExpireDay:'',
      docExpireMon:'',
      docExpireYear: '',
      nif: '',
      bankAccName: '',
      iban: '',
      paymentSettings: '',
      iva: false,
      retention: '',
      startDate: '',
      finishDate: '',
      posNum:'',
      bagType: '',
      zone: '',
      daObservation: '',

      observation: '',

      submitFilesModal: {
        id: 'submitFiles-modal',
        title: '',
        docType: '',
        deliveryAgentId: ''
      },
      files: '',

      editBool: false,
      seeDocs: false,
      daFiles: [],

      currentDa: '',
      usernameAvailable: 'changed',

      seeHistorico: false,
      history: [],
      showSchedule: false,

      scheduleHeader: [
        {
          id: 0,
          header: "SEGUNDA"
        },
        {
          id: 1,
          header: "TERÇA"
        },
        {
          id: 2,
          header: "QUARTA"
        },
        {
          id: 3,
          header: "QUINTA"
        },
        {
          id: 4,
          header: "SEXTA"
        },
        {
          id: 5,
          header: "SÁBADO"
        },
        {
          id: 6,
          header: "DOMINGO"
        },
      ],
      daSchedule: null,
      daScheduleSaved: null,
      showPassword: false,

      candidateModal: {
        id: 'candidate-modal',
        title: '',
        content: ''
      },
    };
  },
  methods: {
    getCurrentLoginInfo(){
      SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => {
          (this.currentUser = response.data.result.user);
          if(this.currentUser == null){
            this.$router.push({ path: '/login', query: { returnUrl: this.$route.path } })
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    formatData(){
      this.daData = [];
      this.items.forEach(element => {
        if(!element.isAuthorized){
          this.est = 'Bloqueado';
          this.estState = 1;
        }else if(!element.isCheckedin){
          this.est = 'Offline';
          this.estState = 2;
        } else{
          this.est = 'Online';
          this.estState = 3;
        }
        var zona = this.zonas.find(z => z.latitude == element.zoneLatitude && z.longitude == element.zoneLongitude) // && z.radius == element.zoneRadius
        var nomeZona = ''
        if(zona != undefined){
          nomeZona = zona.name
        }
        var DateLocation = new Date(element.locationUpdatedDate)
        var DateCreated = new Date(element.auditCreatedDate)
        if(element.locationUpdatedDate != null){
          this.daData.push({
            element,
            id: element.daId,
            estado: this.est,
            estadoState: this.estState,
            fullName: element.fullName,
            userName: element.userName,
            phone: element.phone,
            email: element.email,
            isAuthorized: element.isAuthorized,
            isCheckedin: element.isCheckedin,
            locationUpdatedDate: String(DateLocation.getDate()).padStart(2, '0') + "/" + String(DateLocation.getMonth()+1).padStart(2, '0') + "/" + String(DateLocation.getFullYear()) + " " +String(DateLocation.getHours()).padStart(2, '0') + ":" + String(DateLocation.getMinutes()).padStart(2, '0'),
            auditCreatedDate: String(DateCreated.getDate()).padStart(2, '0') + "/" + String(DateCreated.getMonth()+1).padStart(2, '0') + "/" + String(DateCreated.getFullYear()) + " " +String(DateCreated.getHours()).padStart(2, '0') + ":" + String(DateCreated.getMinutes()).padStart(2, '0'),
            // locationUpdatedDate: (element.locationUpdatedDate).split("T")[0] + " " + (element.locationUpdatedDate).split("T")[1].split(":")[0] + ":" + (element.locationUpdatedDate).split("T")[1].split(":")[1],
            // auditCreatedDate: (element.auditCreatedDate).split("T")[0] + " " + (element.auditCreatedDate).split("T")[1].split(":")[0] + ":" + (element.auditCreatedDate).split("T")[1].split(":")[1],
            zona: nomeZona,
            locationGpsLatitude: element.locationGpsLatitude,
            locationGpsLongitude: element.locationGpsLongitude
        })
        }else{
          this.daData.push({
            element,
            id: element.daId,
            estado: this.est,
            estadoState: this.estState,
            fullName: element.fullName,
            userName: element.userName,
            phone: element.phone,
            email: element.email,
            isAuthorized: element.isAuthorized,
            isCheckedin: element.isCheckedin,
            locationUpdatedDate: "",
            auditCreatedDate: String(DateCreated.getDate()).padStart(2, '0') + "/" + String(DateCreated.getMonth()+1).padStart(2, '0') + "/" + String(DateCreated.getFullYear()) + " " +String(DateCreated.getHours()).padStart(2, '0') + ":" + String(DateCreated.getMinutes()).padStart(2, '0'),
            // auditCreatedDate: (element.auditCreatedDate).split("T")[0] + " " + (element.auditCreatedDate).split("T")[1].split(":")[0] + ":" + (element.auditCreatedDate).split("T")[1].split(":")[1],
            zona: nomeZona,
            locationGpsLatitude: "",
            locationGpsLongitude: ""
        })
        }
      })
    },
    formatDAData(element){
      var newDA = {}
      if(!element.isAuthorized){
        this.est = 'Bloqueado';
        this.estState = 1;
      }else if(!element.isCheckedin){
        this.est = 'Offline';
        this.estState = 2;
      } else{
        this.est = 'Online';
        this.estState = 3;
      }
      var zona = this.zonas.find(z => z.latitude == element.zoneLatitude && z.longitude == element.zoneLongitude ) //&& z.radius == element.zoneRadius
      var nomeZona = ''
      if(zona != undefined){
        nomeZona = zona.name
      }
      var DateLocation = new Date(element.locationUpdatedDate)
      var DateCreated = new Date(element.auditCreatedDate)
      if(element.locationUpdatedDate != null){
        newDA = {
          element,
          id: element.daId,
          estado: this.est,
          estadoState: this.estState,
          fullName: element.fullName,
          userName: element.userName,
          phone: element.phone,
          email: element.email,
          isAuthorized: element.isAuthorized,
          isCheckedin: element.isCheckedin,
          locationUpdatedDate: String(DateLocation.getDate()).padStart(2, '0') + "/" + String(DateLocation.getMonth()+1).padStart(2, '0') + "/" + String(DateLocation.getFullYear()) + " " +String(DateLocation.getHours()).padStart(2, '0') + ":" + String(DateLocation.getMinutes()).padStart(2, '0'),
          auditCreatedDate: String(DateCreated.getDate()).padStart(2, '0') + "/" + String(DateCreated.getMonth()+1).padStart(2, '0') + "/" + String(DateCreated.getFullYear()) + " " +String(DateCreated.getHours()).padStart(2, '0') + ":" + String(DateCreated.getMinutes()).padStart(2, '0'),
          // locationUpdatedDate: (element.locationUpdatedDate).split("T")[0] + " " + (element.locationUpdatedDate).split("T")[1].split(":")[0] + ":" + (element.locationUpdatedDate).split("T")[1].split(":")[1],
          // auditCreatedDate: (element.auditCreatedDate).split("T")[0] + " " + (element.auditCreatedDate).split("T")[1].split(":")[0] + ":" + (element.auditCreatedDate).split("T")[1].split(":")[1],
          zona: nomeZona,
          locationGpsLatitude: element.locationGpsLatitude,
          locationGpsLongitude: element.locationGpsLongitude
        }
      }else{
        newDA = {
          element,
          id: element.daId,
          estado: this.est,
          estadoState: this.estState,
          fullName: element.fullName,
          userName: element.userName,
          phone: element.phone,
          email: element.email,
          isAuthorized: element.isAuthorized,
          isCheckedin: element.isCheckedin,
          locationUpdatedDate: "",
          auditCreatedDate: String(DateCreated.getDate()).padStart(2, '0') + "/" + String(DateCreated.getMonth()+1).padStart(2, '0') + "/" + String(DateCreated.getFullYear()) + " " +String(DateCreated.getHours()).padStart(2, '0') + ":" + String(DateCreated.getMinutes()).padStart(2, '0'),
          // auditCreatedDate: (element.auditCreatedDate).split("T")[0] + " " + (element.auditCreatedDate).split("T")[1].split(":")[0] + ":" + (element.auditCreatedDate).split("T")[1].split(":")[1],
          zona: nomeZona,
          locationGpsLatitude: "",
          locationGpsLongitude: ""
        }
      }
      return newDA;
    },
    info(da, daapp, index, button) {
      // if(button.target.className!="nc-icon nc-pin-3"){
        this.infoModal.title = ``
        this.infoModal.daapp = daapp
        this.editBool = false

        this.seeHistorico = false
        this.gethistory(daapp.id)
        this.getDeliveryAgentSchedule(da.id)

        var expireDate = new Date(da.docExpireDate)
        var stDateCM = new Date(da.startDateCM)
        var endDateCM = new Date(da.finishDateCM)

        var item = {
          element: da,
          id: da.id,
          fullName: da.fullName,
          userName: da.userName,
          nationality: da.nationality,
          address: da.address,
          phone: da.phone,
          secondaryPhone: da.secondaryPhone,
          email: da.email,
          vehicle: da.vehicle,
          docType: da.docType,
          docNum: da.docNum,
          docExpireDate: expireDate.getDate() + "/" + (expireDate.getMonth()+1) + "/" + expireDate.getFullYear(),
          nif: da.nif,
          bankAccountName: da.bankAccountName,
          iban: da.iban ?? '',
          paymentSettingsId: da.paymentSettingsId,
          iva: da.iva,
          retention: da.retention,
          startDateCM: stDateCM.getDate() + "-" + (stDateCM.getMonth()+1) + "-" + stDateCM.getFullYear(),
          finishDateCM: da.finishDateCM != null? endDateCM.getDate() + "-" + (endDateCM.getMonth()+1) + "-" + endDateCM.getFullYear(): '',
          posNum: da.posNum,
          bagType: da.bagType,
          zone: da.deliveryAgentZone != null? da.deliveryAgentZone.name : '',
          observations: da.observations
        }
        this.infoModal.content = item

        this.name = item.fullName
        this.username = item.userName
        this.nationality = item.nationality
        this.address = item.address
        this.phone = item.phone
        this.secondaryPhone = item.secondaryPhone
        this.email = item.email
        this.vehicle = item.vehicle
        this.docType = item.docType
        this.docNum = item.docNum
        this.docExpireDay = (expireDate.getDate())
        this.docExpireMon = (expireDate.getMonth()+1)
        this.docExpireYear = expireDate.getFullYear()
        this.nif = item.nif
        this.bankAccName = item.bankAccountName
        this.iban = item.iban ?? ''
        this.paymentSettings = item.paymentSettingsId
        this.iva = item.iva
        this.retention = item.retention
        this.startDate = stDateCM.getFullYear() + "-" + (stDateCM.getMonth()+1) + "-" + stDateCM.getDate()
        this.finishDate = da.finishDateCM != null? endDateCM.getFullYear() + "-" + (endDateCM.getMonth()+1) + "-" + endDateCM.getDate(): ''
        this.posNum = item.posNum
        this.bagType = item.bagType
        this.zone = item.element.deliveryAgentZone

        this.daObservation = item.observations

        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      // }
    },
    infoAux(item, index, button) {
      this.infoModal.title = ``
      this.infoModal.content = item
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    addDa() {
      this.addDaModal.title = `Adicionar Estafeta`
      this.addDaModal.content = ''
      this.usernameAvailable = 'changed'
      // this.addDaModal.title = `Adicionar Estafeta`
      // this.addDaModal.content = ''
      this.$root.$emit('bv::show::modal', this.addDaModal.id, '')
    },
    mapDa(item, index, button) {
      this.mapsLink = "https://maps.google.com/?q="+ item.locationGpsLatitude + ',' + item.locationGpsLongitude
      this.mapDaModal.title = `Localização do Estafeta`
      this.mapDaModal.content = item
      this.$root.$emit('bv::show::modal', this.mapDaModal.id, button)
    },
    deleteDa(item, index, button) {
      this.deleteDaModal.title = 'Tem a certeza que pretende eliminar o ficheiro?'
      this.deleteDaModal.content = item
      this.$root.$emit('bv::show::modal', this.deleteDaModal.id, button)
    },
    submitFilesInfo(deliveryAgentId, docType, index, button) {
      this.submitFilesModal.title = ''
      this.submitFilesModal.docType = docType
      this.submitFilesModal.deliveryAgentId = deliveryAgentId
      this.$root.$emit('bv::show::modal', this.submitFilesModal.id, button)
    },
    zonaInfo(item, index, button) {
      this.zonaModal.title = `Definir Zona do Estafeta ${item.id}`
      this.zonaModal.content = item
      this.$root.$emit('bv::show::modal', this.zonaModal.id, button)
    },
    checkoutInfo(item, index, button) {
      this.checkoutModal.title = `Certeza que pretende forçar o checkout do estafeta ${item.userName}?`
      this.checkoutModal.content = item
      this.$root.$emit('bv::show::modal', this.checkoutModal.id, button)
    },
    bloquearInfo(item, index, button) {
      this.bloquearModal.title = `Certeza que pretende bloquear o estafeta ${item.userName}?`
      this.bloquearModal.content = item
      this.$root.$emit('bv::show::modal', this.bloquearModal.id, button)
    },
    desbloquearInfo(item, index, button) {
      this.desbloquearModal.title = `Certeza que pretende desbloquear o estafeta ${item.userName}?`
      this.desbloquearModal.content = item
      this.$root.$emit('bv::show::modal', this.desbloquearModal.id, button)
    },
    daCalendarModalInfo(item, index, button) {
      this.daCalendarModal.title = `Calendário do estafeta ${item.userName}`
      this.daCalendarModal.content = item
      this.$root.$emit('bv::show::modal', this.daCalendarModal.id, button)
    },
    daAbsenceModalInfo(item, index, button) {
      this.daAbsenceModal.title = `Férias do estafeta ${item.userName}`
      this.daAbsenceModal.content = item
      this.getDeliveryAgentAbsences(item.id)
      this.$root.$emit('bv::show::modal', this.daAbsenceModal.id, button)
    },
    resetInfoModal() {
      this.getDAs()
      this.infoModal.title = ''
      this.infoModal.content = ''
      this.infoModal.daapp = ''

      this.seeHistorico = false
      this.history = []

      this.name = ''
      this.username = ''
      this.password = ''
      this.showPassword = false
      this.nationality = 'Portuguesa'
      this.address = ''
      this.zipcode1 = ''
      this.zipcode2 = ''
      this.local = ''
      this.phone = ''
      this.secondaryPhone = ''
      this.email = ''
      this.vehicle = ''
      this.docType = 'CC'
      this.docNum = ''
      this.docExpireDay = ''
      this.docExpireMon = ''
      this.docExpireYear = ''
      this.nif = ''
      this.bankAccName = ''
      this.iban = ''
      this.paymentSettings = ''
      this.iva = false
      this.retention = ''
      this.startDate = ''
      this.finishDate = ''
      this.posNum = ''
      this.bagType = ''
      this.zone = ''
      this.daObservation = ''

      this.daFiles = []

      this.seeDocs = false
      this.editBool = false

      this.daSchedule = null
      this.daScheduleSaved = null
    },
    resetAddDaModal() {
      this.addDaModal.title = ''
      this.addDaModal.content = ''
      this.usernameAvailable = 'changed'

      this.name = ''
      this.username = ''
      this.nationality = 'Portuguesa'
      this.address = ''
      this.zipcode1 = ''
      this.zipcode2 = ''
      this.local = ''
      this.phone = ''
      this.secondaryPhone = ''
      this.email = ''
      this.vehicle = ''
      this.docType = 'CC'
      this.docNum = ''
      this.docExpireDay = ''
      this.docExpireMon = ''
      this.docExpireYear = ''
      this.nif = ''
      this.bankAccName = ''
      this.iban = ''
      this.paymentSettings = ''
      this.iva = false
      this.retention = ''
      this.startDate = ''
      this.finishDate = ''
      this.posNum = ''
      this.bagType = ''
      this.zone = ''
      this.daObservation = ''
    },
    resetSubmitFilesModal() {
      this.submitFilesModal.title = ''
      this.submitFilesModal.docType = ''
      this.submitFilesModal.deliveryAgentId = ''
      this.files = ''
    },
    resetZonaModal() {
      this.zonaModal.title = ''
      this.zonaModal.content = ''
      this.zona = ''
      this.observation = ''
    },
    resetCheckoutModal() {
      this.checkoutModal.title = ''
      this.checkoutModal.content = ''
      this.observation = ''
    },
    resetBloquearModal() {
      this.bloquearModal.title = ''
      this.bloquearModal.content = ''
      this.observation = ''
    },
    resetDesbloquearModal() {
      this.desbloquearModal.title = ''
      this.desbloquearModal.content = ''
      this.observation = ''
    },
    resetMapDaModal() {
      this.mapDaModal.title = ''
      this.mapDaModal.content = ''
    },
    resetDeleteDaModal() {
      this.deleteDaModal.title = ''
      this.deleteDaModal.content = ''
    },
    resetDACalendarModal() {
      this.daCalendarModal.title = ''
      this.daCalendarModal.content = ''
    },
    resetDAAbsenceModal() {
      this.daAbsenceModal.title = ''
      this.daAbsenceModal.content = ''
      this.absences = []
      this.isBusyAbsences = true
      this.absencesRetrieved = false
    },
    openAbsenceModal(item, index, button) {
      console.log(item)
      this.scheduleAbsenceModal.title = 'Alterar Falta de Estafeta'
      var d = dayjs(item.element.startDate)
      var a = dayjs(item.element.endDate)
      this.scheduleAbsenceModal.content = item
      this.initialDate = d.year() + "-" + (d.month()+1) + "-" + d.date()
      this.finalDate = a.year() + "-" + (a.month()+1) + "-" + a.date()
      var startHour = d.hour()
      var finalHour = a.hour()
      if(item.durationDays < 1){
        this.notSameDay = false
        if(startHour > 15){
          this.initialDateshift2 = true
        }
        if(finalHour < 17){
          this.initialDateshift1 = true
        }
        if(finalHour > 17 && startHour < 15){
          this.initialDateshift1 = true
          this.initialDateshift2 = true
        }
      }else{
        this.notSameDay = true
        if(startHour > 15){
          this.initialDateshift2 = true
        }else{
          this.initialDateshift1 = true
          this.initialDateshift2 = true
        }
        if(finalHour > 15){
          this.finalDateshift1 = true
          this.finalDateshift2 = true
        }else{
          this.finalDateshift1 = true
        }
      }
      this.$root.$emit('bv::show::modal', this.scheduleAbsenceModal.id, button)
    },
    resetScheduleAbsenceModal() {
      this.scheduleAbsenceModal.title = ''
      this.scheduleAbsenceModal.content = ''
      this.initialDate = ''
      this.finalDate = ''
      this.initialDateshift1 = false
      this.initialDateshift2 = false
      this.finalDateshift1 = false
      this.finalDateshift2 = false
      this.notSameDay = false
    },
    absenceDeleteInfo(item) {
      this.absenceDeleteModal.title = `Tem a certeza que pretende eliminar as férias do estafeta?`
      this.absenceDeleteModal.content = item
      this.$root.$emit('bv::show::modal', this.absenceDeleteModal.id, '')
    },
    resetAbsenceDeleteModal() {
      this.absenceDeleteModal.title = ''
      this.absenceDeleteModal.content = ''
    },
    getDAs(){
      DeliveryAgentsOCDataService.GetDeliveryAgentsByZones({pageNumber: this.currentPage, pageSize: this.perPage, sortOrder: this.sortBy, sortDesc: this.sortDesc, searchOn: this.filterOn, searchString: this.filter} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.items=response.data.result.items, this.isBusy=false, this.totalRows = response.data.result.totalCount , this.formatData()))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    getOCDA(daapp){
      DeliveryAgentsOCDataService.GetDeliveryAgentByDaId({daId: daapp.id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.currentDa = response.data.result, this.info(response.data.result, daapp, '', '')))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    getZonasByUser(){
      DeliveryAgentsOCDataService.GetDAZonesByUser({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.zonas=response.data.result, this.getDAs()))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    getDeliveryAgentSchedule(deliveryAgentId){
      DAScheduleDataService.GetDeliveryAgentSchedule({deliveryAgentId: deliveryAgentId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.daSchedule=response.data.result, this.copyScheduleArray()))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    getDeliveryAgentAbsences(deliveryAgentId){
      this.isBusyAbsences = true
      DAScheduleDataService.GetScheduleAbsencesByDA({deliveryAgentId: deliveryAgentId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.absences=this.formatAbsencesData(response.data.result), this.isBusyAbsences=false, this.absencesRetrieved = true))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    formatAbsencesData(absences){
      var newAbsences = []
      absences.forEach(element => {
        var sDate = new Date(element.startDate)
        var sDatehour = sDate.getHours();
        var sDateday = String(sDate.getDate()).padStart(2, '0');
        var sDatemon = String(sDate.getMonth()+1).padStart(2, '0');
        var sDateyear = String(sDate.getFullYear());
        var shift = 'Almoço'
        if(sDatehour > 15){
          shift = 'Jantar'
        }
        sDate = sDateday + "/" + sDatemon + "/" + sDateyear + " " + shift;
        var eDate = new Date(element.endDate)
        var eDatehour = eDate.getHours();
        var eDateday = String(eDate.getDate()).padStart(2, '0');
        var eDatemon = String(eDate.getMonth()+1).padStart(2, '0');
        var eDateyear = String(eDate.getFullYear());
        var shift2 = 'Almoço'
        if(eDatehour > 15){
          shift2 = 'Jantar'
        }
        eDate = eDateday + "/" + eDatemon + "/" + eDateyear + " " + shift2;
        var cDate = ''
        if(element.creationDate != null){
          cDate = new Date(element.creationDate)
          var cDatehour = String(cDate.getHours()).padStart(2, '0');
          var cDatemin = String(cDate.getMinutes()).padStart(2, '0');
          var cDateday = String(cDate.getDate()).padStart(2, '0');
          var cDatemon = String(cDate.getMonth()+1).padStart(2, '0');
          var cDateyear = String(cDate.getFullYear());
          cDate = cDateday + "/" + cDatemon + "/" + cDateyear + " " + cDatehour + ":" + cDatemin;
        }
        var dDays = Math.floor((new Date(element.endDate) - new Date(element.startDate)) / (1000*60*60*24))
        newAbsences.push({
          element: element,
          id: element.id,
          deliveryAgentId: element.deliveryAgentId,
          startDate: sDate,
          endDate: eDate,
          durationDays: dDays,
          creationDate: cDate,
          creationUsername: element.createdBy != null ? element.createdBy.userName : ''
        })
      })
      return newAbsences
    },
    copyScheduleArray(){
      this.daScheduleSaved = JSON.parse(JSON.stringify(this.daSchedule))
    },
    updateDeliveryAgentSchedule(changes, daid){
      DAScheduleDataService.UpdateDeliveryAgentSchedule(changes, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.getDeliveryAgentSchedule(daid)))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    changeDASchedule(newSchedule, schedule, daid){
      var changes = []
      schedule.forEach(s => {
          var n = newSchedule.find(d => d.id == s.id)
          if(s.state != n.state){
            changes.push(n)
          }
        })
      if(changes.length > 0){
        this.updateDeliveryAgentSchedule(changes, daid)
      }
    },
    postDefinirZona(da, zona, obs){
      var today = new Date();
      var logAcao = {
        acaoId: 15,
        userId: this.currentUser.id,
        previous:  da.zona +" - "+da.element.zoneLatitude + ", " + da.element.zoneLongitude + ", " + da.element.zoneRadius,
        parameters: zona.name +" - "+zona.latitude + ", " + zona.longitude + ", " + zona.radius,
        daId: da.id,
        daUserName: da.userName,
        logDateTime: today,
        observations: obs,
      }
      DeliveryAgentsOCDataService.DefinirZonaDeliveryAgent(logAcao, {daId: da.id, zoneId: zona.id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Zona Definida com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.info(response.data.result.deliveryAgent, this.formatDAData(response.data.result.daApp), this.infoModal.id, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postForcarCheckout(da, obs){
      var today = new Date();
      var logAcao = {
        acaoId: 16,
        userId: this.currentUser.id,
        previous: '',
        parameters: '',
        daId: da.id,
        daUserName: da.userName,
        logDateTime: today,
        observations: obs,
      }
      DeliveryAgentsOCDataService.CheckoutDeliveryAgent(logAcao, {daId: da.id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Checkout Efetuado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.info(response.data.result.deliveryAgent, this.formatDAData(response.data.result.daApp), this.infoModal.id, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postBloquearDA(da, obs){
      var today = new Date();
      var logAcao = {
        acaoId: 17,
        userId: this.currentUser.id,
        previous: '',
        parameters: '',
        daId: da.id,
        daUserName: da.userName,
        logDateTime: today,
        observations: obs,
      }
      DeliveryAgentsOCDataService.BloquearDeliveryAgent(logAcao, {daId: da.id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Estafeta Bloqueado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.info(response.data.result.deliveryAgent, this.formatDAData(response.data.result.daApp), this.infoModal.id, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postDesbloquearDA(da, obs){
      var today = new Date();
      var logAcao = {
        acaoId: 18,
        userId: this.currentUser.id,
        previous: '',
        parameters: '',
        daId: da.id,
        daUserName: da.userName,
        logDateTime: today,
        observations: obs,
      }
      DeliveryAgentsOCDataService.DesbloquearDeliveryAgent(logAcao, {daId: da.id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Estafeta Desbloqueado com sucesso</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.info(response.data.result.deliveryAgent, this.formatDAData(response.data.result.daApp), this.infoModal.id, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postAddDA(daapp, name, username, password, phone, secondaryPhone, email, paymentSettings, iva, retention, zone, nif, docNum, docType, docExpireDay, docExpireMon, docExpireYear, nationality, address, zipcode1, zipcode2, local, iban, bankAccName, vehicle, daObservation, startDate, bagType, posNum){

      var body = {
        userName: username,
        pw: password,
        fullName: name,
        phone: phone,
        secondaryPhone: secondaryPhone,
        email: email,
        paymentSettingsId: paymentSettings,
        iva: iva,
        retention: retention,
        deliveryAgentZoneId: zone != ''? zone.id: null,
        nif: nif,
        docNum: docNum,
        docType: docType,
        docExpireDate: new Date(docExpireYear,(docExpireMon-1), docExpireDay),
        nationality: nationality,
        address: address + " " + zipcode1 + "-" + zipcode2 + " " + local,
        iban: iban.replace(/\s+/g, ''),
        bankAccountName: bankAccName,
        vehicle: vehicle,
        observations: daObservation,
        startDateCM: startDate,
        bagType: bagType,
        posNum: posNum,

        daId: daapp.daId,
        daAppAccess: username,
        isAuthorized: true,
        locationGpsLatitude: 0,
        locationGpsLongitude: 0,
        locationUpdatedDate: "2021-11-26T12:00:55.491Z",
        isCheckedin: false,
        suspendedUntilDatetime: null,
        numMissedAssignments: 0

      }
      DeliveryAgentsOCDataService.AddDeliveryAgent(body, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Estafeta adicionado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.getDAs()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postEditDA(item, daapp, name, username, phone, secondaryPhone, email, paymentSettings, iva, retention, zone, nif, docNum, docType, docExpireDay, docExpireMon, docExpireYear, nationality, address, iban, bankAccName, vehicle, daObservation, startDate, finishDate, bagType, posNum){
      var body = {
        id: item.element.id,
        userName: username,
        fullName: name,
        phone: phone,
        secondaryPhone: secondaryPhone,
        email: email,
        paymentSettingsId: paymentSettings,
        iva: iva,
        retention: retention,
        deliveryAgentZoneId: zone != ''? zone.id: null,
        nif: nif,
        docNum: docNum,
        docType: docType,
        docExpireDate: new Date(docExpireYear, (docExpireMon-1), docExpireDay),
        nationality: nationality,
        address: address,
        iban: iban.replace(/\s+/g, ''),
        bankAccountName: bankAccName,
        vehicle: vehicle,
        observations: daObservation,
        startDateCM: startDate,
        finishDateCM: finishDate != ''? finishDate : null,
        bagType: bagType,
        posNum: posNum,

        daId: item.element.daId,
        daAppAccess: item.element.username,
        isAuthorized: item.element.isAuthorized,
        locationGpsLatitude: item.element.locationGpsLatitude,
        locationGpsLongitude: item.element.locationGpsLongitude,
        locationUpdatedDate: item.element.locationUpdatedDate,
        isCheckedin: item.element.isCheckedin,
        suspendedUntilDatetime: item.element.suspendedUntilDatetime,
        numMissedAssignments: item.element.numMissedAssignments

      }
      DeliveryAgentsOCDataService.EditDeliveryAgent(body, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Estafeta editado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.info(response.data.result.deliveryAgent, this.formatDAData(response.data.result.daApp), this.infoModal.id, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    putAbsence(absence, estafeta, idate, ishift1, ishift2, fdate, fshift1, fshift2, notSameDay){
      var daId = estafeta
      // var daId = ''
      // var da = this.dasByZone.filter(d => d.userName == estafeta)[0];
      // if(da != null){
      //   daId = da.id
      // }
      var startHour = 1
      var finalHour = 22
      var stDate = idate
      var fiDate = idate
      if(!notSameDay){
        if(!ishift1){
          startHour = 17
        }
        if(!ishift2){
          finalHour = 15
        }
      }else{
        if(!ishift1){
          startHour = 17
        }
        if(!fshift2){
          finalHour = 15
        }
        fiDate = fdate
      }
      var s = dayjs(stDate)
      var f = dayjs(fiDate)
      var startDate = s.year() + "/" + (s.month()+1) + "/" + s.date() + " " + startHour + ":00"
      var endDate = f.year() + "/" + (f.month()+1) + "/" + f.date() + " " + finalHour + ":00"
      absence.startDate = startDate
      absence.endDate = endDate
      absence.updateDate = new Date()
      absence.updatedById = this.currentUser.id
      DAScheduleDataService.PutDeliveryAgentScheduleChange(absence, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Falta editada com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.getDeliveryAgentAbsences(daId)
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    deleteAbsence(absence){
      DAScheduleDataService.DeleteDeliveryAgentScheduleChange({id: absence.id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Falta removida com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.getDeliveryAgentAbsences(absence.deliveryAgentId)
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    notSameDayChange(){
      this.notSameDay = !this.notSameDay
      if(this.notSameDay){
        this.initialDateshift2 = true
        this.finalDateshift1 = true
        var date = new Date(this.initialDate)
        var d = new Date(date.setDate(date.getDate()+1))
        this.finalDate = d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate()
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.getDAs();
    },

    handlePageSizeChange(event) {
      this.pageSize = event;
      this.currentPage = 1;
      this.getDAs();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.getDAs();
    },
    pesquisar(){
      this.isBusy = true
      this.currentPage = 1;
      this.getDAs();
    },

    handleFileUploads( event ){
      this.files = event.target.files;
    },
    submitFiles(deliveryAgentId, docType){
      var today = new Date();
      let formData = new FormData();

      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];

        formData.append('files[' + i + ']', file);
      }

      formData.append('deliveryAgentId', deliveryAgentId);
      formData.append('docType', docType);
      formData.append('uploadDate', today.toISOString());
      formData.append('userId', this.currentUser.id);

      DeliveryAgentsOCDataService.UploadFiles(formData, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(setTimeout(() => {  this.$notifications.notify(
          {
            message: `<span>Documento Adicionado com sucesso</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }),
          this.getDAFiles(deliveryAgentId) }, 1000))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique o tamanho do documento!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)

    },

    downloadFiles(id, filename){
      DeliveryAgentsOCDataService.DownloadFile({id: id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => {
              const blob = new Blob([response.data], { type: response.data.type })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = filename
              link.click()
              URL.revokeObjectURL(link.href)
            })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)

    },

    editDaModal(){
      this.editBool = true
      this.seeDocs = false
    },
    cancelEditDaModal(item){
      this.editBool = false

      this.name = item.fullName
      this.username = item.userName
      this.nationality = item.nationality
      this.address = item.address
      this.phone = item.phone
      this.secondaryPhone = item.secondaryPhone
      this.email = item.email
      this.vehicle = item.vehicle
      this.docType = item.docType
      this.docNum = item.docNum

      var expireDate = new Date(item.element.docExpireDate)
      var stDate = new Date(item.element.startDateCM)
      var endDate = new Date(item.element.finishDateCM)

      this.docExpireDay = (expireDate.getDate())
      this.docExpireMon = (expireDate.getMonth()+1)
      this.docExpireYear = expireDate.getFullYear()
      this.nif = item.nif
      this.bankAccName = item.bankAccountName
      this.iban = item.iban
      this.paymentSettings = item.paymentSettingsId
      this.iva = item.iva
      this.retention = item.retention
      this.startDate = stDate.getFullYear() + "-" + (stDate.getMonth()+1) + "-" + stDate.getDate()
      this.finishDate = item.finishDateCM != ''? endDate.getFullYear() + "-" + (endDate.getMonth()+1) + "-" + endDate.getDate() : ''
      this.posNum = item.posNum
      this.bagType = item.bagType
      this.zone = item.element.deliveryAgentZone
      this.daObservation = item.observations
    },
    getDAFiles(deliveryAgentId){
      DeliveryAgentsOCDataService.GetDeliveryAgentDocs({deliveryAgentId: deliveryAgentId},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.daFiles=response.data.result, this.seeDocs = true))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    deleteDAFile(id, deliveryAgentId){
      DeliveryAgentsOCDataService.DeleteDeliveryAgentDoc({id: id},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( setTimeout(() => {  this.$notifications.notify(
          {
            message: `<span>Documento Eliminado com sucesso</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }),
          this.getDAFiles(deliveryAgentId) }, 1000)))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique o tamanho do documento!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    validateUsername(username){
      if(username == ''){
        this.usernameAvailable = false
        return;
      }
      DeliveryAgentsOCDataService.ValidateUsername({username: username} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.usernameAvailable = response.data.result, this.$notifications.notify(
          {
            message: `<span>Disponibilidade do username verificada!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          })
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    usernameChanged(){
      this.usernameAvailable = 'changed'
    },
    formatHistory(hist){
      var newHist = []
      hist.forEach(element => {
        var date = new Date(element.logDateTime)
        var hour = String(date.getHours()).padStart(2, '0');
        var min = String(date.getMinutes()).padStart(2, '0');
        var day = String(date.getDate()).padStart(2, '0');
        var mon = String(date.getMonth()+1).padStart(2, '0');
        var year = String(date.getFullYear());
        date = day + "/" + mon + "/" + year + " " + hour + ":" + min;
        newHist.push({
          acao: element.acao,
          user: element.user,
          previous: element.previous,
          parameters: element.parameters,
          poNum: element.poNum,
          logDateTime: date,
          observations: element.observations,
          _showDetails: true
        })
      })
      return newHist
    },
    seeHistoricoInfo(id){
      if(!this.seeHistorico){
        this.gethistory(id)
      }
      this.seeHistorico = !this.seeHistorico
    },
    gethistory(daId){
      LogAcaoDataService.GetLogEditsByDaId({daId: daId},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.history = this.formatHistory(response.data.result)))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },

    seeCandidateModal(candidateId) {
      this.candidateModal.title = ''
      this.candidateModal.content = candidateId
      this.$root.$emit('bv::show::modal', this.candidateModal.id, '')
    },
    resetCandidateModal() {
      this.candidateModal.title = ''
      this.candidateModal.content = ''
    },

    getDateWithHoursFormated(date){
      var dt = new Date(date)
      var str = dt.getDate().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + "/" +
                (dt.getMonth()+1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + "/" + dt.getFullYear() + " " +
                dt.getHours().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + ":" +
                dt.getMinutes().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                })
      return str
    }
  }
};
</script>
<style>

  .docsView{
    color: black;
  }
  .docsView:hover{
    color: #DAA520;
    cursor: pointer;
  }

  .absentIcon{
    color: black;
    display: inline !important;

  }
  .absentIcon:hover{
    color: #DAA520;

    cursor: pointer;
  }

  .addFileIcon{
    font-size: 1.0em;
    margin: 5px;
    margin-right: 10px;
    color:lightgreen;
  }
  .addFileIcon:hover{
    color:green;
    cursor: pointer;
  }

  .removeFileIcon{
    font-size: 1.0em;
    margin: 5px;
    color:lightcoral;
  }
  .removeFileIcon:hover{
    color:red;
    cursor: pointer;
  }

</style>

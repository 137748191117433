
import http from "../http-common-oc";

class LogCandidateStatusOCDataService {
  GetLogCandidateStatusByCandidateId(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogCandidateStatus/GetLogCandidateStatusByCandidateId", config);
  };
}

export default new LogCandidateStatusOCDataService();

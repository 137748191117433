<template>
  <div class="content" style="padding-top: 0px; background-color: #f7f7f7;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <h4 class="card-title">Zonas Estafeta</h4>
            </template>
            <div class="table-responsive">
              <div>
                <b-col lg="15" class="my-1">
                  <!-- <b-form-group
                    label="Filtro"
                    label-for="filter-input"
                    label-cols-sm="1"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Escreva para procurar"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group> -->
                </b-col>
                <b-table  class="tables"
                  responsive
                  striped
                  bordered
                  outlined
                  hover
                  small
                  thead-class="cm-bg"
                  sort-icon-left
                  no-border-collapse :items="zonaData" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                  :busy="isBusy" :filter="filter" :filter-included-fields="filterOn" :current-page="currentPage" :per-page="perPage"
                  @filtered="onFiltered" @row-clicked="(item, index, event) => editZona(item, index, event)">
                  <template #table-busy>
                    <div class="text-center text-warning my-1">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>A carregar...</strong>
                    </div>
                  </template>
                </b-table>
                <b-row>
                  <b-col lg="2" class="my-1">
                    <b-button size="sm" variant="success" @click="addZona('', '', $event.target)">Adicionar</b-button>
                  </b-col>
                  <b-col lg="6" class="my-1">
                    <b-form-group
                      label="Por Página"
                      label-for="per-page-select"
                      label-cols-lg="8"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        align="right"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col class="my-1">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
                <b-modal :id="addZonaModal.id" :title="addZonaModal.title"  ok-variant="success" ok-title="Adicionar" cancel-title="Cancelar" @hide="resetAddZonaModal" @ok="createZona()">
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Nome</p>
                    </b-col>
                    <b-col lg="9" class="my-1">
                      <b-form-input
                        id="nome-input"
                        v-model="nome"
                        type="text"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Email</p>
                    </b-col>
                    <b-col lg="9" class="my-1">
                      <b-form-input
                        id="email-input"
                        v-model="email"
                        type="text"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Latitude</p>
                    </b-col>
                    <b-col lg="9" class="my-1">
                      <b-form-input
                        id="latitude-input"
                        v-model="latitude"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Longitude</p>
                    </b-col>
                    <b-col lg="9" class="my-1">
                      <b-form-input
                        id="longitude-input"
                        v-model="longitude"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Raio</p>
                    </b-col>
                    <b-col lg="9" class="my-1">
                      <b-form-input
                        id="raio-input"
                        v-model="raio"
                        type="number"
                        step="0.01"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Descrição</p>
                    </b-col>
                    <b-col lg="9" class="my-1">
                      <b-form-textarea
                        id="descricao-text"
                        v-model="descricao"
                        placeholder="Escreva aqui.."
                      ></b-form-textarea>
                    </b-col>
                  </b-row>
                </b-modal>
                <b-modal :id="editZonaModal.id" :title="editZonaModal.title"  ok-variant="warning" ok-title="Editar" cancel-title="Cancelar" @cancel="deleteZonaInfo(editZonaModal.content, editZonaModal.content.id, $event.target)" @hide="resetEditZonaModal" @ok="updateZona(editZonaModal.content.id)">
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Nome</p>
                    </b-col>
                    <b-col lg="9" class="my-1">
                      <b-form-input
                        id="nome-input"
                        v-model="nome"
                        type="text"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Email</p>
                    </b-col>
                    <b-col lg="9" class="my-1">
                      <b-form-input
                        id="email-input"
                        v-model="email"
                        type="text"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Latitude</p>
                    </b-col>
                    <b-col lg="9" class="my-1">
                      <b-form-input
                        id="latitude-input"
                        v-model="latitude"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Longitude</p>
                    </b-col>
                    <b-col lg="9" class="my-1">
                      <b-form-input
                        id="longitude-input"
                        v-model="longitude"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Raio</p>
                    </b-col>
                    <b-col lg="9" class="my-1">
                      <b-form-input
                        id="raio-input"
                        v-model="raio"
                        type="number"
                        step="0.01"
                        min="0"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Descrição</p>
                    </b-col>
                    <b-col lg="9" class="my-1">
                      <b-form-textarea
                        id="descricao-text"
                        v-model="descricao"
                        placeholder="Escreva aqui.."
                      ></b-form-textarea>
                    </b-col>
                  </b-row>
                  <template #modal-footer="{ cancel, hide, ok }">
                    <b-button size="md" variant="secondary" @click="hide()">
                      Cancelar
                    </b-button>
                    <b-button size="md" variant="danger" @click="cancel()">
                      Eliminar
                    </b-button>
                    <b-button size="md" variant="warning" @click="ok()">
                      Editar
                    </b-button>
                  </template>
                </b-modal>
                <b-modal :id="deleteZonaModal.id" :title="deleteZonaModal.title"  ok-variant="danger" ok-title="Eliminar" cancel-title="Cancelar" @hide="resetDeleteZonaModal" @ok="deleteZona(deleteZonaModal.content.id)">
                </b-modal>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import { BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BPagination, BRow, BPopover, BTooltip, BFormSelectOption, BFormTextarea   } from 'bootstrap-vue'
import ZonaEstafetaDataService from "src/services/ZonaEstafetaDataService"
import SessionDataService from "src/services/SessionDataService"
import DeliveryAgentsOCDataService from "src/services/DeliveryAgentsOCDataService"
export default {
  components: {
    LTable,
    Card,
    BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BPagination, BRow, BPopover, BTooltip, BFormSelectOption, BFormTextarea
  },
  mounted () {
    this.getCurrentLoginInfo()
    this.getZonas();
  },

  data() {
    return {
      fields: [
        {
            key: 'nome',
            label: 'Nome',
            sortable: true,
            tdClass: 'text-left', thClass: 'text-white'
          },
        {
            key: 'email',
            label: 'Email',
            sortable: true,
            tdClass: 'text-left', thClass: 'text-white'
          },
          {
            key: 'latitude',
            label: 'Latitude',
            sortable: true,
            tdClass: 'text-center', thClass: 'text-white'
          },
          {
            key: 'longitude',
            label: 'Longitude',
            sortable: true,
            tdClass: 'text-center', thClass: 'text-white'
          },
          {
            key: 'raio',
            label: 'Raio',
            sortable: true,
            tdClass: 'text-center', thClass: 'text-white'
          },
          {
            key: 'dataCriacao',
            label: 'Data Criação',
            sortable: true,
            tdClass: 'text-center', thClass: 'text-white'
          },
          {
            key: 'descricao',
            label: 'Descrição',
            sortable: true,
            tdClass: 'text-left', thClass: 'text-white'
          },
        ],
      items: [
      ],
      zonaData:[],
      isBusy: true,
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      addZonaModal: {
        id: 'add-zona-modal',
        title: '',
        content: ''
      },
      editZonaModal: {
        id: 'edit-zona-modal',
        title: '',
        content: ''
      },
      deleteZonaModal: {
        id: 'delete-zona-modal',
        title: '',
        content: ''
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 15, 20, 100],
      nome: '',
      email: '',
      latitude: '',
      longitude: '',
      raio: '',
      descricao: '',
      currentUser: ''
    };
  },
  methods: {
    getCurrentLoginInfo(){
      SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => {
          (this.currentUser = response.data.result.user);
          if(this.currentUser == null){
            this.$router.push({ path: '/login', query: { returnUrl: this.$route.path } })
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    formatData(){
      this.zonaData = [];
      this.items.forEach(element => {
        var DateCreated = new Date(element.creationDate)
        this.zonaData.push({
          id: element.id,
          nome: element.name,
          email: element.email,
          latitude: element.latitude,
          longitude: element.longitude,
          raio: element.radius,
          dataCriacao: String(DateCreated.getDate()).padStart(2, '0') + "/" + String(DateCreated.getMonth()+1).padStart(2, '0') + "/" + String(DateCreated.getFullYear()) + " " +String(DateCreated.getHours()).padStart(2, '0') + ":" + String(DateCreated.getMinutes()).padStart(2, '0'),
          descricao: element.description
        })
      })
    },
    getZonas(){
      DeliveryAgentsOCDataService.GetDAZonesByUser({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.items=response.data.result,this.isBusy=false, this.totalRows = response.data.result.length , this.formatData(), this.isBusy = false ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    createZona(){
      var today = new Date()
      var zona = {
        name: this.nome,
        email: this.email,
        latitude: this.latitude,
        longitude: this.longitude,
        radius: this.raio,
        description: this.descricao,
        creationDate: today
      }
      ZonaEstafetaDataService.CreateZonaEstafeta(zona, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Zona Criada com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }),this.getZonas()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    updateZona(idZona){
      var today = new Date()
      var zona = {
        name: this.nome,
        email: this.email,
        latitude: this.latitude,
        longitude: this.longitude,
        radius: this.raio,
        description: this.descricao,
        creationDate: today,
        id: idZona
      }
      ZonaEstafetaDataService.UpdateZonaEstafeta(zona, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Zona Atualizada com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }),this.getZonas()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    deleteZona(Id){
      ZonaEstafetaDataService.DeleteZonaEstafeta( {Id: Id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Zona Eliminada com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }),this.getZonas()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    addZona(item, index, button) {
      this.addZonaModal.title = 'Adicionar Zona'
      this.addZonaModal.content = ''
      this.$root.$emit('bv::show::modal', this.addZonaModal.id, button)
    },
    resetAddZonaModal() {
      this.addZonaModal.title = ''
      this.addZonaModal.content = ''
      this.nome = ''
      this.email = ''
      this.latitude = ''
      this.longitude = ''
      this.raio = ''
      this.descricao = ''
    },
    editZona(item, index, button) {
      this.editZonaModal.title = 'Editar Zona'
      this.editZonaModal.content = item
      this.nome = item.nome
      this.email = item.email
      this.latitude = item.latitude
      this.longitude = item.longitude
      this.raio = item.raio
      this.descricao = item.descricao
      this.$root.$emit('bv::show::modal', this.editZonaModal.id, button)
    },
    resetEditZonaModal() {
      this.editZonaModal.title = ''
      this.editZonaModal.content = ''
      this.nome = ''
      this.email = ''
      this.latitude = ''
      this.longitude = ''
      this.raio = ''
      this.descricao = ''
    },
    deleteZonaInfo(item, index, button) {
      this.deleteZonaModal.title = `Certeza que pretende eliminar a zona ${item.nome}?`
      this.deleteZonaModal.content = item
      this.$root.$emit('bv::show::modal', this.deleteZonaModal.id, button)
    },
    resetDeleteZonaModal() {
      this.deleteZonaModal.title = ''
      this.deleteZonaModal.content = ''
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
};
</script>
<style>
</style>

import http from "../http-common-oc";

class RestauranteAssignedToZoneDataService {
  GetSuppliersFromZone(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/SupplierAssignmentToZone/GetAllSupplierFromZoneInList", config);
  }
  GetAllActiveSuppliers(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/SupplierAssignmentToZone/GetAllActiveSuppliers", config);
  }
  GetSupplierNameBySupplierId(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/SupplierAssignmentToZone/GetSupplierNameBySupplierId", config);
  }
  GiveTimeToPartner(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/SupplierAssignmentToZone/GiveTimeToPartner", {}, config);
  }
  ChangePartnerState(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/SupplierAssignmentToZone/ChangeSupplierState", {}, config);
  }
}

export default new RestauranteAssignedToZoneDataService();

import http from "../http-common-oc";

class StoreFrontDataService {
  GetPartnerContacts(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/StoreFrontConnection/GetPartnerContacts", {}, config);
  }
}

export default new StoreFrontDataService();

<template>
  <div>
    <gmap-map
      id="map"
      :center="location"
      :zoom="13"
      :options="options"
    >
      <gmap-marker icon="img/client_pin_27c2af.svg" :position="location">
      </gmap-marker>
    </gmap-map>
  </div>
</template>
<script>
  export default {
    props: {
      location: Object
    },
    data () {
      return {
        options: {
          styles: [
            {'featureType': 'poi', 'stylers': [{'visibility': 'off'}]}
          ]
        },
      }
    },
    methods: {

    }
  }
</script>
<style>
  #map {
    max-height: calc(100vh - 123px);
    min-height: 300px;
  }
</style>

import http from "../http-common-oc";

class TransactionDataService {
  GetAllTransactionsByOrderId(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Transaction/GetAllByOrderId", config);
  }
  GetPhonesByPayments(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Transaction/GetVirtualAccountIdsByCustomerPhone", config);
  }
  GetPaymentsValueByPO(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Transaction/GetTotalPayedByOrderId", config);
  }
}

export default new TransactionDataService();

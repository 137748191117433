<template>
  <div class="table-responsive">
    <div> <!-- v-if="this.candidateStatusHistoryData.length > 0" -->
      <b-table  class="tables"
        responsive
        striped
        bordered
        outlined
        hover
        small
        thead-class="cm-bg"
        sort-icon-left
        no-border-collapse :items="this.candidateStatusHistoryData" :fields="this.fields" :busy="this.isBusy">
        <template #table-busy>
          <div class="text-center text-warning my-1">
            <b-spinner class="align-middle"></b-spinner>
            <strong>A carregar...</strong>
          </div>
        </template>
      </b-table>
    </div>
    <div>
      Ainda não existem interações.
    </div>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import { BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BPagination, BRow, BFormSelectOption    } from 'bootstrap-vue'
import SessionDataService from "src/services/SessionDataService"
import LogCandidateStatusOCDataService from "src/services/LogCandidateStatusOCDataService"
export default {
  components: {
    Card,
    BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BPagination, BRow, BFormSelectOption
  },
  name: "LogCandidateStatusListingView",
  props: {
    candidateId: String
  },
  mounted () {
    this.getCurrentLoginInfo();
    if(candidateId != '' && candidateId != undefined && candidateId != null){
      this.getCandidateStatusHistory();
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'logDateTime',
          label: 'Data',
          sortable: true,
          tdClass: 'text-left', thClass: 'text-white'
        },
        {
          key: 'status',
          label: 'Estado',
          sortable: true,
          tdClass: 'text-center', thClass: 'text-white'
        },
        {
          key: 'observations',
          label: 'Observações',
          sortable: true,
          tdClass: 'text-left', thClass: 'text-white'
        },
        {
          key: 'user.userName',
          label: 'Efetuada Por',
          sortable: true,
          tdClass: 'text-center', thClass: 'text-white'
        },
      ],
      items: [],
      candidateStatusHistoryData: [],
      isBusy: true,
      currentUser:'',
    };
  },
  methods: {
    getCurrentLoginInfo(){
      SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => {
          (this.currentUser = response.data.result.user);
          if(this.currentUser == null){
            this.$router.push({ path: '/login', query: { returnUrl: this.$route.path } })
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    formatData(){
      this.candidateStatusHistoryData = [];
      this.items.forEach(element => {
        this.candidateStatusHistoryData.push({
          element,
          id: element.id,
          status: element.status,
          observations: element.observations,
          user: element.user,
          logDateTime: this.getDateWithHoursFormated(element.logDateTime),
        })
      })
    },
    getCandidateStatusHistory(){
      LogCandidateStatusOCDataService.GetLogCandidateStatusByCandidateId({candidateId: this.candidateId} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.items=response.data.result.items, this.isBusy=false , this.formatData()))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },

    getDateWithHoursFormated(date){
      var dt = new Date(date)
      var str =
        dt.getDate().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + "/" +
        (dt.getMonth()+1).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + "/" + dt.getFullYear() + " " +
        dt.getHours().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + ":" +
        dt.getMinutes().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
      return str
    }
  }
};
</script>
<style>
</style>

import http from "../http-common-oc";

class ExtratoDataService {
  GetExtratoByPoNum(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/ExtratoLine/GetExtratoByPoNum", config);
  }
}

export default new ExtratoDataService();

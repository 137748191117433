
import http from "../http-common-oc";

class DeliveryAgentsOCDataService {
  UploadFiles(payload, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/DeliveryAgentDocs/UploadFiles", payload, config);
  };
  DownloadFile(params, headers) {
    let config = {
      headers: headers,
      params: params,
      responseType: 'arraybuffer'
    }
    return http.post("/services/app/DeliveryAgentDocs/DownloadFile", {}, config);
  };
  DeleteDeliveryAgentDoc(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.delete("/services/app/DeliveryAgentDocs/Delete", config);
  };
  AddDeliveryAgent(payload, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/DeliveryAgent/CreateDeliveryAgent", payload, config);
  };
  EditDeliveryAgent(payload, headers) {
    let config = {
      headers: headers
    }
    return http.put("/services/app/DeliveryAgent/UpdateDeliveryAgent", payload, config);
  };
  GetDeliveryAgents(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgent/GetAll", config);
  };
  GetDeliveryAgentByDaId(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgent/GetDeliveryAgentByDaId", config);
  };
  GetDeliveryAgentDocs(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgentDocs/GetFilesFromDeliveryAgent", config);
  };
  BloquearDeliveryAgent(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/DeliveryAgent/BloquearDA", logAcao, config);
  };
  DesbloquearDeliveryAgent(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/DeliveryAgent/DesbloquearDA", logAcao, config);
  };
  CheckoutDeliveryAgent(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/DeliveryAgent/CheckoutDA", logAcao, config);
  };
  StandByDeliveryAgent(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/DeliveryAgent/StandByDA", logAcao, config);
  };
  CancelarStandByDeliveryAgent(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/DeliveryAgent/CancelStandByDA", logAcao, config);
  };
  DefinirZonaDeliveryAgent(logAcao, params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/DeliveryAgent/DefinirZonaDA", logAcao, config);
  };

  GetDeliveryAgentsByZones(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetAllDAsByCurrentUserZonesPaginated", config);
  };
  GetDeliveryAgentsByZone(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgent/GetAllDAsByZone", config);
  };
  GetDAZonesByUser(headers) {
    let config = {
      headers: headers
    }
    return http.get("/services/app/UserAssignmentToZones/GetAllDAZonesByCurrentUserZones", config);
  };
  GetZonesByUser(headers) {
    let config = {
      headers: headers
    }
    return http.get("/services/app/UserAssignmentToZones/GetCurrentUserSupplierZones", config);
  };
  GetAllDAsOnlineBySupplierZone(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetAllDAsOnlineBySupplierZone", config);
  };
  ValidateUsername(params,headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/DeliveryAgent/GetUsernameAvailability", config );
  }
  GetAllDAsOnlineFromShiftBySupplierZone(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/UserAssignmentToZones/GetAllDAsOnlineFromShiftBySupplierZone", config);
  };
}

export default new DeliveryAgentsOCDataService();

<template>
  <div style="border: 1px solid black; border-radius: 5px;">
    <line-chart :labels="labels" :datasets="datasets" v-if="labels.length > 0 && datasets.length > 0"></line-chart>
  </div>
</template>
<script>
import LineChart from 'src/components/charts/LineChart'
import GetDatesBetween2Dates from 'src/components/Dates/GetDatesBetween2Dates'
import PurchaseOrdersOCDataService from "src/services/PurchaseOrdersOCDataService"
export default {
  components: {
    LineChart
  },
  props:{
    zoneId: {
      type: String,
      required: true,
      default: ''
    },
    startDate: {
      type: String,
      required: true,
      default: ''
    },
    endDate: {
      type: String,
      required: true,
      default: ''
    },
    shift: {
      type: Number,
      required: true,
      default: 0
    },
    startDateTime: {
      type: String,
      required: true,
      default: ''
    },
    endDateTime: {
      type: String,
      required: true,
      default: ''
    },
  },
  mounted(){
    this.labels = GetDatesBetween2Dates.getDates(new Date(this.startDate), new Date(this.endDate))
    this.getDATotalPayedByZone()
  },
  data(){
    return {
      labels: [],
      datasets: [],
    }
  },
  methods: {
    getDATotalPayedByZone(){
      PurchaseOrdersOCDataService.GetDATotalPayedByZone({zoneId: this.zoneId, startDate: this.startDate, endDate: this.endDate, shift: this.shift, startDateTimeHour: this.startDateTime != ''? this.startDateTime:null, endDateTimeHour: this.endDateTime != ''? this.endDateTime:null} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.datasets = this.formatData(response.data.result)))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    formatData(das){
      var dataset = []
      das.forEach(da => {
        var color = '#'+(Math.random()*0xFFFFFF<<0).toString(16);
        var newDa = {
          label: da.daName,
          data: [],
          fill: false,
          borderColor: color,
          backgroundColor: color,
          borderWidth: 1,
          tension: 0
        }
        this.labels.forEach(day => {
          var value = da.payedDays.findIndex(pd => pd.date == day);
          if(value >= 0){
            newDa.data.push(da.payedDays[value].totalPayed)
          }else{
            newDa.data.push(0)
          }
        })
        dataset.push(newDa);
      })
      return dataset
    }
  }
}
</script>

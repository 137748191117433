import http from "../http-common-oc";

class UserDataService {
  GetAllUsers(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/User/GetAll", config);
  }
  GetCurrentUserInfo(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/User/Get",  config );
  }
  GetCurrentUserPermissions(headers) {
    let config = {
      headers: headers
    }
    return http.get("/services/app/User/GetUserPermissions",  config );
  }
  CreateUser(payload,headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/User/Create", payload, config );
  }
  CreateUserWithSameEmail(payload,headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/User/CreateUserWithSameEmail", payload, config );
  }
  ActivateUser(payload,headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/User/Activate", payload, config );
  }
  DeactivateUser(payload, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/User/DeActivate", payload, config );
  }
  ValidateUsername(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/User/GetUsernameAvailability", config );
  }
  ValidateEmail(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/User/GetEmailAvailability", config );
  }
  ChangePassword(payload, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/User/ChangePassword", payload, config );
  }
}

export default new UserDataService();

<template>
  <div class="content" style="padding-top: 0px; background-color: #f7f7f7;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <h4 class="card-title" style="font-size: 30px;margin-bottom: 30px">Administração de Utilizadores</h4>
            </template>
            <div class="table-responsive">
              <b-row>
                <b-col lg="8" class="my-1">
                  <h4 class="card-title">Utilizadores</h4>
                </b-col>
                <b-col lg="4" class="my-1">
                  <b-form-group
                    label-for="filter-input"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Escreva para procurar"
                        @keyup.enter.native="pesquisar"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button @click="pesquisar()">Pesquisar</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table  class="tables"
                responsive
                striped
                bordered
                outlined
                hover
                small
                thead-class="cm-bg"
                sort-icon-left
                no-border-collapse
                :items="itemsUsers"
                :fields="fieldsUsers"
                :busy="isBusyUsers"
                @sort-changed="sortingChanged" no-local-sorting>
                 <!-- @row-clicked="(item, index, event) => userInfo(item, index, event)" -->
                <template #table-busy>
                  <div class="text-center text-warning my-1">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>A carregar...</strong>
                  </div>
                </template>
                <template #cell(roleNames)="row">
                  <b-row style="width: fit-content">
                    <b-col v-for="role in row.item.user.roleNames" :key="role" class="pillStyle" style="text-align:center; margin-bottom:0;margin-top:0; width: 175px">{{role}}<i class="nc-icon nc-simple-remove" style="color: red;  font-size: 1em;  font-weight: bold; float:right" title="Remover" @click="removeRole(role,row.item.user, '', '')"></i></b-col>
                    <i class="nc-icon nc-simple-add" style="color: green;  font-size: 1.5em;  font-weight: bold;" title="Adicionar Role" @click="addRoleToUser(row.item.user, '', '')"></i>
                  </b-row>
                </template>
                <template #cell(zones)="row">
                  <b-row style="width: fit-content">
                    <b-col v-for="zone in row.item.zones" :key="zone.id" class="pillStyle" style="text-align:center; margin-bottom:0;margin-top:0; width: 175px">{{zone.name}}<i class="nc-icon nc-simple-remove" style="color: red;  font-size: 1em;  font-weight: bold; float:right" title="Remover" @click="removeZone(zone, row.item.user, '', '')"></i></b-col>
                    <i class="nc-icon nc-simple-add" style="color: green;  font-size: 1.5em;  font-weight: bold;" title="Adicionar Zona" @click="addZone(row.item.user, '', '')"></i>
                  </b-row>
                </template>
                <template #cell(isActive)="row">
                  <div v-if="row.item.user.isActive">Sim<i class="fa fa-ban" style="color: red;  font-size: 1.5em;  font-weight: bold;margin-left:15px" title="Desativar Utilizador" @click="deActivateUser(row.item.user.id)"></i></div>
                  <div v-if="!row.item.user.isActive">Não<i class="nc-icon nc-check-2" style="color: lightgreen;  font-size: 1.5em;  font-weight: bold;margin-left:15px" title="Ativar Utilizador" @click="activateUser(row.item.user.id)"></i></div>
                </template>
              </b-table>
              <b-row>
                <b-col class="my-1">
                  <b-button size="sm" variant="warning" @click="showPermissions()" v-if="!showPermi">Ver Permissões</b-button>
                  <b-button size="sm" variant="success" @click="addUserInfo('','','')">Adicionar Utilizador</b-button>
                </b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label-for="per-page-select"
                    label-cols-lg="11"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="handlePageSizeChange($event)"
                      align="right"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col class="my-1">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="handlePageChange"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </b-col>
              </b-row>
              <!-- <b-button size="sm" variant="info" @click="partnerStateInfo('','','')">Estado do Parceiro</b-button> -->
            </div>
            <card v-if="showPermi" style="margin-top: 15px">
              <b-row>
                <b-col lg="2" style="text-align:center">
                  <p>Permissões</p>
                </b-col>
                <b-col v-for="role in roles" :key="role.name" style="text-align:center">
                  <p>{{role.name}}</p>
                </b-col>
              </b-row>
              <b-row v-for="permission in permissions" :key="permission" class="permissionLine">
                <b-col lg="2">
                  <label>{{permission}}</label>
                </b-col>
                <b-col v-for="role in roles" :key="role.id" style="text-align:center">
                  <input :id="permission" :value="permission" name="permission" type="checkbox" v-model="role.grantedPermissions">
                </b-col>
              </b-row>
              <b-button size="sm" variant="warning" @click="addRoleInfo('','','')">Adicionar Role</b-button>
              <b-button size="sm" variant="success" @click="UpdateRolesPermissions(roles)" style="float:right">Guardar Alterações</b-button>
              <b-button size="sm" variant="secondary" @click="hidePermissions()" style="float:right">Esconder Permissões</b-button>
            </card>
            <b-modal :id="userInfoModal.id" :title="userInfoModal.title" ok-only ok-variant="secondary" ok-title="Cancelar" @hide="resetUserInfoModal">
              <b-button size="sm" variant="warning" @click="editZones(userInfoModal.content, '', '')">Alterar Zonas</b-button>
              <b-button size="sm" variant="warning" @click="editRoles(userInfoModal.content, '', '')">Alterar Roles</b-button>
            </b-modal>
            <b-modal :id="addZoneModal.id" :title="addZoneModal.title" ok-variant="success" ok-title="Adicionar" cancel-title="Cancelar" @hide="resetAddZoneModal" @ok="AddZoneToUser(zone.id, addZoneModal.content.id)">
              <b-form-select v-model="zone" class="a">
                <b-form-select-option disabled value=''>Escolha a Zona</b-form-select-option>
                <b-form-select-option v-for="zone in zones" :key="zone.name" :value="zone">{{zone.name}}</b-form-select-option>
              </b-form-select>
            </b-modal>
            <b-modal :id="removeZoneModal.id" :title="removeZoneModal.title" ok-variant="success" ok-title="Remover" cancel-title="Cancelar" @hide="resetRemoveZoneModal" @ok="RemoveZoneFromUser(removeZoneModal.content.id, removeZoneModal.user.id)">
            </b-modal>
            <b-modal :id="addRoleToUserModal.id" :title="addRoleToUserModal.title" ok-variant="success" ok-title="Adicionar" cancel-title="Cancelar" @hide="resetAddRoleToUserModal" @ok="AddRoleToUser(addRoleToUserModal.content, role)">
              <b-form-select v-model="role" class="a">
                <b-form-select-option disabled value=''>Escolha a Role</b-form-select-option>
                <b-form-select-option v-for="role in roles" :key="role.normalizedName" :value="role.normalizedName">{{role.normalizedName}}</b-form-select-option>
              </b-form-select>
            </b-modal>
            <b-modal :id="removeRoleModal.id" :title="removeRoleModal.title" ok-variant="success" ok-title="Remover" cancel-title="Cancelar" @hide="resetRemoveRoleModal" @ok="RemoveRoleFromUser(removeRoleModal.user, removeRoleModal.content)">
            </b-modal>

            <b-modal :id="addUserModal.id" :title="addUserModal.title" :ok-disabled="userUserNameState != true || userUserName == '' || userName == '' || userRole == '' || userPassword == ''" ok-variant="success" ok-title="Adicionar" cancel-title="Cancelar" @hide="resetAddUserModal" @ok="AddUser(userUserName, userName, userSurname, userEmail, userState, userRole, userPassword)">
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="3" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Username</p>
                </b-col>
                <b-col lg="9" class="my-1">
                  <b-form-input
                    id="userUserName-input"
                    v-model="userUserName"
                    type="text"
                    :state="userUserNameState"
                    @change="verifyUsername"
                  ></b-form-input>
                  <b-form-invalid-feedback id="userUserName-input-feedback">
                    Username já existe!
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="3" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Nome</p>
                </b-col>
                <b-col lg="9" class="my-1">
                  <b-form-input
                    id="userName-input"
                    v-model="userName"
                    type="text"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="3" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Apelido</p>
                </b-col>
                <b-col lg="9" class="my-1">
                  <b-form-input
                    id="userSurname-input"
                    v-model="userSurname"
                    type="text"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="3" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Email</p>
                </b-col>
                <b-col lg="9" class="my-1">
                  <b-form-input
                    id="userEmail-input"
                    v-model="userEmail"
                    :state="userEmailState"
                    @change="verifyEmail"
                  ></b-form-input>
                  <b-form-invalid-feedback id="userEmail-input-feedback">
                    Email já existe! Se pretender continuar a criar o utilizador, irá alterar o utilizador já existente.
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="3" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Ativo?</p>
                </b-col>
                <b-col lg="9" class="my-1">
                  <b-form-checkbox v-model="userState" name="check-button" switch></b-form-checkbox>
                </b-col>
              </b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="3" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Role</p>
                </b-col>
                <b-col lg="9" class="my-1">
                  <b-form-select v-model="userRole" class="a">
                    <b-form-select-option disabled value=''>Escolha a Role</b-form-select-option>
                    <b-form-select-option v-for="role in roles" :key="role.normalizedName" :value="role.normalizedName">{{role.normalizedName}}</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="3" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Password</p>
                </b-col>
                <b-col lg="9" class="my-1">
                  <b-form-input
                    id="userPassword-input"
                    v-model="userPassword"
                    type="password"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-modal>
            <b-modal :id="addRoleModal.id" :ok-disabled="roleName == '' || roleDisplayName == '' || roleDescription == ''" :title="addRoleModal.title" ok-variant="success" ok-title="Adicionar" cancel-title="Cancelar" @hide="resetAddRoleModal" @ok="AddRole(roleName, roleDisplayName, roleDescription)">
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="12" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Nome (Se possivel, sem espaços)</p>
                </b-col>
                <b-col lg="12" class="my-1">
                  <b-form-input
                    id="roleName-input"
                    v-model="roleName"
                    type="text"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="12" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Nome de Apresentação</p>
                </b-col>
                <b-col lg="12" class="my-1">
                  <b-form-input
                    id="roleDisplayName-input"
                    v-model="roleDisplayName"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="12" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Descrição</p>
                </b-col>
                <b-col lg="12" class="my-1">
                  <b-form-textarea
                    id="descricao-text"
                    v-model="roleDescription"
                    placeholder="Escreva aqui.."
                  ></b-form-textarea>
                </b-col>
              </b-row>
            </b-modal>
            <b-modal :id="partnerStateModal.id" :ok-disabled="partnerId == ''" :title="partnerStateModal.title" ok-variant="success" ok-title="Alterar Estado" cancel-title="Cancelar" @hide="resetPartnerStateModal" @ok="changePartnerState(partnerId, partnerState)">
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="12" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">PartnerId</p>
                </b-col>
                <b-col lg="12" class="my-1">
                  <b-form-input
                    id="partnerId-input"
                    v-model="partnerId"
                    type="number"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="3" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Ativo?</p>
                </b-col>
                <b-col lg="9" class="my-1">
                  <b-form-checkbox v-model="partnerState" name="check-button" switch></b-form-checkbox>
                </b-col>
              </b-row>
            </b-modal>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import UserAssignmentDataService from "src/services/UserAssignmentDataService"
import UserDataService from "src/services/UserDataService"
import SessionDataService from "src/services/SessionDataService"
import RestauranteAssignedToZoneDataService from "src/services/RestauranteAssignedToZoneDataService"
import { BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BTr ,BTh } from 'bootstrap-vue'
export default {
  components: {
    LTable,
    Card,
    BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BTr ,BTh,
    Card
  },

  mounted () {
    this.getCurrentLoginInfo()
    this.GetAllUsers()
    this.GetAllRoles()
    this.GetAllPermissions()
    this.GetAllSupplierZones()
  },

  data() {
    return {
      fieldsUsers: [
        {
            key: 'userName',
            label: 'Nome',
            sortable: true,
            tdClass: 'text-center', thClass: 'text-white'
          },
          {
            key: 'roleNames',
            label: 'Roles',
            tdClass: 'text-center', thClass: 'text-white'
          },
          {
            key: 'zones',
            label: 'Zonas',
            tdClass: 'text-center', thClass: 'text-white'
          },
          {
            key: 'isActive',
            label: 'Ativo?',
            sortable: true,
            tdClass: 'text-center', thClass: 'text-white'
          }
        ],
      itemsUsers: [],
      isBusyUsers: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100],
      sortBy: 'estafeta_horaRest',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: 'userName',

      showPermi: false,
      permissions: [],
      zones: [],
      roles: [],
      zone: '',
      role: '',

      userInfoModal: {
        id: 'userInfo-modal',
        title: '',
        content: ''
      },
      editZonesModal: {
        id: 'editZones-modal',
        title: '',
        content: ''
      },
      editRolesModal: {
        id: 'editRoles-modal',
        title: '',
        content: ''
      },
      addZoneModal: {
        id: 'addZone-modal',
        title: '',
        content: ''
      },
      removeZoneModal: {
        id: 'removeZone-modal',
        title: '',
        content: '',
        user: ''
      },
      addRoleToUserModal: {
        id: 'addRoleToUser-modal',
        title: '',
        content: ''
      },
      removeRoleModal: {
        id: 'removeRole-modal',
        title: '',
        content: '',
        user: ''
      },

      addUserModal: {
        id: 'addUser-modal',
        title: '',
        content: ''
      },
      userUserName: '',
      userName: '',
      userSurname: '',
      userEmail: '',
      userState: true,
      userRole: '',
      userPassword: '',
      addRoleModal: {
        id: 'addRole-modal',
        title: '',
        content: ''
      },
      roleName: '',
      roleDisplayName: '',
      roleDescription: '',


      partnerStateModal: {
        id: 'partnerState-modal',
        title: '',
        content: ''
      },
      partnerId: '',
      partnerState: true,

      userEmailState: null,
      userUserNameState: null,

      currentUser: null,
    };
  },
  methods: {
    getCurrentLoginInfo(){
      SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => {
          (this.currentUser = response.data.result.user);
          if(this.currentUser == null){
            this.$router.push({ path: '/login', query: { returnUrl: this.$route.path } })
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    GetAllUsers(){
      var skip = ((this.currentPage-1) * this.perPage)
      UserAssignmentDataService.GetAllUsers({skipCount: skip, maxResultCount: this.perPage, sortBy: this.sortBy, sortDesc: this.sortDesc, filter: this.filter} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.itemsUsers = this.formatUserData(response.data.result.items), this.totalRows = response.data.result.totalRows, this.isBusyUsers = false))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    formatUserData(users){
      var userData = [];
      users.forEach(element => {
        userData.push({
          user: element.user,
          zones: element.zones,
          roleNames: element.user != null? element.user.roleNames: '',
          userName: element.user != null? element.user.userName: '',
          isActive: element.user != null? element.user.isActive: '',
        })
      })
      return userData
    },
    GetAllRoles(){
      UserAssignmentDataService.GetAllRoles({skipCount: 0, maxResultCount: 1000} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.roles = response.data.result.items))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    GetAllPermissions(){
      UserAssignmentDataService.GetAllPermissions({} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.permissions = response.data.result.items.map(p => p.name)))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    GetAllSupplierZones(){
      UserAssignmentDataService.GetAllSupplierZones({skipCount: 0, maxResultCount: 1000} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.zones = response.data.result.items))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    AddZoneToUser(supplierZoneId, userId){
      UserAssignmentDataService.AddZoneToUser({supplierZoneId: supplierZoneId, userId: userId} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Zona Adicionada ao Utilizador com Sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.GetAllUsers()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    RemoveZoneFromUser(supplierZoneId, userId){
      UserAssignmentDataService.RemoveZoneFromUser({supplierZoneId: supplierZoneId, userId: userId} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Zona Removida do Utilizador com Sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.GetAllUsers()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    AddRoleToUser(user, role){
      user.roleNames.push(role)
      UserAssignmentDataService.UpdateUser(user , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Role Adicionada ao Utilizador com Sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.GetAllUsers()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    RemoveRoleFromUser(user, role){
      const index = user.roleNames.indexOf(role);
      if (index > -1) {
        user.roleNames.splice(index, 1);
      }
      UserAssignmentDataService.UpdateUser(user , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Role Removida do Utilizador com Sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.GetAllUsers()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    UpdateRolesPermissions(newRoles){
      UserAssignmentDataService.UpdateRolesPermissions(newRoles , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Permissões alteradas com Sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.hidePermissions(), this.GetAllPermissions()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },

    AddUser(userUserName, userName, userSurname, userEmail, userState, userRole, userPassword){
      var user = {
        userName: userUserName,
        name: userName,
        surname: userSurname,
        emailAddress: userEmail,
        isActive: userState,
        roleNames: [
          userRole
        ],
        password: userPassword
      }
      UserDataService.CreateUserWithSameEmail(user , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Utilizador criado com Sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.GetAllUsers()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    AddRole(roleName, roleDisplayName, roleDescription){
      var role = {
        name: roleName,
        displayName: roleDisplayName,
        description: roleDescription,
      }
      UserAssignmentDataService.CreateRole(role , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Role criada com Sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.hidePermissions(), this.GetAllRoles()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    activateUser(userId){
      var user = {
        id: userId
      }
      UserDataService.ActivateUser(user , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Utilizador ativado com Sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.GetAllUsers()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    deActivateUser(userId){
      var user = {
        id: userId
      }
      UserDataService.DeactivateUser(user , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Utilizador desativado com Sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.GetAllUsers()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    validateUsername(username){
      UserDataService.ValidateUsername({username: username} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.userUserNameState = response.data.result, this.$notifications.notify(
          {
            message: `<span>Disponibilidade do username verificada!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          })
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    validateEmail(email){
      UserDataService.ValidateEmail({email: email} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.userEmailState = response.data.result, response.data.result? '': this.$notifications.notify(
          {
            message: `<span>Email já existe! Se pretender continuar a criar o utilizador, irá alterar o utilizador já existente.</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          })
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },


    userInfo(item, index, button){
      this.userInfoModal.title = ''
      this.userInfoModal.content = item
      this.$root.$emit('bv::show::modal', this.userInfoModal.id, button)
    },
    resetUserInfoModal(){
      this.userInfoModal.title = ''
      this.userInfoModal.content = ''
    },
    editZones(item, index, button){
      this.editZonesModal.title = ''
      this.editZonesModal.content = item
      this.$root.$emit('bv::show::modal', this.editZonesModal.id, button)
    },
    resetEditZonesModal(){
      this.editZonesModal.title = ''
      this.editZonesModal.content = ''
    },
    editRoles(item, index, button){
      this.editRolesModal.title = ''
      this.editRolesModal.content = item
      this.$root.$emit('bv::show::modal', this.editRolesModal.id, button)
    },
    resetEditRolesModal(){
      this.editRolesModal.title = ''
      this.editRolesModal.content = ''
    },
    addZone(user, index, button){
      this.addZoneModal.title = 'Adicionar Zona ao utilizador '+ user.userName
      this.addZoneModal.content = user
      this.$root.$emit('bv::show::modal', this.addZoneModal.id, button)
    },
    resetAddZoneModal(){
      this.addZoneModal.title = ''
      this.addZoneModal.content = ''
      this.zone = ''
    },
    removeZone(zone, user, index, button){
      this.removeZoneModal.title = 'Tem a certeza que pretende remover a zona ' + zone.name + ' deste utilizador?'
      this.removeZoneModal.content = zone
      this.removeZoneModal.user = user
      this.$root.$emit('bv::show::modal', this.removeZoneModal.id, button)
    },
    resetRemoveZoneModal(){
      this.removeZoneModal.title = ''
      this.removeZoneModal.content = ''
      this.removeZoneModal.user = ''
    },
    addRoleToUser(user, index, button){
      this.addRoleToUserModal.title = 'Adicionar Role ao utilizador '+ user.userName
      this.addRoleToUserModal.content = user
      this.$root.$emit('bv::show::modal', this.addRoleToUserModal.id, button)
    },
    resetAddRoleToUserModal(){
      this.addRoleToUserModal.title = ''
      this.addRoleToUserModal.content = ''
      this.role = ''
    },
    removeRole(role, user, index, button){
      this.removeRoleModal.title = 'Tem a certeza que pretende remover a role ' + role + ' deste utilizador?'
      this.removeRoleModal.content = role
      this.removeRoleModal.user = user
      this.$root.$emit('bv::show::modal', this.removeRoleModal.id, button)
    },
    resetRemoveRoleModal(){
      this.removeRoleModal.title = ''
      this.removeRoleModal.content = ''
      this.removeRoleModal.user = ''
    },


    addUserInfo(item, index, button){
      this.addUserModal.title = 'Adicionar Utilizador'
      this.addUserModal.content = item
      this.userUserNameState = null
      this.userEmailState = null
      this.$root.$emit('bv::show::modal', this.addUserModal.id, button)
    },
    resetAddUserModal(){
      this.addUserModal.title = ''
      this.addUserModal.content = ''
      this.userUserName = ''
      this.userName = ''
      this.userSurname = ''
      this.userEmail = ''
      this.userState = true,
      this.userRole = ''
      this.userPassword = ''
      this.userUserNameState = null
      this.userEmailState = null
    },
    addRoleInfo(item, index, button){
      this.addRoleModal.title = 'Adicionar Role'
      this.addRoleModal.content = item
      this.$root.$emit('bv::show::modal', this.addRoleModal.id, button)
    },
    resetAddRoleModal(){
      this.addRoleModal.title = ''
      this.addRoleModal.content = ''
      this.roleName = ''
      this.roleDisplayName = ''
      this.roleDescription = ''
    },


    partnerStateInfo(item, index, button){
      this.partnerStateModal.title = 'Alterar Estado do Parceiro'
      this.partnerStateModal.content = item
      this.$root.$emit('bv::show::modal', this.partnerStateModal.id, button)
    },
    resetPartnerStateModal(){
      this.partnerStateModal.title = ''
      this.partnerStateModal.content = ''
      this.partnerId = ''
      this.partnerState = true
    },
    changePartnerState(partnerId, partnerState){
      RestauranteAssignedToZoneDataService.ChangePartnerState({supplierId: partnerId, newState: partnerState} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Estado do Parceiro alterado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          })
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },

    verifyUsername(){
      this.validateUsername(this.userUserName)
    },
    verifyEmail(){
      this.validateEmail(this.userEmail)
    },
    showPermissions(){
      this.showPermi = true
    },
    hidePermissions(){
      this.showPermi = false
    },

    handlePageChange(value) {
      this.isBusyUsers = true
      this.currentPage = value;
      this.GetAllUsers()
    },
    handlePageSizeChange(event) {
      this.isBusyUsers = true
      this.pageSize = event;
      this.currentPage = 1;
      this.GetAllUsers()
    },
    sortingChanged(ctx) {
      this.isBusyUsers = true
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.GetAllUsers()
    },
    pesquisar(){
      this.isBusyUsers = true
      this.currentPage = 1;
      this.GetAllUsers()
    },

  },

};
</script>
<style>
  .permissionLine{
    background-color: white;
  }

  .permissionLine:hover{
    background-color: #fae3aa;
  }
  .pillStyle {
    background-color: #fae3aa;
    border: 1px solid #878787c9;
    border-radius: 8px;
    margin: 10px;
    padding: 5px;
    width: fit-content;
  }
</style>

<template>
  <div v-if="poNum != '' && isFirstOrder == true">
    <i class="fa fa-trophy" title="Primeiro Pedido" style="color: #DAA520; font-size: 1.5em; "></i>
  </div>
</template>
<script>
import PurchaseOrderCoreDataService from "src/services/PurchaseOrderCoreDataService"
export default {
  name: "FirstOrderIcon",

  props: {
    poNum: {
      type: Number,
      required: true
    },
  },
  mounted () {
    this.getIsFirstOrder(this.poNum)
  },
  watch: {
    poNum(newVal, oldVal) {
      this.isFirstOrder = false
      this.getIsFirstOrder(this.poNum)
    },
  },
  data() {
    return {
      isFirstOrder: false
    }
  },

  methods:{
    getIsFirstOrder(poNum){
      PurchaseOrderCoreDataService.GetIsFirstOrder({poNum: poNum})
        .then(response => this.isFirstOrder = response.data)
        .catch(error => {
          console.log(error)
        })
        .finally()
    }
  }
};
</script>

<style scoped>
</style>

import http from "../http-common-oc";

class LogAuthorizationCodeDataService {
  GetAllLogAuthorizationCode(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAuthorizationCode/GetAll", config);
  }
  CreateLogAuthorizationCode(logAuthorizationCode, headers) {
    return http.post("/services/app/LogAuthorizationCode/Create", logAuthorizationCode, { headers });
  }
  GetDebtByPoNum(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/LogAuthorizationCode/GetDebtByPoNum", config);
  }
}

export default new LogAuthorizationCodeDataService();

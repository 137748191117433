import http from "../http-common-oc";

class CandidateScheduleDataService {
  GetCandidateSchedule(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/CandidateSchedule/GetCandidateSchedule", config);
  }
  UpdateCandidateSchedule(payload, headers) {
    let config = {
      headers: headers
    }
    return http.put("/services/app/CandidateSchedule/UpdateCandidateSchedule", payload, config);
  }
  UpdateCandidateFullSchedule(payload, headers) {
    let config = {
      headers: headers
    }
    return http.put("/services/app/CandidateSchedule/UpdateCandidateFullSchedule", payload, config);
  }

}

export default new CandidateScheduleDataService();

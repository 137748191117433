<template>
  <div class="content" style="padding-top: 0px; background-color: #f7f7f7;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <b-row style="margin:5px">
                <b-col lg="9">
                  <h4 class="card-title">Turnos</h4>
                </b-col>
                <b-col lg="3">
                    <b-overlay id="overlay" :show="loadingZones" variant="transparent" rounded="lg" spinner-variant="warning">
                      <b-form-select v-model="zone" class="a" @change="handleZoneChange(zone.id)">
                        <b-form-select-option disabled value="">Escolha a Zona</b-form-select-option>
                        <b-form-select-option v-for="zona in zonas" :key="zona.name" :value="zona">{{zona.name}}</b-form-select-option>
                      </b-form-select>
                    </b-overlay>
                </b-col>
              </b-row>
            </template>
            <b-overlay id="overlay" :show="loadingSchedule" variant="transparent" rounded="lg" spinner-variant="warning">
              <div class="calendar-month">
                <div class="calendar-month-header">
                  <CalendarDateSelector
                    :current-date="today"
                    :selected-date="selectedDate"
                    @dateSelected="selectDate"
                    @changeSchedule="openAbsenceModal"
                  />
                </div>
                <CalendarWeekdays :selected-date="selectedDate" style="background-color: #f3dba0;"/>

                <ol class="days-grid">
                  <CalendarWeekDayItem
                    :zoneId="zone.id"
                    v-for="day in days"
                    :key="day.id"
                    :day="day"
                    :is-today="isToday(day.date)"
                    :height="maxHeight"
                    :events="day.events"
                    @changeSchedule="openReinforceModal"
                    @removeEvent="openRemoveEventModal"
                  />
                </ol>
                <div style="border: 1px solid #DAA520;background-color: #f3dba0;">
                  <CalendarSubtitle />
                </div>
              </div>
            </b-overlay>
            <b-modal :id="scheduleAbsenceModal.id" :title="scheduleAbsenceModal.title" cancel-title="Cancelar" :ok-disabled="(!initialDateshift1 && !initialDateshift2) || (notSameDay && !finalDateshift1 && !finalDateshift2)" ok-variant="success" ok-title="Adicionar" @hide="resetScheduleAbsenceModal" @ok="postAbsence(estafeta, initialDate, initialDateshift1, initialDateshift2, finalDate, finalDateshift1, finalDateshift2, notSameDay)">
              <div class="row justify-content-center" style="margin: auto;width: 100%;">
                <b-row style="margin: auto;width: 100%;">
                  <b-col lg="3" class="my-1">
                    <p style="text-align: left; padding: 6px 0;margin-top:10px" v-if="!notSameDay">Data</p>
                    <p style="text-align: left; padding: 6px 0;margin-top:10px" v-if="notSameDay">Data Inicial</p>
                  </b-col>
                  <b-col lg="9" class="my-1">
                    <b-row class="my-1">
                      <b-col lg="2" class="my-1">
                        <b-form-datepicker id="initialDate" size="sm" button-only v-model="initialDate" :min="todayDate" :max="notSameDay? finalDate: null" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"></b-form-datepicker>
                      </b-col>
                      <b-col lg="4" class="my-1">
                        <p>{{initialDate}}</p>
                      </b-col>
                      <b-col lg="3" class="my-1">
                        <p><b-form-checkbox v-model="initialDateshift1" name="shift1-check-button" style="text-align:center"></b-form-checkbox>Almoço</p>
                      </b-col>
                      <b-col lg="3" class="my-1">
                        <p><b-form-checkbox v-model="initialDateshift2" name="shift2-check-button" style="text-align:center" :disabled="notSameDay"></b-form-checkbox>Jantar</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row style="margin: auto;width: 100%;">
                  <a @click="notSameDayChange">
                    <p style="margin-left:15px;font-size:12px; color: #DAA520;text-decoration: underline" v-if="!notSameDay">Escolher outra data final</p>
                    <p style="margin-left:15px;font-size:12px; color: #DAA520;text-decoration: underline" v-if="notSameDay">Falta apenas no dia</p>
                  </a>
                </b-row>
                <b-row style="margin: auto;width: 100%;" v-if="notSameDay">
                  <b-col lg="3" class="my-1">
                    <p style="text-align: left; padding: 6px 0;">Data Final</p>
                  </b-col>
                  <b-col lg="9" class="my-1">
                    <b-row class="my-1">
                      <b-col lg="2" class="my-1">
                        <b-form-datepicker id="finalDate" size="sm" button-only v-model="finalDate" :min="initialDate" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"></b-form-datepicker>
                      </b-col>
                      <b-col lg="4" class="my-1">
                        <p>{{finalDate}}</p>
                      </b-col>
                      <b-col lg="3" class="my-1">
                        <p><b-form-checkbox v-model="finalDateshift1" name="shift1-check-button" style="text-align:center" :disabled="notSameDay"></b-form-checkbox>Almoço</p>
                      </b-col>
                      <b-col lg="3" class="my-1">
                        <p><b-form-checkbox v-model="finalDateshift2" name="shift2-check-button" style="text-align:center"></b-form-checkbox>Jantar</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row style="margin: auto;width: 100%;">
                  <b-col lg="3" class="my-1">
                    <p style="text-align: left; padding: 6px 0;">Estafeta</p>
                  </b-col>
                  <b-col lg="9" class="my-1">
                    <b-overlay id="overlay" :show="loadingDAs" variant="transparent" rounded="lg" spinner-variant="warning">
                      <b-form-select v-model="estafeta" style="cursor: pointer">
                        <b-form-select-option value="">Escolha um estafeta</b-form-select-option>
                        <b-form-select-option v-for="da in dasByZone" :key="da.id" :value="da.id">{{da.userName}}</b-form-select-option>
                      </b-form-select>
                    </b-overlay>
                  </b-col>
                </b-row>
              </div>
            </b-modal>
            <b-modal :id="scheduleReinforceModal.id" :title="scheduleReinforceModal.title" cancel-title="Cancelar" ok-variant="success" ok-title="Adicionar" @hide="resetScheduleReinforceModal" @ok="postReinforce(estafeta,scheduleReinforceModal.content)">
              <div class="row justify-content-center" style="margin: auto;width: 100%;">
                <b-row style="margin: auto;width: 100%;">
                  <b-col lg="4" class="my-1">
                    <p style="text-align: left; padding: 6px 0;margin-top:10px">Data</p>
                  </b-col>
                  <b-col lg="4" class="my-1">
                    <p style="padding: 6px 0;margin-top:10px">{{scheduleReinforceModal.content.date}}</p>
                  </b-col>
                  <b-col lg="4" class="my-1">
                    <p style="padding: 6px 0;margin-top:10px">{{scheduleReinforceModal.content.shiftLabel}}</p>
                  </b-col>
                </b-row>
                <b-row style="margin: auto;width: 100%;">
                  <b-col lg="3" class="my-1">
                    <p style="text-align: left; padding: 6px 0;">Estafeta</p>
                  </b-col>
                  <b-col lg="9" class="my-1">
                    <b-overlay id="overlay" :show="loadingDAs" variant="transparent" rounded="lg" spinner-variant="warning">
                      <b-form-select v-model="estafeta" style="cursor: pointer">
                        <b-form-select-option value="">Escolha um estafeta</b-form-select-option>
                        <b-form-select-option v-for="da in dasAvailable" :key="da.deliveryAgent.id" :value="da.deliveryAgent.id" :style="da.available? 'color: green' : ''">{{da.deliveryAgent.userName}} {{da.available? '(Disponível)' : ''}}</b-form-select-option>
                      </b-form-select>
                    </b-overlay>
                  </b-col>
                </b-row>
              </div>
            </b-modal>
            <b-modal :id="removeEventModal.id" :title="removeEventModal.title" cancel-title="Cancelar" ok-variant="success" ok-title="Remover" @hide="resetRemoveEventModal" @ok="removeDeliveryAgentScheduleEvent(removeEventModal.content)">
            </b-modal>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import CalendarDateSelector from "src/components/Calendar/CalendarDateSelector";
import CalendarWeekDayItem from "src/components/Calendar/CalendarWeekDayItem";
import CalendarWeekdays from "src/components/Calendar/CalendarWeekdays";
import CalendarSubtitle from "src/components/Calendar/CalendarSubtitle";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import DAScheduleDataService from "src/services/DAScheduleDataService"
import DeliveryAgentsOCDataService from "src/services/DeliveryAgentsOCDataService"

dayjs.extend(weekday);
dayjs.extend(weekOfYear);


export default {
  components: {
    CalendarDateSelector,
    CalendarWeekDayItem,
    CalendarWeekdays,
    CalendarSubtitle
  },
  mounted(){
    this.getDays()
    this.getDAZonesByUser()
  },

  data() {
    return {
      selectedDate: dayjs(),
      days: [],
      today: dayjs(),
      todayDate: dayjs().format("YYYY-MM-DD"),
      zonas: [],
      zone: '',
      loadingSchedule: false,
      scheduleAbsenceModal: {
        id: 'scheduleAbsence-modal',
        title: '',
        content: ''
      },
      scheduleReinforceModal: {
        id: 'scheduleReinforce-modal',
        title: '',
        content: ''
      },
      removeEventModal: {
        id: 'removeEvent-modal',
        title: '',
        content: ''
      },
      initialDate: '',
      finalDate: '',
      initialDateshift1: false,
      initialDateshift2: false,
      finalDateshift1: true,
      finalDateshift2: false,
      estafeta: '',
      dasByZone:[],
      dasAvailable:[],
      availableIds: [],
      notSameDay: false,
      showAllDAs: false,
      loadingDAs: false,
      loadingZones: false,
      maxHeight: 500
    };
  },

  methods: {
    formatScheduleData(events){
      events.forEach(element => {
        var index = this.days.findIndex(d => d.weekday == element.dayOfWeek)
        this.days[index].events.push({
            id: element.id,
            deliveryAgent: element.deliveryAgent,
            title  : element.deliveryAgent.userName,
            shift: element.shift,
            className: (element.state == 'Summoned'?'eventsummoned': (element.state == 'Unavailable'?'eventunavailable': (element.state == 'Available'?'eventavailable': 'eventreinforced'))),
            event: element
        })
      });
    },
    isToday(date){
      return date.format("YYYY-MM-DD") === this.today.format("YYYY-MM-DD")
    },
    getWeekday(date) {
      return dayjs(date).weekday();
    },
    getDays(){
      this.days = [
          ...this.previousWeekDays(),
          ...this.currentDay(),
          ...this.nextWeekDays()
        ]
    },

    selectDate(newSelectedDate) {
      this.selectedDate = newSelectedDate;
      this.getDays()
      if(this.zone != ''){
        this.getScheduleByZoneWithDates(this.zone.id)
      }
    },
    currentDay(){
      let weekday = this.getWeekday(this.selectedDate)
      weekday = weekday == 0 ? 6 : (weekday - 1)
      var dayArray = []
      dayArray.push({
          id: weekday,
          weekday: weekday,
          date: dayjs(this.selectedDate),
          events: []
        })

      return dayArray
    },

    previousWeekDays() {
      let weekday = this.getWeekday(this.selectedDate)
      weekday = weekday == 0 ? 6 : (weekday - 1)

      var pastDays = []
      for(var i = weekday; i > 0 ;i--){
        var dt = dayjs(this.selectedDate).subtract(i, "day")
        pastDays.push({
            id: weekday - i,
            weekday: weekday - i,
            date: dt,
            events: []
          })
      }

      return pastDays;
    },
    nextWeekDays() {
      let weekday = this.getWeekday(this.selectedDate)
      weekday = weekday == 0 ? 6 : (weekday - 1)

      const nextWeekDays = 7 - weekday

      var nextDays = []
      for(var i = 1; i < nextWeekDays ;i++){
        var dt = dayjs(this.selectedDate).add(i, "day")
        nextDays.push({
            id: i + weekday,
            weekday: i + weekday,
            date: dt,
            events: []
          })
      }

      return nextDays;
    },
    getScheduleByZoneWithDates(zoneId){
      this.loadingSchedule = true
      var monday = this.days[0].date.set('hour', 1).set('minute', 0).set('second', 0)
      var sunday = this.days[6].date.set('hour', 23).set('minute', 59).set('second', 59)
      this.days.forEach(element => element.events = [])

      DAScheduleDataService.GetScheduleByZoneWithDates({zoneId: zoneId, monday: monday.toString(), sunday: sunday.toString()}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.formatScheduleData(response.data.result), this.loadingSchedule = false, this.getMaxEvents()))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    getDAZonesByUser(){
      this.loadingZones = true
      DeliveryAgentsOCDataService.GetDAZonesByUser({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.zonas=response.data.result, this.loadingZones = false))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    handleZoneChange(zoneId){
      this.getScheduleByZoneWithDates(zoneId)
      this.getDAsByZone(zoneId)
    },
    openAbsenceModal() {
      this.scheduleAbsenceModal.title = 'Adicionar Falta de Estafeta'
      var d = dayjs()
      var a = d.add(1, "day")
      var day = {
        day: d,
        date: d.year() + "-" + (d.month()+1) + "-" + d.date()
      }
      this.scheduleAbsenceModal.content = day
      this.initialDate = day.date
      this.finalDate = a.year() + "-" + (a.month()+1) + "-" + a.date()
      this.initialDateshift1 = true
      this.$root.$emit('bv::show::modal', this.scheduleAbsenceModal.id, '')
    },
    resetScheduleAbsenceModal() {
      this.scheduleAbsenceModal.title = ''
      this.scheduleAbsenceModal.content = ''
      this.initialDate = ''
      this.finalDate = ''
      this.initialDateshift1 = false
      this.estafeta = ''
      this.notSameDay = false
      this.loadingDAs = false
    },
    openReinforceModal(item) {
      let weekday = this.getWeekday(item.day)
      weekday = weekday == 0 ? 6 : (weekday - 1)
      this.getDAsAvailableByZoneAndShift(this.zone.id, item.day, weekday, item.shift)
      this.scheduleReinforceModal.title = 'Adicionar Reforço'
      var d = dayjs(item.day)
      var day = {
        day: d,
        date: d.year() + "-" + (d.month()+1) + "-" + d.date(),
        shift: item.shift,
        shiftLabel: item.shift == 1? 'Almoço':'Jantar'
      }
      this.scheduleReinforceModal.content = day
      this.initialDate = day.date
      this.finalDate = day.date
      if(item.shift == 1){
        this.initialDateshift1 = true
      }else if(item.shift == 2){
        this.initialDateshift2 = true
      }
      this.$root.$emit('bv::show::modal', this.scheduleReinforceModal.id, '')
    },
    resetScheduleReinforceModal() {
      this.scheduleReinforceModal.title = ''
      this.scheduleReinforceModal.content = ''
      this.initialDate = ''
      this.finalDate = ''
      this.initialDateshift1 = false
      this.initialDateshift2 = false
      this.dasAvailable = []
      this.availableIds = []
      this.estafeta = ''
      this.showAllDAs = false
      this.loadingDAs = false
    },
    openRemoveEventModal(item) {
      var d = dayjs(item.day)
      var day = {
        day: d,
        date: d.year() + "-" + (d.month()+1) + "-" + d.date(),
        shift: item.shift,
        shiftLabel: item.shift == 1? 'Almoço':'Jantar',
        event: item.event.event
      }
      var str = 'Tem a certeza que pretende '
      if(item.event.className == 'eventsummoned'){
        str = str + 'colocar o estafeta '+ item.event.title + ' de férias'
      }
      if(item.event.className == 'eventunavailable'){
        str = str + 'pausar as férias do estafeta '+ item.event.title
      }
      if(item.event.className == 'eventreinforced'){
        str = str + 'retirar o estafeta '+ item.event.title + ' de reforço'
      }
      str = str + ' no dia '+ day.date + " ao " + day.shiftLabel +'?'
      this.removeEventModal.title = str
      this.removeEventModal.content = day
      this.$root.$emit('bv::show::modal', this.removeEventModal.id, '')
    },
    resetRemoveEventModal() {
      this.removeEventModal.title = ''
      this.removeEventModal.content = ''
    },
    notSameDayChange(){
      this.notSameDay = !this.notSameDay
      this.initialDateshift2 = true
    },
    changeShowAllDAs(){
      this.showAllDAs = !this.showAllDAs
      this.estafeta = ''
    },
    getDAsByZone(zoneId){
      this.loadingDAs = true
      DeliveryAgentsOCDataService.GetDeliveryAgentsByZone({zoneId: zoneId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.dasByZone=response.data.result, this.loadingDAs = false))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    getDAsAvailableByZoneAndShift(zoneId, d, weekday, shift){
      this.loadingDAs = true
      var day = d.year() + "/" + (d.month()+1) + "/" + d.date()
      DAScheduleDataService.GetAvailableDAsByZoneAndShift({zoneId: zoneId, day: day, weekday: weekday, shift: shift}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.dasAvailable=response.data.result, this.loadingDAs = false))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    postReinforce(estafeta, day){
      var daId = estafeta
      // var daId = ''
      // var da = this.dasAvailable.filter(d => d.userName == estafeta)[0];
      // if(da != null){
      //   daId = da.id
      // }
      var startHour = 1
      var finalHour = 22
      if(day.shift == 1){
        finalHour = 15
      }else{
        startHour = 17
      }
      var dd = dayjs(day.day)
      var startDate = dd.year() + "/" + (dd.month()+1) + "/" + dd.date() + " " + startHour + ":00"
      var endDate = dd.year() + "/" + (dd.month()+1) + "/" + dd.date() + " " + finalHour + ":00"
      var body = {
        deliveryAgentId: daId,
        type: "Reinforce",
        startDate: startDate,
        endDate: endDate,
      }
      DAScheduleDataService.PostDeliveryAgentScheduleChange(body, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Reforço adicionado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.zone != '' ? this.getScheduleByZoneWithDates(this.zone.id) : ''
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postAbsence(estafeta, idate, ishift1, ishift2, fdate, fshift1, fshift2, notSameDay){
      var daId = estafeta
      // var daId = ''
      // var da = this.dasByZone.filter(d => d.userName == estafeta)[0];
      // if(da != null){
      //   daId = da.id
      // }
      var startHour = 1
      var finalHour = 22
      var stDate = idate
      var fiDate = idate
      if(!notSameDay){
        if(!ishift1){
          startHour = 17
        }
        if(!ishift2){
          finalHour = 15
        }
      }else{
        if(!ishift1){
          startHour = 17
        }
        if(!fshift2){
          finalHour = 15
        }
        fiDate = fdate
      }
      var s = dayjs(stDate)
      var f = dayjs(fiDate)
      var startDate = s.year() + "/" + (s.month()+1) + "/" + s.date() + " " + startHour + ":00"
      var endDate = f.year() + "/" + (f.month()+1) + "/" + f.date() + " " + finalHour + ":00"
      var body = {
        deliveryAgentId: daId,
        type: "Absence",
        startDate: startDate,
        endDate: endDate,
      }
      DAScheduleDataService.PostDeliveryAgentScheduleChange(body, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Falta adicionada com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.zone != '' ? this.getScheduleByZoneWithDates(this.zone.id) : ''
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    removeDeliveryAgentScheduleEvent(item){
      var d = dayjs(item.day)
      var day = d.year() + "/" + (d.month()+1) + "/" + d.date()
      var body = {
        shift: item.shift,
        day: day,
        event: item.event
      }
      DAScheduleDataService.RemoveDeliveryAgentScheduleEvent(body, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.zone != '' ? this.getScheduleByZoneWithDates(this.zone.id) : ''
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    getMaxEvents(){
      var max = 0
      this.days.forEach(day => {
        var a = day.events.length
        if(a > max){
          max = a
        }
      })
      this.maxHeight = 50 * max
      if(this.maxHeight < 500){
        this.maxHeight = 500
      }
    }
  }
};
</script>
<style scoped>
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.calendar-month-header {
  display: flex;
  justify-content: space-between;
  background-color: #f3dba0;
  padding: 10px;
  border: 1px solid #DAA520;
}
.calendar-month {
  position: relative;
  background-color: white;
  border: 1px solid #DAA520;
  margin: 1%;
}

.day-of-week {
  color: var(--grey-800);
  font-size: 18px;
  background-color: #fff;
  padding-bottom: 5px;
  padding-top: 10px;
}

.day-of-week,
.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border: 1px solid #DAA520;
}

.day-of-week > * {
  text-align: right;
  padding-right: 5px;
  border: 1px solid #DAA520;
}

.days-grid {
  height: 100%;
  position: relative;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  border: 1px solid #DAA520;
}
</style>

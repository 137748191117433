import http from "../http-common-oc";

class DiscountDataService {
  GetVoucherByPO(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Discount/GetVoucherByPoNum", config);
  }
  GetVoucherIdByPO(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Discount/GetVoucherIdByPoNum", config);
  }
}

export default new DiscountDataService();

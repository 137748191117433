import http from "../http-common-oc";

class CreditComplaintDataService {
  GetByComplaint(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/CreditComplaint/GetByComplaint", config);
  }
  CreateCreditComplaint(body, headers) {
    return http.post("/services/app/CreditComplaint/Create", body, { headers });
  }
}

export default new CreditComplaintDataService();
